.breadcrumbs {
    width: 100%;
    padding-top: 20px;
    @include rpmin(lg){
        padding-top: 40px;
    }

    &-list {        
        display: flex;
        align-items: center;
        list-style-type: none;
        justify-content: center;

        // overflow: auto;
    }
    
    &-item {
        display: flex;
        align-items: center;
        line-height: 1.4;
        white-space: nowrap;

        & > * {
            @include content(18px);
            color: var(--cl-content, #000);
            @include medium;
            margin-right: 8px;
            
            @include rpmin(md){
                margin-right: 12px;
            }
        }

        *{
            transition: .3s all linear;
        }
        
        &.is-actived {
            .breadcrumbs-item__link {
                cursor: initial;
                pointer-events: none;

                color: var(--cl-blue, #000);
            }
        }

        &:hover{
            .breadcrumbs-item__link {
                color: var(--cl-red, #000);
            }
        }
    }
}
