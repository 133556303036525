.banner-section{
    position: relative;
    max-width: 100%;

    &__thumb{
        position: relative;
        
        width: 100%;
        height: 280px;
        max-height: calc(100vh - #{var(--hHeader, 0px)});
        
        @include rpmin(sm){
            padding-top: 41.667%;
            width: 100%;
            height: 0;
        }

        z-index: -1;

        &>*{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;

            @include rpmin(md){
                clip-path: ellipse(96% 100% at 50% 0%);
            }
            @include rpmin(lg){
                clip-path: ellipse(86% 100% at 50% 0%);
            }
            @include rpmin(xl){
                clip-path: ellipse(70% 100% at 50% 0%);
            }
        }
    }
    .banner-img{
        position: relative;
        width: 100%;
        height: 400px;

        @include rpmin(sm){
            padding-top: 41.667%;
            width: 100%;
            height: 0;
        }

        img, svg, video{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;

            @include rpmin(md){
                clip-path: ellipse(96% 100% at 50% 0%);
            }
            @include rpmin(lg){
                clip-path: ellipse(86% 100% at 50% 0%);
            }
            @include rpmin(xl){
                clip-path: ellipse(70% 100% at 50% 0%);
            }
        }
    }


    @keyframes iconLeftRotate_banner {
        0%, 20%, 80%, 100%{
            bottom: -28%;
            left: -8.7%;
            transform: scale(1);
            display: block;
        }

        30%, 50%, 70%{
            bottom: -60%;
            left: -80%;
            transform: rotate(-40deg) scale(3);
            display: block;
        }
    }

    @keyframes iconRightRotate_banner {
        0%, 20%, 80%, 100%{
            right: -7%;
            bottom: -20%;
            transform: scale(1);
            display: block;
        }
        
        30%, 50%, 70%{
            bottom: -45%;
            right: -60%;
            transform: rotate(45deg) scale(2);
            display: block;
        }
    }
    @include rpmin(md){
        @keyframes iconLeftRotate_banner {
            0%, 20%, 80%, 100%{
                bottom: -25%;
                left: -8%;
                transform: scale(1);
                display: block;
            }
    
            30%, 50%, 70%{
                bottom: -25%;
                left: -60%;
                transform: rotate(-40deg) scale(3);
                display: block;
            }
        }
        @keyframes iconRightRotate_banner {
            0%, 20%, 80%, 100%{
                right: -6%;
                bottom: -20%;
                transform: scale(1);
                display: block;
            
            }
    
            30%, 50%, 70%{
                right: -35%;
                bottom: -45%;
                transform: rotate(45deg) scale(2);
                display: block;
            }
        }
    }
    @include rpmin(lg){
        @keyframes iconLeftRotate_banner {
            0%, 20%, 80%, 100%{
                bottom: -25%;
                left: -1.7%;
                transform: scale(1);
                display: block;
            }
    
            30%, 50%, 70%{
                bottom: -25%;
                left: -60%;
                transform: rotate(-40deg) scale(3);
                display: block;
            }
        }
        @keyframes iconRightRotate_banner {
            0%, 20%, 80%, 100%{
                bottom: -16.5%;
                right: 0%;
                transform: scale(1);
                display: block;
            }
    
            30%, 50%, 70%{
                bottom: -38%;
                right: -30%;
                transform: rotate(45deg) scale(2);
                display: block;
            }
        }
    }

    .icon-left, .icon-right{
        opacity: 0.45;
        @include rpmin(md){
            opacity: 1;
        }

        svg{
            width: 100%;
        }

        display: none;
    }

    .icon-left{
        position: absolute;
        display: block;
        width: 80px;

        bottom: -28%;
        left: -8.7%;
        transform: scale(1);

        @include rpmin(sx){
            width: 100px;
        }
        @include rpmin(md){
            width: 196px;

            bottom: -25%;
            left: -8%;
            transform: scale(1);
        }

        @include rpmin(lg){
            bottom: -25%;
            left: -1.7%;
            transform: scale(1);
        }

        animation: 14s iconLeftRotate_banner infinite ease;
    }

    .icon-right{
        position: absolute;
        display: block;

        right: -7%;
        bottom: -20%;
        transform: scale(1);

        width: 60px;
        @include rpmin(sx){
            width: 80px;
        }
        @include rpmin(md){
            width: 140px;

            right: -6%;
            bottom: -20%;
            transform: scale(1);
        }
        @include rpmin(lg){
            bottom: -16.5%;
            right: 0%;
            transform: scale(1);
        }
        animation: 14s iconRightRotate_banner infinite ease;
    }
}