.about-introduction{
    @include space(margin-top, 60px);
    &-wrapper{
        width: var(--wContainer3, 100%);
        margin-left: auto;
        margin-right: auto;

        @include content(18px);

        color: var(--cl-content, #000);
        line-height: 1.388889;
    }
}

.about-mv{
    // @include space(margin-top, 60px);
    margin-top: 50px;

    &-bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: -1;
        height: 100%;
        
        @include rpmin(md){
            height: calc(100% - 40px);
        }

        img{
            display: block;
            width: 100%;
            height: 100%;

            @include rpmin(md){
                clip-path: ellipse(96% 180% at 50% 180%);
            }
            @include rpmin(lg){
                clip-path: ellipse(86% 180% at 50% 180%);
            }
            @include rpmin(xl){
                clip-path: ellipse(72% 180% at 50% 180%);
            }
        }

        &::after{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--cl-blue, #000);
            opacity: .5;

            @include rpmin(md){
                clip-path: ellipse(96% 180% at 50% 180%);
            }
            @include rpmin(lg){
                clip-path: ellipse(86% 180% at 50% 180%);
            }
            @include rpmin(xl){
                clip-path: ellipse(72% 180% at 50% 180%);
            }
        }
    }

    &-container{
        position: relative;
        @include space(padding-top, 100px);


        @include rpmax(md){
            @include space(padding-bottom, 100px);
        }
        
        h1, h2, h3{
            text-align: center;

            color: var(--cl-title-light, #fff);

            @include title(60px);
            line-height: 1.33333;
        }
    }

    &-options{
        @include space(margin-top, 60px);

        display: flex;
        list-style-type: none;
        justify-content: center;

        li + li{
            margin-left: 33px;
        }

        li.is-actived{
            &>a{
                @extend .is-actived;
            }
        }

        @include rpmax(md){
            flex-direction: column;
            
            li{
                a{
                    width: 100%;
                }
            }

            li + li{
                margin-top: 20px;
                margin-left: 0;
            }
        }
    }



    &-wrapper{
        @include space(margin-top, 60px);

        display: flex;
        width: var(--wContainer2, 100%);
        margin-left: auto;
        margin-right: auto;

        display: none;

        &.is-actived{
            display: flex;
        }

        .content{
            display: block;
            width: 100%;
            @include rpmax(md){
                display: flex;
                flex-direction: column;
            }

            &__title{
                order: 1;
                &>*{
                    font-weight: 500;
                    @include title(60px);
                    line-height: 1.333333;

                    color: var(--cl-title, #000);
                }
            }

            &__desc{
                order: 3;

                margin-top: 20px;
                @include content(18px);
                line-height: 1.3888889;
                color: var(--cl-content, #000);
                ul{
                    padding-left: 16px;
                }
            }
        }

        .thumb{ 
            order: 2;
            margin-top: 8px;

            @include rpmin(md){
                float: right;
                flex: 0 0 48%;
                max-width: 48%;
                max-height: var(--varHeight, auto);    
                margin: 0 0 10px 20px;
            }
            
            @include rpmin(lg){
                flex: 0 0 34.8%;
                max-width: 34.8%;
                margin: 0 0 10px 60px;
            }

            img{
                display: block;
            }
        }
    }
}

.about-certificates{
    @include space(margin-top, 100px);
    @include space(margin-bottom, 100px);

    min-height: 400px;

    padding-top: 60px;
    position: relative;

    &-bg{
        pointer-events: none;

        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        width: var(--wContainer, 100%);
    }

    .container + .container{
        @include space(margin-top, 100px);
    }

    &-wrapper{
        position: relative;
        z-index: 1;
        .title{
            &>*{
                @include title(60px);
                font-weight: 500;
                line-height: 1.3333333;
                color: var(--cl-title, #000);
            }
        }
        .swiper{
            @include space(padding-top, 40px);
            @include space(padding-bottom, 60px);

            padding-left: 6px;
            padding-right: 6px;
            margin-left: -6px;
            margin-right: -6px;

            .swiper-slide{
                a, img{
                    display: block;
                    
                    box-shadow: 0 4px 8px rgba(0,0,0,.25);
                }
            }

            .swiper-pagination{
                text-align: left;
                &-bullet{
                    --swiper-pagination-bullet-size: 10px;
                    --swiper-pagination-bullet-inactive-color: #D9D9D9;
                    --swiper-pagination-color: var(--cl-red, #000);
                    --swiper-pagination-bullet-inactive-opacity: .8;

                    border-radius: 0;
                }
            }
        }
    }
}