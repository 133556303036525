

// .scroll-prompt {
// 	position: fixed;
// 	z-index: 10;
// 	left: 50%;

//     // --whPrompt: 20vh;

// 	bottom: -16px;
// 	margin-left: -30vw;
// 	width: 60vw;
// 	height: 20vh;
//     cursor: pointer;
//     transform: scale(.8);

// 	.scroll-prompt-arrow-container {
// 		position: absolute;
// 		bottom: 30px;
// 		left: 50%;
// 		margin-left: -18px;
// 		animation-name: bouncePrompt;
// 		animation-duration: 1.5s;
// 		animation-iteration-count: infinite;
// 	}
// 	.scroll-prompt-arrow {
// 		animation-name: opacityPrompt;
// 		animation-duration: 1.5s;
// 		animation-iteration-count: infinite;
// 	}
// 	.scroll-prompt-arrow:last-child {
// 		animation-direction: reverse;
// 		margin-top: -6px;
// 	}
// 	.scroll-prompt-arrow > div {
// 		width: 36px;
// 		height: 36px;
// 		border-right: 8px solid #bebebe;
// 		border-bottom: 8px solid #bebebe;
// 		transform: rotate(45deg) translateZ(1px);
// 	}
// }

.title-h1{
    text-align: center;
    @include space(margin-bottom, 60px);

    &>*{
        @include title(60px);
        font-weight: 500;
        line-height: 1.33333;
        color: var(--cl-title, #000);
    }
}

.title-h2{
    text-align: center;
    @include space(margin-bottom, 60px);

    &>*{
        @include title(36px);
        @include semi-bold;
        line-height: 1.33333;
        color: var(--cl-title, #000);
    }
}

.readmore{
    --cl-hover: var(--cl-red, #000);
    a{
        display: flex;
        align-items: center;

        &:hover{
            color: var(--cl-hover, #000);
            
            svg{
                transform: rotate(-90deg);
            }
        }
    }
    svg{
        transition: .2s transform linear;
        
        margin-left: 17px;
        margin-top: -2px;

        [stroke]:not([stroke="none"]){
            stroke: currentColor;
        }
        [fill]:not([fill="none"]){
            fill: currentColor;
        }
    }
}



.btn-option{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    background-color: var(--cl-w, #fff);
    color: var(--cl-blue, #000);

    position: relative;

    width: 100%;
    min-width: 280px;
    @include rpmin(lg){
        min-width: 332px;
    }
    padding: 18px 20px 13px 20px;
    height: 80px;
    white-space: nowrap;

    box-shadow: -5px 6px 20px 0 rgba(0,0,0,.15);

    @include title(24px);
    line-height: 1.666667;
    @include bold;
    @include uppercase;

    .icon{
        position: absolute;
        top: 10px;
        left: 10px;

        width: 10px;
        height: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        transition: .3s transform linear;
        img, svg{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        [stroke]:not([stroke="none"]){
            stroke: currentColor;
        }
        [fill]:not([fill="none"]){
            fill: currentColor;
        }
    }

    &.is-actived{
        background-color: var(--cl-blue);
        color: var(--cl-content-light, #FFF);
        .icon{
            transform: rotate(-90deg);
        }
    }
}


.pages-detail{
    @include space(margin-top, 60px);
    @include space(margin-bottom, 100px);

    &-wrapper{
        @include space(padding-top, 80px);
        border-top: 1px solid #000;

        .content{
            &>h2{
                @include title(32px);
                @include semi-bold;
                color: var(--cl-title, #000);
                line-height: 1.2;

                @include space(margin-bottom, 60px);
            }
            &-footer{
                @include space(margin-top, 40px);
            }
        }
    }

}

.pages-related{
    &>*{
        @include title(32px);
        @include semi-bold;
        color: var(--cl-title, #000);
        line-height: 1.2;

        @include space(margin-bottom, 60px);
    }
    ul{
        list-style-type: none;
    }
}

.pages-sidebar{
    ul{
        list-style-type: none;

        li{
            border-top: 1px solid var(--cl-blue, #000);
            border-left: 1px solid var(--cl-blue, #000);
            border-right: 1px solid var(--cl-blue, #000);
        }
        li:last-child{
            border-bottom: 1px solid var(--cl-blue, #000);
        }
    }
    .sidebar-item{
        display: flex;
        align-items: center;
        justify-content: space-between;

        width: 100%;
        height: 100%;
        padding: 20px;

        background-color: #FFF;
        color: var(--cl-blue, #000);

        &__icon{
            width: 60px;
            height: 60px;
            flex: 0 0 60px;

            background-color: var(--cl-blue, #000);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            overflow: hidden;

            transition: .3s all linear;
            img, svg{
                width: auto;
                max-width: 64%;
                max-height: 80%;
                object-fit: contain;
                color: var(--cl-title-light, #FFF);


                [stroke]:not([stroke="none"]){
                    stroke: currentColor;
                }
                [fill]:not([fill="none"]){
                    fill: currentColor;
                }
            }
        }

        &__name{
            flex: 1;
            margin-left: 8px;
            text-align: left;
            &>*{
                @include title(24px);
                line-height: 1.66667;
                @include uppercase;
                color: var(--cl-blue, #000);
                @include bold;
                transition: .3s all linear;
            }
        }

        &__arrow{
            margin-left: 8px;

            [stroke]:not([stroke="none"]){
                stroke: currentColor;
            }
            [fill]:not([fill="none"]){
                fill: currentColor;
            }

            transition: .3s all linear;

        }

        &:hover{
            background-color: var(--cl-blue, #000);
            color: var(--cl-w, #FFF);

            .sidebar-item{
                &__icon{
                    background-color: var(--cl-w, #FFF);

                    img, svg{
                        color: var(--cl-blue, #000);
                    }
                }
                &__name{
                    &>*{
                        color: var(--cl-w, #FFF);
                    }
                }

                &__arrow{
                    transform: rotate(90deg);
                }
            }
        }
    }
}
