.overflow{
    overflow: auto;
}
.overflow-y{
    overflow-x: hidden;
    overflow-y: auto;
}
.overflow-x{
    overflow-y: hidden;
    overflow-x: auto;
}

.overflow, .overflow-y, .overflow-x{
    // max-width: 100%;
    // &::-webkit-scrollbar {
    //     width: 3px;
    //     height: 3px;
    //     background-color: var(--cl-main);
    // }
    
    // /* Track */
    // &::-webkit-scrollbar-track {
    //     box-shadow: inset 0 0 3px var(--cl-main); 
    //     border-radius: 10px;
    // }
    
    // /* Handle */
    // &::-webkit-scrollbar-thumb {
    //     background: var(--cl-xanh); 
    //     border-radius: 10px;
    // }
    
    // /* Handle on hover */
    // &::-webkit-scrollbar-thumb:hover {
    //     background: var(--cl-xanh); 
    // }
}