.block-tb-container{
    --tb-title-bg: var(--cl-blue, #000);
    --tb-content: var(--cl-content, #000);
    --tb-content-light: var(--cl-w, #FFF);
    --tb-border: #cccccc;

    --tb-row-bg: var(--cl-w, #FFF);
    --tb-col-bg: var(--cl-w, #FFF);

    .table_img{
        &-content{
            text-align: center;
            padding: 10px 0;
            @include content(24px);
            color:  white;
            background-color: var(--cl-blue, #000);
        }
        &-image{
            position: relative;
            display: block;
            width: 100%;
            padding-top: 56.25%; // tỉ lệ 16/9
            img{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
               display: block;
            }
        }
    }

    .table_title{
        padding: 10px 0;
        @include rpmin(md){
            padding: 16px 0;
        }
        background-color: var(--tb-title-bg);
        color: var(--tb-content-light);
        @include content(18px);
        text-align: center;
        &>*{
            color: inherit;
            font-size: inherit;
        }
    }
    .tb-container{
        border-top: solid 1px var(--tb-border);
        border-left: solid 1px var(--tb-border);
        // margin-bottom: 40px;
        
        &-row{
            display: flex;
            height: 100%;
            border-bottom: solid 1px var(--tb-border);
            background-color: var(--tb-row-bg);
                
            .table-items{
                border-right: solid 1px var(--tb-border);
                height: 100%;
                &>*:not(:last-child){
                    border-bottom: solid 1px var(--tb-border);
                    
                }   
            }
            .items{
                text-align: center;
                padding: 10px 0;
                color: var(--tb-content);
            }
            .head{
                padding-left: 10px;
                padding-right: 10px;
                min-width: 120px;
                @include rpmin(lg){
                    padding-left: 60px;
                }

                display: flex;
                align-items: center;
                border-right: solid 1px var(--tb-border);
                &>*{
                    @include bold;
                }
            }
            .infor{
                flex: 1;
                display: flex;
                align-items: center;
            }
        }
    }

    & + .block-tb-container{
        @include space(margin-top, 60px);
    }
}