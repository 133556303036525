.header{
    position: relative;
    z-index: 999;
    .container{
        display: flex;
        justify-content: space-between;
        
        padding: 18px 0 20px 0;
        @include rpmin(md){
            padding: 33px 0 33px 0;
        }
    }
    &__logo{
        display: block;
        width: 90px;
        
        @include rpmin(md){
            width: 112px;
        }

        img, svg{
            display: block;
        }
    }

    &-right{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        
        &>*{
            display: flex;
            align-items: center;

            margin-left: 12px;
            @include rpmin(md){
                margin-left: 32px;
            }
        }

        &-toggle{
            display: inline-flex;
            justify-content: center;
            align-items: center;
            padding: 4px;
            font-size: 1.8rem;

            @include rpmin(md){
                display: none;
            }

            position: relative;

            width: 30px;
            height: 30px;

            cursor: pointer;
            transition: .2s all linear;

            span{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                
                color: transparent;
                width: 60%;
                height: 0;
                border-top: 3px solid var(--cl-blue-dark, #000);
                border-radius: 10px;

                transition: .2s all linear;
                font-size: 0px;
            }

            &::after, &::before{
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                width: 60%;
                height: 0;
                border-top: 3px solid var(--cl-blue-dark, #000);
                border-radius: 10px;

                transition: .2s all linear;
            }

            &::before{
                transform: translate(-50%, calc(-50% + 6px)) rotate(0);
            }
            &::after{
                transform: translate(-50%, calc(-50% - 6px)) rotate(0);
            }

            &.showed{
                &::after, &::before{
                    width: 70%;
                }

                span{
                    font-size: 0;
                    width: 0;
                }

                & ~ .header-right-nav{
                    display: flex;
                    flex-direction: column;
                }

                &::before{
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
                &::after{
                    transform: translate(-50%, -50%) rotate(45deg);
                }
            }
        }

        &-nav{
            @include rpmin(md){
                ul{
                    list-style-type: none;
                    display: flex;
                    align-items: center;
    
                    li + li{
                        margin-left: 12px;
                        @include rpmin(lg){
                            margin-left: 23px;
                        }
                    }
    
                    &>li{
                        text-align: center;
                        font-size: 14px;
                        line-height: 143%;
                        font-weight: 500;
                        text-transform: uppercase;
    
                        color: var(--cl-content, #000);
    
                        position: relative;
    
                        padding: 8px 0;
    
                        &.is-actived{
                            color: var(--cl-red, #000);
                        }
    
                        a:hover{
                            color: var(--cl-red, #000);
                        }
    
                        &:hover{
                            &>ul{
                                display: flex;
                            }
                        }
                        
                        .icon{
                            display: none;
                        }
    
                        &>ul{
                            display: none;
    
                            position: absolute;
                            top: 100%;
                            left: -11px;
    
                            width: auto;
                            min-width: 140px;
    
                            align-items: flex-start;
                            flex-direction: column;
    
                            background-color: #FFF;
                            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                            text-align: left;
                            padding: 15px 21px 15px 11px;
    
                            li + li{
                                margin-left: 0;
                                margin-top: 10px;
                            }
    
                            &>li{
                                width: auto;
                                white-space: nowrap;
                                text-align: left;
                                padding: 0;
    
                                color: var(--cl-content, #000);
    
                                &.is-actived{
                                    color: var(--cl-blue, #000);
                                }
                                a{
                                    text-transform: uppercase;
                                }

    
                                a:hover{
                                    color: var(--cl-blue, #000);
                                }
                            }
                        }
                    }
                }

                
            }

            @include rpmax(md){
                display: none;

                position: absolute;
                top: 100%;
                right: 0;
                width: 100%;
                height: calc(100vh - #{var(--hHeader, 0px)});
                background-color: var(--cl-blue, #FFF);

                overflow-x: hidden;
                overflow-y: auto;

                ul{
                    width: 100%;
                    flex-direction: column;
                    align-items: flex-start;
                    color: var(--cl-content-light, #000);


                    &>li{
                        font-size: 14px;
                        line-height: 143%;
                        font-weight: 500;
                        text-transform: uppercase;
    
                        color: var(--cl-content, #000);

                        text-align: left;
                        width: 100%;
                        color: var(--cl-content-light, #000);
                        padding: 10px 2vw;

    
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;

                        &>.icon{
                            flex: 1;
                            text-align: right;
                            svg{
                                transition: .2s transform linear;
                            }
                        }

                        &>ul{
                            margin-top: 4px;
                            // display: block;
                            display: none;

                            &>li{
                                width: auto;
                                white-space: nowrap;
                                text-align: left;
                                padding: 8px 2vw;
    
                                color: var(--cl-content-light, #000);
    
                                &.is-actived{
                                    color: var(--cl-w, #000);
                                }

                                a{
                                    text-transform: initial;
                                }
                            }
                        }

                        
                        &.is-actived{
                            color: var(--cl-red, #000);
                        }

                        &.is-showed{
                            .icon{
                                svg{
                                    transform: rotate(180deg);
                                }
                            }
                            &>ul{
                                display: block;
                                flex: 0 0 100%;
                                max-width: 100%;
                            }

                            a:hover{
                                color: var(--cl-red, #000);
                            }
                        }
                    }
                }


            }
        }

        &-account{
            position: relative;
            height: 100%;
            &::after{
                content: "";
                position: absolute;
                top: calc(50% + 20px);
                left: 50%;
                width: 300%;
                height: 20px;
                background-color: transparent;
                transform: translate(-50%, -50%);
                z-index: 2;
            }
            &>a{
                display: block;
                width: 20px;
                height: 20px;

                color: var(--cl-b, #000);

                img, svg{
                    display: block;
                    object-fit: contain;
                    height: 100%;
                    width: auto;
                    max-width: 100%;
                }

                [stroke]:not([stroke="none"]){
                    stroke: currentColor;
                }
                [fill]:not([fill="none"]){
                    fill: currentColor;
                }
            }


            &.is-actived{
                color: var(--cl-red, #000);
            }

            &>a:hover, &>a:focus{
                color: var(--cl-red, #000);
                
                & ~ ul{
                    display: flex;
                }
            }

            &:hover{
                &>ul{
                    display: flex;
                }
            }

            &>ul{
                display: none;

                position: absolute;
                top: calc(50% + 20px);
                right: -11px;
                left: auto;

                width: auto;
                min-width: 140px;

                list-style-type: none;

                align-items: flex-start;
                flex-direction: column;

                background-color: #FFF;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                text-align: left;
                padding: 15px 21px 15px 11px;

                li + li{
                    margin-left: 0;
                    margin-top: 10px;
                }

                &>li{
                    width: auto;
                    white-space: nowrap;
                    text-align: left;
                    padding: 0;

                    color: var(--cl-content, #000);

                    &.is-actived{
                        color: var(--cl-blue, #000);
                    }

                    a:hover{
                        color: var(--cl-blue, #000);
                    }
                }
            }
        }


        &-languages{
            ul{
                display: flex;

                list-style-type: none;
                li + li{
                    margin-left: 15px;
                }

                li{
                    height: 22px;
                    width: 32.64px;
                    a{
                        display: block;
                        width: 100%;
                        height: 100%;
                        img, svg{
                            display: block;
                            width: 100%;height: 100%;
                        }
                    }
                    &.selected{
                        position: relative;
    
                        &::after{
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: var(--cl-blue, #000);
                            opacity: .8;
                            mix-blend-mode: multiply;
                        }
                    }
                }
            }
        }
    }
}