.services-list{
    @include space(margin-top, 60px);
    @include space(margin-bottom, 100px);

    // &__title{
    //     text-align: center;

    //     @include space(margin-bottom, 60px);

    //     &>*{
    //         @include title(60px);
    //         font-weight: 500;
    //         line-height: 1.33333;
    //         color: var(--cl-title, #000);
    //     }
    // }

    .service-item{
        box-shadow: 0 4px 4px rgba(0,0,0,.25);

        display: flex;
        height: 100%;
        flex-direction: column;

        &-head{
            position: relative;
            flex: 1;
            display: flex;
            flex-direction: column;

            .head-bg{
                position: absolute;
                z-index: 1;

                top: 0%;
                left: 0;
                width: 100%;
                height: 0;
                padding-top: 73.66%;
                transition: .3s all linear;

            
                img, svg{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: .3s all linear;
                }

                &::after{
                    content: "";
                    position: absolute;
                    --varside: 32px;
                    top: var(--varside, 0px);
                    left: var(--varside, 0px);
                    bottom: var(--varside, 0px);
                    right: var(--varside, 0px);

                    background-color: var(--cl-blue, #000);
                    opacity: 0.8;
                    transition: .3s all linear;
                }
            }

            .head-content{
                position: relative;
                z-index: 1;
                width: 100%;
                padding-top: 73.66%;
            }

            .head-name{
                position: absolute;
                z-index: 1;

                top: 0%;
                left: 0;
                width: 100%;
                height: 0;
                padding-top: 73.66%;

                &__wrapper{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;

                    align-items: center;
                    justify-content: center;

                    &>*{
                        color: var(--cl-title-light, #FFF);

                        margin-top: 10px;

                        @include title(24px);
                        @include bold;
                        line-height: 1.666667;

                        @include uppercase;
                    }
                }
            }

            .head-desc{
                flex: 1;

                padding: 25px 32px;
                @include content(18px);
                color: var(--cl-content, #000);
                line-height: 1.388889;

                text-align: center;
            }
        }

        &-more{
            padding: 25px 32px;
            position: relative;
            text-align: center;

            a{
                text-align: center;
                justify-content: center;
            }
            
            &::before{
                content: "";
                position: absolute;
                top:0;
                left: 50%;
                width: calc(100% - 32px - 32px);
                transform: translateX(-50%);
                height: 0;
                border-top: 1px solid #DDDDDD;
                transition: .3s all ease;
            }

            &::after{
                content: "";
                position: absolute;
                top: 0;
                left: 50%;
                width: 0;
                height: 100%;
                transform: translateX(-50%);

                background-color: var(--cl-red, #000);
                transition: .3s all ease;
                z-index: -1;
            }
            transition: .3s all ease;
        }

        &:hover{
            .service-item{
                &-head{
                    .head-bg{
                        height: 100%;
                        &::after{
                            --varside: 0;
                        }
                    }

                    // .head-content{
                    //     padding-top: 0;
                    // }

                    .head-desc{
                        color: var(--cl-content-light, #FFF);
                    }
                }
                &-more{
                    &::before, &::after{
                        border-top: 0px;
                        width: 100%;
                    }

                    --cl-hover: var(--cl-content-light, #FFF);
                    color: var(--cl-content-light, #FFF);
                }
            }
        }
    }
}