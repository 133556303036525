.footer{

    &-wrapper{
        position: relative;
        color: var(--cl-content-light, #FFF);
        background-image: url('../assets/images/footer-bg.jpg');
        background-size: cover;

        padding: 70px 0 64px 0;

        // @include rpmax(lg){
        //     .row>*:first-child{
        //         width: 10
        //     }
        // }

        &::before{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--cl-blue, #000);
            opacity: 0.9;
            z-index: 1;
        }

        &>*{
            position: relative;
            z-index: 2;
        }
    }

    &-copyright{
        border-top: 1px solid #FFFFFF;
        background-color: #002E5C;
        color: var(--cl-content-light, #FFF);
        font-size: 14px;
        font-weight: 400;
        line-height: 157%;
        padding: 18px 0;

        a{
            display: inline-block;
            color: currentColor;

            &:hover{
                color: var(--cl-red, #FFF);
            }
        }

        .container{
            display: flex;
            justify-content: space-between;
            align-items: center;

            .footer-copyright__content{
                flex: 1;
            }
        }

        &-socials{
            ul{
                list-style-type: none;
                display: flex;
                li + li {
                    margin-left: 22px;
                }
                li{
                    width: 18px;
                    height: 18px;
                    a{
                        display: block;
                        width: 100%;
                        height: 100%;

                        svg, img{
                            display: block;
                            max-width: 100%;
                            width: auto;
                            height: 100%;
                            object-fit: contain
                        }

                        transition: .16s transform linear;
                        &:hover{
                            transform: scale(1.08);
                        }
                    }
                }
            }
        }
    }

    &-infor{
        @include content(16px);
        line-height: 188%;
        p, span{
            font-weight: 500;
        }

        a{
            color: currentColor;
            &:hover{
                color: var(--cl-red, #FFF);
            }
        }

        &__logo{
            display: block;
            width: 114px;
            max-width: 100%;
            margin-bottom: 32px;

            img, svg{
                display: block;
            }

            
            @include rpmin(md){
                margin-left: auto;
                margin-right: auto;
            }
            @include rpmin(lg){
                margin-left: 0;
                margin-right: 0;
            }
        }

        @include rpmin(md){
            text-align: center;
        }
        @include rpmin(lg){
            text-align: left;
        }
    }

    &-nav{
        h3{
            @include title(20px);
            line-height: 175%;
            font-weight: 500;
            text-transform: uppercase;  
        }

        ul{
            margin-top: 15px;
            list-style-type: none;
            &>li{
                display: block;
                font-size: 15px;
                font-weight: 400;
                line-height: 133%;
                text-transform: uppercase;

                a{
                    color: currentColor;

                    &:hover{
                        color: var(--cl-red, #FFF);
                    }
                }
                & + li{
                    margin-top: 18px;
                }
            }
        }
    }
}