#trackTracePage{
    .breadcrumbs-list{
        justify-content: flex-start;
    }
}

.tracktrace-searches{
    @include space(margin-top, 100px);
    @include space(margin-bottom, 100px);

    &-wrapper{
        margin-top: 16px;

        .wrapper-select{
            // background: var(--cl-blue, #000);
            position: relative;
            border-radius: 10px;
            padding: 44px 33px;

            &::before{
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: var(--cl-blue, #000);
                opacity: .76;
                border-radius: inherit;
            }

            &>*{
                position: relative;
                z-index: 1;
            }

            .item{
                height: 100%;
                
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;

                width: 100%;
                padding: 30px 20px;

                @include content(16px);
                @include regular;
                line-height: 1.4;
                text-transform: uppercase;

                background-color: var(--cl-blue, #000);
                color: var(--cl-content-light, #FFF);

                box-shadow: -5px 6px 20px rgba(0,0,0,.15);

                &:hover, &.is-actived{
                    background: var(--cl-w, #FFF);
                    color: var(--cl-blue, #000);
                }
            }
        }

        .wrapper-option{
            .option-item{
                margin-top: 50px;

                display: none;
                &.is-actived{
                    display: block;
                }
                form{
                    .group-input{
                        input, textarea{
                            resize: none;

                            appearance: none;
                            background: none;
                            outline: none;
                            border: none;

                            padding: 20px;
                            height: 60px;
                            @include content(16px);
                            color: #171717;
                            @include semi-bold;
                            text-transform: uppercase;

                            background: #FFF;
                            border: 1px solid #BFBFBF;
                            border-radius: 4px;
                        }
                    }

                    .group-submit, .group-input{
                        height: 100%;
                        display: flex;
                        flex-direction: column;

                        label{
                            margin-bottom: 10px;
                            @include rpmin(md){
                                margin-bottom: 16px;
                            }

                            @include title(24px);
                            @include regular;
                            color: var(--cl-title, #000);
                            
                            span{
                                color: var(--cl-red, currentColor);
                            }
                        }
                    }

                    .group-submit{
                        display: block;
                        label{
                            width: 100%;
                            display: none;
                            @include rpmin(lg){
                                display: block;
                            }
                        }
                        input[type=submit], button[type="submit"]{
                            appearance: none;
                            background: none;
                            outline: none;
                            border: none;

                            width: auto;
                            flex: 0 0 auto;
                            height: 60px;
                            padding: 20px;
                            min-width: 175px;
                            max-width: 100%;

                            width: 100%;
                            @include rpmin(sm){
                                width: auto;
                            }

                            border-radius: 10px;

                            @include content(16px);
                            @include medium;

                            background: var(--cl-red, #000);
                            color: #FFF;

                        }
                    }
                }

                &-results{
                    @include space(margin-top, 60px);
                    list-style-type: none;
                    position: relative;

                    &>ul{
                        list-style-type: none;
                    }
                    .result-item{
                        &-name{
                            display: flex;
                            justify-content: space-between;

                            background-color: var(--cl-blue, #000);
                            color: var(--cl-title-light, #FFF);
                            border-radius: 8px;

                            &>*{
                                color: currentColor;
                                padding: 17px 32px 17px 32px;

                                @include content(18px);
                                @include regular;
                                line-height: 1.33333;
                            }

                            .icon{
                                flex: 1;
                                display: flex;
                                justify-content: flex-end;
                                align-items: center;

                                svg{
                                    transform: rotate(180deg);
                                    transition: .2s all ease;
                                }
                            }

                            [stroke]:not([stroke="none"]){
                                stroke: currentColor;
                            }
                            [fill]:not([fill="none"]){
                                fill: currentColor;
                            }

                            &.is-actived{
                                .icon{
                                    svg{
                                        transform: rotate(0deg);
                                    }
                                }
                                & ~ .result-item-content{
                                    display: block;
                                }
                            }
                        }

                        &-content{
                            display: none;
                            margin-top: 30px;
                        }
                    }

                    li + li, .result-item + .result-item{
                        @include space(margin-top, 60px);
                    }

                    &>*{
                        @include content(18px);
                        color: var(--cl-content, #000);
                        @include medium;

                        &>a{
                            color: var(--cl-blue, currentColor);
                            text-decoration: underline;
                        }

                        &:not(:first-child){
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }
}