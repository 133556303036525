////////////////////////////////////////////////
/////////////////////////MEDIA ///////////////////
// $fpsize:(
// 	w: 1279px,
// 	h: 539px
// );


@mixin rp($canvas) {
    @media screen and (max-width: map-get($media, #{$canvas})){
        @content;
    }
}
@mixin rpmin($canvas) {
    @media screen and (min-width: map-get($minWidth, #{$canvas})){
        @content;
    }
}
@mixin rpmax($canvas) {
    @media screen and (max-width: map-get($maxWidth, #{$canvas})){
        @content;
    }
}

// @mixin fp{
//     @media (max-width: map-get($fpsize, w)), (max-height: map-get($fpsize, h)){
//         @content;
//     }
// }

// @mixin fph{
//     @media (min-width: 1280px) and (max-height: 768px){
//         @content;
//     }
// }
  
/////////////////////////MEDIA ///////////////////
//////////////////////////////////////////////////

