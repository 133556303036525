.popup-form{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    // display: flex;
    justify-content: center;
    align-items: center;

    display: none;
    &.is-actived{
        display: flex;
    }

    .popup-form-bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.8;
    }

    .modal{
        position: relative;
        z-index: 1;

        &-close{
            position: absolute;
            top: calc(100% + 40px);
            left: 50%;
            transform: translateX(-50%);

            width: 30px;
            height: 30px;
            border-radius: 100%;
            background-color: #FFF;

            cursor: pointer;
            transition: .2s all linear;

            &::after, &::before{
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                width: 42%;
                height: 0;
                border-top: 1px solid #000;
                border-radius: 10px;

            }

            &::before{
                transform: translate(-50%, -50%) rotate(-45deg);
            }
            &::after{
                transform: translate(-50%, -50%) rotate(45deg);
            }

            &:hover, &:focus{
                transform: translateX(-50%) rotate(90deg) scale(1.1);
            }
        }

        &-title{
            position: absolute;
            bottom: calc(100%);
            left: 0;

            
            width: 100%;
            padding-top: 10px;
            padding-bottom: 30px;
            text-align: center;

            &>*{
                color: var(--cl-title-light, #FFF);
                @include title(24px);
                @include bold;
                text-transform: uppercase;
            }
        }

        &-dialog{
            background-color: var(--cl-w, #FFF);
            padding: 40px 30px;
            
            width: var(--wDialog, 36vw);

            border-radius: 10px;

            max-width: var(--wContainer, 100%);
            max-height: calc(100vh - 180px);
            overflow: auto;
        }
        &-content{
            @include title(24px);
            @include medium;    
            color: var(--cl-content, #000);
            text-align: center;
        }
        &-buttons{
            margin-top: 30px;

            display: flex;
            justify-content: center;
            flex-wrap: wrap;


            &>*{
                appearance: none;
                background: none;
                outline: none;
                border: none;

                margin-top: 10px;
                margin-left: 10px;
                margin-right: 10px;

                min-width: 140px;
                border-radius: 10px;

                display: inline-flex;
                justify-content: center;
                align-items: center;
            
                color: var(--cl-w, #FFF);
                @include title(24px);
                @include medium;
                padding: 10px 18px 6px 18px;
                background-color: var(--cl-red, #000);

                transition: .2s all linear;

                &:hover{
                    background-color: var(--cl-blue, #000);
                    box-shadow: 0 4px 4px rgba(0,0,0,.25);
                }
            }
        }

        &-form{
            .group-input{
                display: flex;
                flex-direction: column;

                label{
                    @include content(16px);
                    @include medium;
                    span{
                        color: var(--cl-red, red);
                    }
                }

                &>.row>*:last-child{
                    text-align: right;
                }

                input, textarea{
                    margin-top: 8px;

                    resize: none;

                    appearance: none;
                    background: none;
                    outline: none;
                    border: none;

                    background-color: #F9F9F9;
                    border-radius: 4px;
                    border: 1px solid #EEEEEE;

                    color: #111111;

                    @include content(16px);
                    @include medium;
                    line-height: 1.2;

                    padding: 8px;

                    &:disabled{
                        pointer-events: none;
                        opacity: .7;
                    }
                }
            }

            .group-submit{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;

                button, input{
                    appearance: none;
                    background: none;
                    outline: none;
                    border: none;

                    display: block;
                    width: 100%;
                    padding: 8px 20px;

                    border-radius: 8px;

                    @include content(16px);
                    @include medium;
                    line-height: 1.2;

                    color: var(--cl-w, #FFF);
                    background-color: var(--cl-red, #000);
                    transition: .2s all linear;

                    &:hover{
                        background-color: var(--cl-blue, #000);
                        box-shadow: 0 4px 4px rgba(0,0,0,.25);
                    }
                }
            }

            a{
                color: var(--cl-blue, #000);
                display: inline-block;

                &:hover{
                    color: var(--cl-red, #000);
                }
            }
        }
    }
}