/*!
* source: https://getbootstrap.com/
* Bootstrap Grid v5.0.2 (https://getbootstrap.com/)
* Copyright 2011-2021 The Bootstrap Authors
* Copyright 2011-2021 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
*/
@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap");
.container,
.container-left,
.container-right {
  width: 96vw;
  margin-left: auto;
  margin-right: auto; }

.container-96 {
  width: 97.2vw;
  max-width: calc(1440px - 2.8vw);
  margin-left: auto;
  margin-right: auto; }

.container-left,
.container-right {
  width: calc( (100vw - var(--wContainer))/2  + var(--wContainer)); }

.container-right {
  margin-right: initial;
  margin-left: auto; }

.container-left {
  margin-right: auto;
  margin-left: initial; }

@media (min-width: 1280px) {
  .container {
    width: 1224px; } }

@media (min-width: 1440px) {
  .container {
    width: 1311.08px; } }

.col {
  flex: 1 0 0%; }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);
  width: calc(100% + (var(--bs-gutter-x) * 0.5 * 2)); }
  .row > * {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }
  .row-cols-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .row-cols-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%; }

@media (min-width: 420px) {
  .col-sx {
    flex: 1 0 0%; }
  .row-cols-sx-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-1-sx > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-2-sx > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-3-sx > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-4-sx > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-5-sx > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-6-sx > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-7-sx > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-8-sx > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-9-sx > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-10-sx > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-11-sx > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-12-sx > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-1-sm > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-2-sm > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-3-sm > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-4-sm > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-5-sm > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-6-sm > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-7-sm > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-8-sm > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-9-sm > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-10-sm > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-11-sm > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-12-sm > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-1-md > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-2-md > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-3-md > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-4-md > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-5-md > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-6-md > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-7-md > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-8-md > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-9-md > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-10-md > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-11-md > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-12-md > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 1024px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-1-lg > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-2-lg > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-3-lg > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-4-lg > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-5-lg > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-6-lg > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-7-lg > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-8-lg > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-9-lg > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-10-lg > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-11-lg > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-12-lg > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 1280px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-1-xl > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-2-xl > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-3-xl > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-4-xl > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-5-xl > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-6-xl > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-7-xl > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-8-xl > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-9-xl > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-10-xl > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-11-xl > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-12-xl > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 1440px) {
  .col-yl {
    flex: 1 0 0%; }
  .row-cols-yl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-1-yl > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-2-yl > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-3-yl > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-4-yl > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-5-yl > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-6-yl > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-7-yl > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-8-yl > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-9-yl > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-10-yl > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-11-yl > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-12-yl > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-none {
  width: 0;
  flex: 0 0 0; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

@media (min-width: 420px) {
  .order-1-sx {
    order: 1; }
  .order-2-sx {
    order: 2; }
  .order-3-sx {
    order: 3; }
  .order-4-sx {
    order: 4; }
  .order-5-sx {
    order: 5; }
  .order-6-sx {
    order: 6; }
  .order-7-sx {
    order: 7; }
  .order-8-sx {
    order: 8; }
  .order-9-sx {
    order: 9; }
  .order-10-sx {
    order: 10; } }

@media (min-width: 576px) {
  .order-1-sm {
    order: 1; }
  .order-2-sm {
    order: 2; }
  .order-3-sm {
    order: 3; }
  .order-4-sm {
    order: 4; }
  .order-5-sm {
    order: 5; }
  .order-6-sm {
    order: 6; }
  .order-7-sm {
    order: 7; }
  .order-8-sm {
    order: 8; }
  .order-9-sm {
    order: 9; }
  .order-10-sm {
    order: 10; } }

@media (min-width: 768px) {
  .order-1-md {
    order: 1; }
  .order-2-md {
    order: 2; }
  .order-3-md {
    order: 3; }
  .order-4-md {
    order: 4; }
  .order-5-md {
    order: 5; }
  .order-6-md {
    order: 6; }
  .order-7-md {
    order: 7; }
  .order-8-md {
    order: 8; }
  .order-9-md {
    order: 9; }
  .order-10-md {
    order: 10; } }

@media (min-width: 1024px) {
  .order-1-lg {
    order: 1; }
  .order-2-lg {
    order: 2; }
  .order-3-lg {
    order: 3; }
  .order-4-lg {
    order: 4; }
  .order-5-lg {
    order: 5; }
  .order-6-lg {
    order: 6; }
  .order-7-lg {
    order: 7; }
  .order-8-lg {
    order: 8; }
  .order-9-lg {
    order: 9; }
  .order-10-lg {
    order: 10; } }

@media (min-width: 1280px) {
  .order-1-xl {
    order: 1; }
  .order-2-xl {
    order: 2; }
  .order-3-xl {
    order: 3; }
  .order-4-xl {
    order: 4; }
  .order-5-xl {
    order: 5; }
  .order-6-xl {
    order: 6; }
  .order-7-xl {
    order: 7; }
  .order-8-xl {
    order: 8; }
  .order-9-xl {
    order: 9; }
  .order-10-xl {
    order: 10; } }

@media (min-width: 1440px) {
  .order-1-yl {
    order: 1; }
  .order-2-yl {
    order: 2; }
  .order-3-yl {
    order: 3; }
  .order-4-yl {
    order: 4; }
  .order-5-yl {
    order: 5; }
  .order-6-yl {
    order: 6; }
  .order-7-yl {
    order: 7; }
  .order-8-yl {
    order: 8; }
  .order-9-yl {
    order: 9; }
  .order-10-yl {
    order: 10; } }

.offset-0 {
  margin-left: 0%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.offset-l-0 {
  margin-left: 0%; }

.offset-l-1 {
  margin-left: 8.33333%; }

.offset-l-2 {
  margin-left: 16.66667%; }

.offset-l-3 {
  margin-left: 25%; }

.offset-l-4 {
  margin-left: 33.33333%; }

.offset-l-5 {
  margin-left: 41.66667%; }

.offset-l-6 {
  margin-left: 50%; }

.offset-l-7 {
  margin-left: 58.33333%; }

.offset-l-8 {
  margin-left: 66.66667%; }

.offset-l-9 {
  margin-left: 75%; }

.offset-l-10 {
  margin-left: 83.33333%; }

.offset-l-11 {
  margin-left: 91.66667%; }

.offset-r-0 {
  margin-right: 0%; }

.offset-r-1 {
  margin-right: 8.33333%; }

.offset-r-2 {
  margin-right: 16.66667%; }

.offset-r-3 {
  margin-right: 25%; }

.offset-r-4 {
  margin-right: 33.33333%; }

.offset-r-5 {
  margin-right: 41.66667%; }

.offset-r-6 {
  margin-right: 50%; }

.offset-r-7 {
  margin-right: 58.33333%; }

.offset-r-8 {
  margin-right: 66.66667%; }

.offset-r-9 {
  margin-right: 75%; }

.offset-r-10 {
  margin-right: 83.33333%; }

.offset-r-11 {
  margin-right: 91.66667%; }

.gx-0 {
  --bs-gutter-x: 0; }

.gy-0 {
  --bs-gutter-y: 0; }

.gx-1 {
  --bs-gutter-x: 1px; }

.gy-1 {
  --bs-gutter-y: 1px; }

.gx-2 {
  --bs-gutter-x: 2px; }

.gy-2 {
  --bs-gutter-y: 2px; }

.gx-10 {
  --bs-gutter-x: 10px; }

.gy-10 {
  --bs-gutter-y: 10px; }

.gx-12 {
  --bs-gutter-x: 12px; }

.gy-12 {
  --bs-gutter-y: 12px; }

.gx-16 {
  --bs-gutter-x: 16px; }

.gy-16 {
  --bs-gutter-y: 16px; }

.gx-20 {
  --bs-gutter-x: 20px; }

.gy-20 {
  --bs-gutter-y: 20px; }

.gx-22 {
  --bs-gutter-x: 22px; }

.gy-22 {
  --bs-gutter-y: 22px; }

.gx-24 {
  --bs-gutter-x: 24px; }

.gy-24 {
  --bs-gutter-y: 24px; }

.gx-30 {
  --bs-gutter-x: 30px; }

.gy-30 {
  --bs-gutter-y: 30px; }

.gx-32 {
  --bs-gutter-x: 32px; }

.gy-32 {
  --bs-gutter-y: 32px; }

.gx-36 {
  --bs-gutter-x: 36px; }

.gy-36 {
  --bs-gutter-y: 36px; }

.gx-40 {
  --bs-gutter-x: 40px; }

.gy-40 {
  --bs-gutter-y: 40px; }

.gx-42 {
  --bs-gutter-x: 42px; }

.gy-42 {
  --bs-gutter-y: 42px; }

.gx-46 {
  --bs-gutter-x: 46px; }

.gy-46 {
  --bs-gutter-y: 46px; }

.gx-50 {
  --bs-gutter-x: 50px; }

.gy-50 {
  --bs-gutter-y: 50px; }

.gx-56 {
  --bs-gutter-x: 56px; }

.gy-56 {
  --bs-gutter-y: 56px; }

.gx-60 {
  --bs-gutter-x: 60px; }

.gy-60 {
  --bs-gutter-y: 60px; }

.gx-64 {
  --bs-gutter-x: 64px; }

.gy-64 {
  --bs-gutter-y: 64px; }

.gx-75 {
  --bs-gutter-x: 75px; }

.gy-75 {
  --bs-gutter-y: 75px; }

.gx-100 {
  --bs-gutter-x: 100px; }

.gy-100 {
  --bs-gutter-y: 100px; }

@media (min-width: 420px) {
  .col-auto-sx {
    flex: 0 0 auto;
    width: auto; }
  .col-1-sx {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-2-sx {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-3-sx {
    flex: 0 0 auto;
    width: 25%; }
  .col-4-sx {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-5-sx {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-6-sx {
    flex: 0 0 auto;
    width: 50%; }
  .col-7-sx {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-8-sx {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-9-sx {
    flex: 0 0 auto;
    width: 75%; }
  .col-10-sx {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-11-sx {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-12-sx {
    flex: 0 0 auto;
    width: 100%; }
  .offset-0-sx > * {
    margin-left: 0%; }
  .offset-1-sx > * {
    margin-left: 8.33333%; }
  .offset-2-sx > * {
    margin-left: 16.66667%; }
  .offset-3-sx > * {
    margin-left: 25%; }
  .offset-4-sx > * {
    margin-left: 33.33333%; }
  .offset-5-sx > * {
    margin-left: 41.66667%; }
  .offset-6-sx > * {
    margin-left: 50%; }
  .offset-7-sx > * {
    margin-left: 58.33333%; }
  .offset-8-sx > * {
    margin-left: 66.66667%; }
  .offset-9-sx > * {
    margin-left: 75%; }
  .offset-10-sx > * {
    margin-left: 83.33333%; }
  .offset-11-sx > * {
    margin-left: 91.66667%; }
  .gx-0-sx {
    --bs-gutter-x: 0; }
  .gy-0-sx {
    --bs-gutter-y: 0; }
  .gx-1-sx {
    --bs-gutter-x: 1px; }
  .gy-1-sx {
    --bs-gutter-y: 1px; }
  .gx-2-sx {
    --bs-gutter-x: 2px; }
  .gy-2-sx {
    --bs-gutter-y: 2px; }
  .gx-10-sx {
    --bs-gutter-x: 10px; }
  .gy-10-sx {
    --bs-gutter-y: 10px; }
  .gx-12-sx {
    --bs-gutter-x: 12px; }
  .gy-12-sx {
    --bs-gutter-y: 12px; }
  .gx-16-sx {
    --bs-gutter-x: 16px; }
  .gy-16-sx {
    --bs-gutter-y: 16px; }
  .gx-20-sx {
    --bs-gutter-x: 20px; }
  .gy-20-sx {
    --bs-gutter-y: 20px; }
  .gx-22-sx {
    --bs-gutter-x: 22px; }
  .gy-22-sx {
    --bs-gutter-y: 22px; }
  .gx-24-sx {
    --bs-gutter-x: 24px; }
  .gy-24-sx {
    --bs-gutter-y: 24px; }
  .gx-30-sx {
    --bs-gutter-x: 30px; }
  .gy-30-sx {
    --bs-gutter-y: 30px; }
  .gx-32-sx {
    --bs-gutter-x: 32px; }
  .gy-32-sx {
    --bs-gutter-y: 32px; }
  .gx-36-sx {
    --bs-gutter-x: 36px; }
  .gy-36-sx {
    --bs-gutter-y: 36px; }
  .gx-40-sx {
    --bs-gutter-x: 40px; }
  .gy-40-sx {
    --bs-gutter-y: 40px; }
  .gx-42-sx {
    --bs-gutter-x: 42px; }
  .gy-42-sx {
    --bs-gutter-y: 42px; }
  .gx-46-sx {
    --bs-gutter-x: 46px; }
  .gy-46-sx {
    --bs-gutter-y: 46px; }
  .gx-50-sx {
    --bs-gutter-x: 50px; }
  .gy-50-sx {
    --bs-gutter-y: 50px; }
  .gx-56-sx {
    --bs-gutter-x: 56px; }
  .gy-56-sx {
    --bs-gutter-y: 56px; }
  .gx-60-sx {
    --bs-gutter-x: 60px; }
  .gy-60-sx {
    --bs-gutter-y: 60px; }
  .gx-64-sx {
    --bs-gutter-x: 64px; }
  .gy-64-sx {
    --bs-gutter-y: 64px; }
  .gx-75-sx {
    --bs-gutter-x: 75px; }
  .gy-75-sx {
    --bs-gutter-y: 75px; }
  .gx-100-sx {
    --bs-gutter-x: 100px; }
  .gy-100-sx {
    --bs-gutter-y: 100px; } }

@media (min-width: 576px) {
  .col-auto-sm {
    flex: 0 0 auto;
    width: auto; }
  .col-1-sm {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-2-sm {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-3-sm {
    flex: 0 0 auto;
    width: 25%; }
  .col-4-sm {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-5-sm {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-6-sm {
    flex: 0 0 auto;
    width: 50%; }
  .col-7-sm {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-8-sm {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-9-sm {
    flex: 0 0 auto;
    width: 75%; }
  .col-10-sm {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-11-sm {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-12-sm {
    flex: 0 0 auto;
    width: 100%; }
  .offset-0-sm > * {
    margin-left: 0%; }
  .offset-1-sm > * {
    margin-left: 8.33333%; }
  .offset-2-sm > * {
    margin-left: 16.66667%; }
  .offset-3-sm > * {
    margin-left: 25%; }
  .offset-4-sm > * {
    margin-left: 33.33333%; }
  .offset-5-sm > * {
    margin-left: 41.66667%; }
  .offset-6-sm > * {
    margin-left: 50%; }
  .offset-7-sm > * {
    margin-left: 58.33333%; }
  .offset-8-sm > * {
    margin-left: 66.66667%; }
  .offset-9-sm > * {
    margin-left: 75%; }
  .offset-10-sm > * {
    margin-left: 83.33333%; }
  .offset-11-sm > * {
    margin-left: 91.66667%; }
  .gx-0-sm {
    --bs-gutter-x: 0; }
  .gy-0-sm {
    --bs-gutter-y: 0; }
  .gx-1-sm {
    --bs-gutter-x: 1px; }
  .gy-1-sm {
    --bs-gutter-y: 1px; }
  .gx-2-sm {
    --bs-gutter-x: 2px; }
  .gy-2-sm {
    --bs-gutter-y: 2px; }
  .gx-10-sm {
    --bs-gutter-x: 10px; }
  .gy-10-sm {
    --bs-gutter-y: 10px; }
  .gx-12-sm {
    --bs-gutter-x: 12px; }
  .gy-12-sm {
    --bs-gutter-y: 12px; }
  .gx-16-sm {
    --bs-gutter-x: 16px; }
  .gy-16-sm {
    --bs-gutter-y: 16px; }
  .gx-20-sm {
    --bs-gutter-x: 20px; }
  .gy-20-sm {
    --bs-gutter-y: 20px; }
  .gx-22-sm {
    --bs-gutter-x: 22px; }
  .gy-22-sm {
    --bs-gutter-y: 22px; }
  .gx-24-sm {
    --bs-gutter-x: 24px; }
  .gy-24-sm {
    --bs-gutter-y: 24px; }
  .gx-30-sm {
    --bs-gutter-x: 30px; }
  .gy-30-sm {
    --bs-gutter-y: 30px; }
  .gx-32-sm {
    --bs-gutter-x: 32px; }
  .gy-32-sm {
    --bs-gutter-y: 32px; }
  .gx-36-sm {
    --bs-gutter-x: 36px; }
  .gy-36-sm {
    --bs-gutter-y: 36px; }
  .gx-40-sm {
    --bs-gutter-x: 40px; }
  .gy-40-sm {
    --bs-gutter-y: 40px; }
  .gx-42-sm {
    --bs-gutter-x: 42px; }
  .gy-42-sm {
    --bs-gutter-y: 42px; }
  .gx-46-sm {
    --bs-gutter-x: 46px; }
  .gy-46-sm {
    --bs-gutter-y: 46px; }
  .gx-50-sm {
    --bs-gutter-x: 50px; }
  .gy-50-sm {
    --bs-gutter-y: 50px; }
  .gx-56-sm {
    --bs-gutter-x: 56px; }
  .gy-56-sm {
    --bs-gutter-y: 56px; }
  .gx-60-sm {
    --bs-gutter-x: 60px; }
  .gy-60-sm {
    --bs-gutter-y: 60px; }
  .gx-64-sm {
    --bs-gutter-x: 64px; }
  .gy-64-sm {
    --bs-gutter-y: 64px; }
  .gx-75-sm {
    --bs-gutter-x: 75px; }
  .gy-75-sm {
    --bs-gutter-y: 75px; }
  .gx-100-sm {
    --bs-gutter-x: 100px; }
  .gy-100-sm {
    --bs-gutter-y: 100px; } }

@media (min-width: 768px) {
  .col-auto-md {
    flex: 0 0 auto;
    width: auto; }
  .col-1-md {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-2-md {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-3-md {
    flex: 0 0 auto;
    width: 25%; }
  .col-4-md {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-5-md {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-6-md {
    flex: 0 0 auto;
    width: 50%; }
  .col-7-md {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-8-md {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-9-md {
    flex: 0 0 auto;
    width: 75%; }
  .col-10-md {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-11-md {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-12-md {
    flex: 0 0 auto;
    width: 100%; }
  .offset-0-md > * {
    margin-left: 0%; }
  .offset-1-md > * {
    margin-left: 8.33333%; }
  .offset-2-md > * {
    margin-left: 16.66667%; }
  .offset-3-md > * {
    margin-left: 25%; }
  .offset-4-md > * {
    margin-left: 33.33333%; }
  .offset-5-md > * {
    margin-left: 41.66667%; }
  .offset-6-md > * {
    margin-left: 50%; }
  .offset-7-md > * {
    margin-left: 58.33333%; }
  .offset-8-md > * {
    margin-left: 66.66667%; }
  .offset-9-md > * {
    margin-left: 75%; }
  .offset-10-md > * {
    margin-left: 83.33333%; }
  .offset-11-md > * {
    margin-left: 91.66667%; }
  .gx-0-md {
    --bs-gutter-x: 0; }
  .gy-0-md {
    --bs-gutter-y: 0; }
  .gx-1-md {
    --bs-gutter-x: 1px; }
  .gy-1-md {
    --bs-gutter-y: 1px; }
  .gx-2-md {
    --bs-gutter-x: 2px; }
  .gy-2-md {
    --bs-gutter-y: 2px; }
  .gx-10-md {
    --bs-gutter-x: 10px; }
  .gy-10-md {
    --bs-gutter-y: 10px; }
  .gx-12-md {
    --bs-gutter-x: 12px; }
  .gy-12-md {
    --bs-gutter-y: 12px; }
  .gx-16-md {
    --bs-gutter-x: 16px; }
  .gy-16-md {
    --bs-gutter-y: 16px; }
  .gx-20-md {
    --bs-gutter-x: 20px; }
  .gy-20-md {
    --bs-gutter-y: 20px; }
  .gx-22-md {
    --bs-gutter-x: 22px; }
  .gy-22-md {
    --bs-gutter-y: 22px; }
  .gx-24-md {
    --bs-gutter-x: 24px; }
  .gy-24-md {
    --bs-gutter-y: 24px; }
  .gx-30-md {
    --bs-gutter-x: 30px; }
  .gy-30-md {
    --bs-gutter-y: 30px; }
  .gx-32-md {
    --bs-gutter-x: 32px; }
  .gy-32-md {
    --bs-gutter-y: 32px; }
  .gx-36-md {
    --bs-gutter-x: 36px; }
  .gy-36-md {
    --bs-gutter-y: 36px; }
  .gx-40-md {
    --bs-gutter-x: 40px; }
  .gy-40-md {
    --bs-gutter-y: 40px; }
  .gx-42-md {
    --bs-gutter-x: 42px; }
  .gy-42-md {
    --bs-gutter-y: 42px; }
  .gx-46-md {
    --bs-gutter-x: 46px; }
  .gy-46-md {
    --bs-gutter-y: 46px; }
  .gx-50-md {
    --bs-gutter-x: 50px; }
  .gy-50-md {
    --bs-gutter-y: 50px; }
  .gx-56-md {
    --bs-gutter-x: 56px; }
  .gy-56-md {
    --bs-gutter-y: 56px; }
  .gx-60-md {
    --bs-gutter-x: 60px; }
  .gy-60-md {
    --bs-gutter-y: 60px; }
  .gx-64-md {
    --bs-gutter-x: 64px; }
  .gy-64-md {
    --bs-gutter-y: 64px; }
  .gx-75-md {
    --bs-gutter-x: 75px; }
  .gy-75-md {
    --bs-gutter-y: 75px; }
  .gx-100-md {
    --bs-gutter-x: 100px; }
  .gy-100-md {
    --bs-gutter-y: 100px; } }

@media (min-width: 1024px) {
  .col-auto-lg {
    flex: 0 0 auto;
    width: auto; }
  .col-1-lg {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-2-lg {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-3-lg {
    flex: 0 0 auto;
    width: 25%; }
  .col-4-lg {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-5-lg {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-6-lg {
    flex: 0 0 auto;
    width: 50%; }
  .col-7-lg {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-8-lg {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-9-lg {
    flex: 0 0 auto;
    width: 75%; }
  .col-10-lg {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-11-lg {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-12-lg {
    flex: 0 0 auto;
    width: 100%; }
  .offset-0-lg > * {
    margin-left: 0%; }
  .offset-1-lg > * {
    margin-left: 8.33333%; }
  .offset-2-lg > * {
    margin-left: 16.66667%; }
  .offset-3-lg > * {
    margin-left: 25%; }
  .offset-4-lg > * {
    margin-left: 33.33333%; }
  .offset-5-lg > * {
    margin-left: 41.66667%; }
  .offset-6-lg > * {
    margin-left: 50%; }
  .offset-7-lg > * {
    margin-left: 58.33333%; }
  .offset-8-lg > * {
    margin-left: 66.66667%; }
  .offset-9-lg > * {
    margin-left: 75%; }
  .offset-10-lg > * {
    margin-left: 83.33333%; }
  .offset-11-lg > * {
    margin-left: 91.66667%; }
  .gx-0-lg {
    --bs-gutter-x: 0; }
  .gy-0-lg {
    --bs-gutter-y: 0; }
  .gx-1-lg {
    --bs-gutter-x: 1px; }
  .gy-1-lg {
    --bs-gutter-y: 1px; }
  .gx-2-lg {
    --bs-gutter-x: 2px; }
  .gy-2-lg {
    --bs-gutter-y: 2px; }
  .gx-10-lg {
    --bs-gutter-x: 10px; }
  .gy-10-lg {
    --bs-gutter-y: 10px; }
  .gx-12-lg {
    --bs-gutter-x: 12px; }
  .gy-12-lg {
    --bs-gutter-y: 12px; }
  .gx-16-lg {
    --bs-gutter-x: 16px; }
  .gy-16-lg {
    --bs-gutter-y: 16px; }
  .gx-20-lg {
    --bs-gutter-x: 20px; }
  .gy-20-lg {
    --bs-gutter-y: 20px; }
  .gx-22-lg {
    --bs-gutter-x: 22px; }
  .gy-22-lg {
    --bs-gutter-y: 22px; }
  .gx-24-lg {
    --bs-gutter-x: 24px; }
  .gy-24-lg {
    --bs-gutter-y: 24px; }
  .gx-30-lg {
    --bs-gutter-x: 30px; }
  .gy-30-lg {
    --bs-gutter-y: 30px; }
  .gx-32-lg {
    --bs-gutter-x: 32px; }
  .gy-32-lg {
    --bs-gutter-y: 32px; }
  .gx-36-lg {
    --bs-gutter-x: 36px; }
  .gy-36-lg {
    --bs-gutter-y: 36px; }
  .gx-40-lg {
    --bs-gutter-x: 40px; }
  .gy-40-lg {
    --bs-gutter-y: 40px; }
  .gx-42-lg {
    --bs-gutter-x: 42px; }
  .gy-42-lg {
    --bs-gutter-y: 42px; }
  .gx-46-lg {
    --bs-gutter-x: 46px; }
  .gy-46-lg {
    --bs-gutter-y: 46px; }
  .gx-50-lg {
    --bs-gutter-x: 50px; }
  .gy-50-lg {
    --bs-gutter-y: 50px; }
  .gx-56-lg {
    --bs-gutter-x: 56px; }
  .gy-56-lg {
    --bs-gutter-y: 56px; }
  .gx-60-lg {
    --bs-gutter-x: 60px; }
  .gy-60-lg {
    --bs-gutter-y: 60px; }
  .gx-64-lg {
    --bs-gutter-x: 64px; }
  .gy-64-lg {
    --bs-gutter-y: 64px; }
  .gx-75-lg {
    --bs-gutter-x: 75px; }
  .gy-75-lg {
    --bs-gutter-y: 75px; }
  .gx-100-lg {
    --bs-gutter-x: 100px; }
  .gy-100-lg {
    --bs-gutter-y: 100px; } }

@media (min-width: 1280px) {
  .col-auto-xl {
    flex: 0 0 auto;
    width: auto; }
  .col-1-xl {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-2-xl {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-3-xl {
    flex: 0 0 auto;
    width: 25%; }
  .col-4-xl {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-5-xl {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-6-xl {
    flex: 0 0 auto;
    width: 50%; }
  .col-7-xl {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-8-xl {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-9-xl {
    flex: 0 0 auto;
    width: 75%; }
  .col-10-xl {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-11-xl {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-12-xl {
    flex: 0 0 auto;
    width: 100%; }
  .offset-0-xl > * {
    margin-left: 0%; }
  .offset-1-xl > * {
    margin-left: 8.33333%; }
  .offset-2-xl > * {
    margin-left: 16.66667%; }
  .offset-3-xl > * {
    margin-left: 25%; }
  .offset-4-xl > * {
    margin-left: 33.33333%; }
  .offset-5-xl > * {
    margin-left: 41.66667%; }
  .offset-6-xl > * {
    margin-left: 50%; }
  .offset-7-xl > * {
    margin-left: 58.33333%; }
  .offset-8-xl > * {
    margin-left: 66.66667%; }
  .offset-9-xl > * {
    margin-left: 75%; }
  .offset-10-xl > * {
    margin-left: 83.33333%; }
  .offset-11-xl > * {
    margin-left: 91.66667%; }
  .gx-0-xl {
    --bs-gutter-x: 0; }
  .gy-0-xl {
    --bs-gutter-y: 0; }
  .gx-1-xl {
    --bs-gutter-x: 1px; }
  .gy-1-xl {
    --bs-gutter-y: 1px; }
  .gx-2-xl {
    --bs-gutter-x: 2px; }
  .gy-2-xl {
    --bs-gutter-y: 2px; }
  .gx-10-xl {
    --bs-gutter-x: 10px; }
  .gy-10-xl {
    --bs-gutter-y: 10px; }
  .gx-12-xl {
    --bs-gutter-x: 12px; }
  .gy-12-xl {
    --bs-gutter-y: 12px; }
  .gx-16-xl {
    --bs-gutter-x: 16px; }
  .gy-16-xl {
    --bs-gutter-y: 16px; }
  .gx-20-xl {
    --bs-gutter-x: 20px; }
  .gy-20-xl {
    --bs-gutter-y: 20px; }
  .gx-22-xl {
    --bs-gutter-x: 22px; }
  .gy-22-xl {
    --bs-gutter-y: 22px; }
  .gx-24-xl {
    --bs-gutter-x: 24px; }
  .gy-24-xl {
    --bs-gutter-y: 24px; }
  .gx-30-xl {
    --bs-gutter-x: 30px; }
  .gy-30-xl {
    --bs-gutter-y: 30px; }
  .gx-32-xl {
    --bs-gutter-x: 32px; }
  .gy-32-xl {
    --bs-gutter-y: 32px; }
  .gx-36-xl {
    --bs-gutter-x: 36px; }
  .gy-36-xl {
    --bs-gutter-y: 36px; }
  .gx-40-xl {
    --bs-gutter-x: 40px; }
  .gy-40-xl {
    --bs-gutter-y: 40px; }
  .gx-42-xl {
    --bs-gutter-x: 42px; }
  .gy-42-xl {
    --bs-gutter-y: 42px; }
  .gx-46-xl {
    --bs-gutter-x: 46px; }
  .gy-46-xl {
    --bs-gutter-y: 46px; }
  .gx-50-xl {
    --bs-gutter-x: 50px; }
  .gy-50-xl {
    --bs-gutter-y: 50px; }
  .gx-56-xl {
    --bs-gutter-x: 56px; }
  .gy-56-xl {
    --bs-gutter-y: 56px; }
  .gx-60-xl {
    --bs-gutter-x: 60px; }
  .gy-60-xl {
    --bs-gutter-y: 60px; }
  .gx-64-xl {
    --bs-gutter-x: 64px; }
  .gy-64-xl {
    --bs-gutter-y: 64px; }
  .gx-75-xl {
    --bs-gutter-x: 75px; }
  .gy-75-xl {
    --bs-gutter-y: 75px; }
  .gx-100-xl {
    --bs-gutter-x: 100px; }
  .gy-100-xl {
    --bs-gutter-y: 100px; } }

@media (min-width: 1440px) {
  .col-auto-yl {
    flex: 0 0 auto;
    width: auto; }
  .col-1-yl {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-2-yl {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-3-yl {
    flex: 0 0 auto;
    width: 25%; }
  .col-4-yl {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-5-yl {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-6-yl {
    flex: 0 0 auto;
    width: 50%; }
  .col-7-yl {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-8-yl {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-9-yl {
    flex: 0 0 auto;
    width: 75%; }
  .col-10-yl {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-11-yl {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-12-yl {
    flex: 0 0 auto;
    width: 100%; }
  .offset-0-yl > * {
    margin-left: 0%; }
  .offset-1-yl > * {
    margin-left: 8.33333%; }
  .offset-2-yl > * {
    margin-left: 16.66667%; }
  .offset-3-yl > * {
    margin-left: 25%; }
  .offset-4-yl > * {
    margin-left: 33.33333%; }
  .offset-5-yl > * {
    margin-left: 41.66667%; }
  .offset-6-yl > * {
    margin-left: 50%; }
  .offset-7-yl > * {
    margin-left: 58.33333%; }
  .offset-8-yl > * {
    margin-left: 66.66667%; }
  .offset-9-yl > * {
    margin-left: 75%; }
  .offset-10-yl > * {
    margin-left: 83.33333%; }
  .offset-11-yl > * {
    margin-left: 91.66667%; }
  .gx-0-yl {
    --bs-gutter-x: 0; }
  .gy-0-yl {
    --bs-gutter-y: 0; }
  .gx-1-yl {
    --bs-gutter-x: 1px; }
  .gy-1-yl {
    --bs-gutter-y: 1px; }
  .gx-2-yl {
    --bs-gutter-x: 2px; }
  .gy-2-yl {
    --bs-gutter-y: 2px; }
  .gx-10-yl {
    --bs-gutter-x: 10px; }
  .gy-10-yl {
    --bs-gutter-y: 10px; }
  .gx-12-yl {
    --bs-gutter-x: 12px; }
  .gy-12-yl {
    --bs-gutter-y: 12px; }
  .gx-16-yl {
    --bs-gutter-x: 16px; }
  .gy-16-yl {
    --bs-gutter-y: 16px; }
  .gx-20-yl {
    --bs-gutter-x: 20px; }
  .gy-20-yl {
    --bs-gutter-y: 20px; }
  .gx-22-yl {
    --bs-gutter-x: 22px; }
  .gy-22-yl {
    --bs-gutter-y: 22px; }
  .gx-24-yl {
    --bs-gutter-x: 24px; }
  .gy-24-yl {
    --bs-gutter-y: 24px; }
  .gx-30-yl {
    --bs-gutter-x: 30px; }
  .gy-30-yl {
    --bs-gutter-y: 30px; }
  .gx-32-yl {
    --bs-gutter-x: 32px; }
  .gy-32-yl {
    --bs-gutter-y: 32px; }
  .gx-36-yl {
    --bs-gutter-x: 36px; }
  .gy-36-yl {
    --bs-gutter-y: 36px; }
  .gx-40-yl {
    --bs-gutter-x: 40px; }
  .gy-40-yl {
    --bs-gutter-y: 40px; }
  .gx-42-yl {
    --bs-gutter-x: 42px; }
  .gy-42-yl {
    --bs-gutter-y: 42px; }
  .gx-46-yl {
    --bs-gutter-x: 46px; }
  .gy-46-yl {
    --bs-gutter-y: 46px; }
  .gx-50-yl {
    --bs-gutter-x: 50px; }
  .gy-50-yl {
    --bs-gutter-y: 50px; }
  .gx-56-yl {
    --bs-gutter-x: 56px; }
  .gy-56-yl {
    --bs-gutter-y: 56px; }
  .gx-60-yl {
    --bs-gutter-x: 60px; }
  .gy-60-yl {
    --bs-gutter-y: 60px; }
  .gx-64-yl {
    --bs-gutter-x: 64px; }
  .gy-64-yl {
    --bs-gutter-y: 64px; }
  .gx-75-yl {
    --bs-gutter-x: 75px; }
  .gy-75-yl {
    --bs-gutter-y: 75px; }
  .gx-100-yl {
    --bs-gutter-x: 100px; }
  .gy-100-yl {
    --bs-gutter-y: 100px; } }

/*# sourceMappingURL=bootstrap-grid.css.map */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

:root {
  --cl-w: #FFF;
  --cl-b: #000;
  --cl-blue: #1E4487;
  --cl-blue-dark: #0D004C;
  --cl-red: #EC2224;
  --cl-title: #000;
  --cl-title-light: #FFF;
  --cl-content: #343434;
  --cl-content-light: #FFF;
  --cl-readmore: #000;
  --cl-readmore-light: #FFF;
  --cl-caption: #343434;
  --wContainer: 96vw;
  --wContainer2: 100%;
  --wContainer3: 100%;
  --hHeader: 97px; }
  @media (min-width: 420px) {
    :root {
      --wContainer: ; } }
  @media (min-width: 576px) {
    :root {
      --wContainer: ; } }
  @media (min-width: 768px) {
    :root {
      --wContainer: ; } }
  @media (min-width: 1024px) {
    :root {
      --wContainer: ; } }
  @media (min-width: 1280px) {
    :root {
      --wContainer: 1224px; } }
  @media (min-width: 1440px) {
    :root {
      --wContainer: 1311.08px; } }
  @media screen and (min-width: 768px) {
    :root {
      --wContainer2: 92%; } }
  @media screen and (min-width: 1024px) {
    :root {
      --wContainer2: 87.8%; } }
  @media screen and (min-width: 768px) {
    :root {
      --wContainer3: 92%; } }
  @media screen and (min-width: 1024px) {
    :root {
      --wContainer3: 82.6%; } }
  @media screen and (min-width: 768px) {
    :root {
      --hHeader: 146px; } }

html, body {
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased; }

body {
  color: #000;
  background-color: var(--cl-w);
  font-weight: 400;
  font-family: "League Spartan", sans-serif;
  font-size: 16px;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: auto; }
  body.hideScroll {
    max-height: 100vh;
    overflow: hidden; }

main {
  position: relative;
  height: auto;
  width: 100%; }

main,
#banner,
#bannerSub,
#breadcrumb {
  margin-left: auto;
  margin-right: auto; }

ol, ul {
  padding-left: 0rem; }

ul.rmd {
  list-style-type: none;
  list-style-position: inside;
  margin: 0;
  padding: 0; }

a {
  color: currentColor; }

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

a[data-fancybox] img {
  cursor: zoom-in; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none; }

.hidden {
  display: none; }

address {
  margin-bottom: 0;
  font-style: normal;
  line-height: inherit; }

b,
strong {
  font-weight: bolder;
  font-weight: 700; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

a {
  text-decoration: none;
  background-color: transparent; }

pre,
code,
kbd,
samp {
  font-family: inherit;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 0;
  overflow: auto; }

figure {
  margin: 0; }

img {
  border-style: none;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center; }
  img.svg {
    width: auto; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0;
  padding-bottom: 0;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0; }

button {
  border-radius: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

*:focus,
*:active {
  outline: none; }

.title-h1 {
  text-align: center;
  margin-bottom: 40px; }
  @media screen and (min-width: 768px) {
    .title-h1 {
      margin-bottom: 48px; } }
  @media screen and (min-width: 1024px) {
    .title-h1 {
      margin-bottom: 60px; } }
  .title-h1 > * {
    font-size: 40px;
    font-weight: 500;
    line-height: 1.33333;
    color: var(--cl-title, #000); }
    @media screen and (min-width: 768px) {
      .title-h1 > * {
        font-size: 48px; } }
    @media screen and (min-width: 1024px) {
      .title-h1 > * {
        font-size: 60px; } }

.title-h2 {
  text-align: center;
  margin-bottom: 40px; }
  @media screen and (min-width: 768px) {
    .title-h2 {
      margin-bottom: 48px; } }
  @media screen and (min-width: 1024px) {
    .title-h2 {
      margin-bottom: 60px; } }
  .title-h2 > * {
    font-size: 28px;
    font-weight: 600;
    line-height: 1.33333;
    color: var(--cl-title, #000); }
    @media screen and (min-width: 768px) {
      .title-h2 > * {
        font-size: 32px; } }
    @media screen and (min-width: 1024px) {
      .title-h2 > * {
        font-size: 36px; } }

.readmore {
  --cl-hover: var(--cl-red, #000); }
  .readmore a {
    display: flex;
    align-items: center; }
    .readmore a:hover {
      color: var(--cl-hover, #000); }
      .readmore a:hover svg {
        transform: rotate(-90deg); }
  .readmore svg {
    transition: .2s transform linear;
    margin-left: 17px;
    margin-top: -2px; }
    .readmore svg [stroke]:not([stroke="none"]) {
      stroke: currentColor; }
    .readmore svg [fill]:not([fill="none"]) {
      fill: currentColor; }

.btn-option {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--cl-w, #fff);
  color: var(--cl-blue, #000);
  position: relative;
  width: 100%;
  min-width: 280px;
  padding: 18px 20px 13px 20px;
  height: 80px;
  white-space: nowrap;
  box-shadow: -5px 6px 20px 0 rgba(0, 0, 0, 0.15);
  font-size: 20px;
  line-height: 1.666667;
  font-weight: 700;
  text-transform: uppercase; }
  @media screen and (min-width: 1024px) {
    .btn-option {
      min-width: 332px; } }
  @media screen and (min-width: 768px) {
    .btn-option {
      font-size: 22px; } }
  @media screen and (min-width: 1024px) {
    .btn-option {
      font-size: 24px; } }
  .btn-option .icon {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 10px;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s transform linear; }
    .btn-option .icon img, .btn-option .icon svg {
      width: 100%;
      height: 100%;
      object-fit: contain; }
    .btn-option .icon [stroke]:not([stroke="none"]) {
      stroke: currentColor; }
    .btn-option .icon [fill]:not([fill="none"]) {
      fill: currentColor; }
  .btn-option.is-actived, .about-mv-options li.is-actived > a.btn-option {
    background-color: var(--cl-blue);
    color: var(--cl-content-light, #FFF); }
    .btn-option.is-actived .icon, .about-mv-options li.is-actived > a.btn-option .icon {
      transform: rotate(-90deg); }

.pages-detail {
  margin-top: 40px;
  margin-bottom: 54px; }
  @media screen and (min-width: 768px) {
    .pages-detail {
      margin-top: 48px; } }
  @media screen and (min-width: 1024px) {
    .pages-detail {
      margin-top: 60px; } }
  @media screen and (min-width: 768px) {
    .pages-detail {
      margin-bottom: 72px; } }
  @media screen and (min-width: 1024px) {
    .pages-detail {
      margin-bottom: 100px; } }
  .pages-detail-wrapper {
    padding-top: 48px;
    border-top: 1px solid #000; }
    @media screen and (min-width: 768px) {
      .pages-detail-wrapper {
        padding-top: 64px; } }
    @media screen and (min-width: 1024px) {
      .pages-detail-wrapper {
        padding-top: 80px; } }
    .pages-detail-wrapper .content > h2 {
      font-size: 24px;
      font-weight: 600;
      color: var(--cl-title, #000);
      line-height: 1.2;
      margin-bottom: 40px; }
      @media screen and (min-width: 768px) {
        .pages-detail-wrapper .content > h2 {
          font-size: 28px; } }
      @media screen and (min-width: 1024px) {
        .pages-detail-wrapper .content > h2 {
          font-size: 32px; } }
      @media screen and (min-width: 768px) {
        .pages-detail-wrapper .content > h2 {
          margin-bottom: 48px; } }
      @media screen and (min-width: 1024px) {
        .pages-detail-wrapper .content > h2 {
          margin-bottom: 60px; } }
    .pages-detail-wrapper .content-footer {
      margin-top: 28px; }
      @media screen and (min-width: 768px) {
        .pages-detail-wrapper .content-footer {
          margin-top: 32px; } }
      @media screen and (min-width: 1024px) {
        .pages-detail-wrapper .content-footer {
          margin-top: 40px; } }

.pages-related > * {
  font-size: 24px;
  font-weight: 600;
  color: var(--cl-title, #000);
  line-height: 1.2;
  margin-bottom: 40px; }
  @media screen and (min-width: 768px) {
    .pages-related > * {
      font-size: 28px; } }
  @media screen and (min-width: 1024px) {
    .pages-related > * {
      font-size: 32px; } }
  @media screen and (min-width: 768px) {
    .pages-related > * {
      margin-bottom: 48px; } }
  @media screen and (min-width: 1024px) {
    .pages-related > * {
      margin-bottom: 60px; } }

.pages-related ul {
  list-style-type: none; }

.pages-sidebar ul {
  list-style-type: none; }
  .pages-sidebar ul li {
    border-top: 1px solid var(--cl-blue, #000);
    border-left: 1px solid var(--cl-blue, #000);
    border-right: 1px solid var(--cl-blue, #000); }
  .pages-sidebar ul li:last-child {
    border-bottom: 1px solid var(--cl-blue, #000); }

.pages-sidebar .sidebar-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #FFF;
  color: var(--cl-blue, #000); }
  .pages-sidebar .sidebar-item__icon {
    width: 60px;
    height: 60px;
    flex: 0 0 60px;
    background-color: var(--cl-blue, #000);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    overflow: hidden;
    transition: .3s all linear; }
    .pages-sidebar .sidebar-item__icon img, .pages-sidebar .sidebar-item__icon svg {
      width: auto;
      max-width: 64%;
      max-height: 80%;
      object-fit: contain;
      color: var(--cl-title-light, #FFF); }
      .pages-sidebar .sidebar-item__icon img [stroke]:not([stroke="none"]), .pages-sidebar .sidebar-item__icon svg [stroke]:not([stroke="none"]) {
        stroke: currentColor; }
      .pages-sidebar .sidebar-item__icon img [fill]:not([fill="none"]), .pages-sidebar .sidebar-item__icon svg [fill]:not([fill="none"]) {
        fill: currentColor; }
  .pages-sidebar .sidebar-item__name {
    flex: 1;
    margin-left: 8px;
    text-align: left; }
    .pages-sidebar .sidebar-item__name > * {
      font-size: 20px;
      line-height: 1.66667;
      text-transform: uppercase;
      color: var(--cl-blue, #000);
      font-weight: 700;
      transition: .3s all linear; }
      @media screen and (min-width: 768px) {
        .pages-sidebar .sidebar-item__name > * {
          font-size: 22px; } }
      @media screen and (min-width: 1024px) {
        .pages-sidebar .sidebar-item__name > * {
          font-size: 24px; } }
  .pages-sidebar .sidebar-item__arrow {
    margin-left: 8px;
    transition: .3s all linear; }
    .pages-sidebar .sidebar-item__arrow [stroke]:not([stroke="none"]) {
      stroke: currentColor; }
    .pages-sidebar .sidebar-item__arrow [fill]:not([fill="none"]) {
      fill: currentColor; }
  .pages-sidebar .sidebar-item:hover {
    background-color: var(--cl-blue, #000);
    color: var(--cl-w, #FFF); }
    .pages-sidebar .sidebar-item:hover .sidebar-item__icon {
      background-color: var(--cl-w, #FFF); }
      .pages-sidebar .sidebar-item:hover .sidebar-item__icon img, .pages-sidebar .sidebar-item:hover .sidebar-item__icon svg {
        color: var(--cl-blue, #000); }
    .pages-sidebar .sidebar-item:hover .sidebar-item__name > * {
      color: var(--cl-w, #FFF); }
    .pages-sidebar .sidebar-item:hover .sidebar-item__arrow {
      transform: rotate(90deg); }

@keyframes appearBox {
  from {
    transform: translateY(100px);
    opacity: 0; }
  to {
    opacity: 1;
    transform: translateY(0); } }

@keyframes dash {
  to {
    stroke-dashoffset: 0; } }

@keyframes opacity {
  0% {
    opacity: 0; }
  10% {
    opacity: 0.1; }
  20% {
    opacity: 0.2; }
  30% {
    opacity: 0.3; }
  40% {
    opacity: 0.4; }
  50% {
    opacity: 0.5; }
  60% {
    opacity: 0.6; }
  70% {
    opacity: 0.7; }
  80% {
    opacity: 0.8; }
  90% {
    opacity: 0.9; }
  100% {
    opacity: 1; } }

@keyframes bounce {
  0% {
    transform: translateY(0); }
  10% {
    transform: translateY(2px); }
  20% {
    transform: translateY(4px); }
  30% {
    transform: translateY(6px); }
  40% {
    transform: translateY(8px); }
  50% {
    transform: translateY(10px); }
  60% {
    transform: translateY(12px); }
  70% {
    transform: translateY(14px); }
  80% {
    transform: translateY(16px); }
  90% {
    transform: translateY(18px); }
  100% {
    transform: translateY(20px); } }

@keyframes circleRotate {
  0% {
    transform: rotateY(0deg); }
  100% {
    transform: rotateY(360deg); } }

@keyframes ring {
  0% {
    transform: rotate(0) scale(1) skew(1deg); }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  50% {
    transform: rotate(0) scale(1) skew(1deg); }
  100% {
    transform: rotate(0) scale(1) skew(1deg); } }

@keyframes btnEffect1 {
  0% {
    border-top-color: #8BC53F;
    border-right-color: var(--btn-cl, #FFF);
    border-bottom-color: var(--btn-cl, #FFF);
    border-left-color: #8BC53F;
    transform: translate(0, 0); }
  70% {
    border-top-color: #8BC53F;
    border-right-color: var(--btn-cl, #FFF);
    border-bottom-color: var(--btn-cl, #FFF);
    border-left-color: #8BC53F;
    transform: translate(100%, 100%); }
  100% {
    border-top-color: #0D004C;
    border-right-color: var(--btn-cl, #FFF);
    border-bottom-color: var(--btn-cl, #FFF);
    border-left-color: #0D004C;
    transform: translate(0, 0); } }

@keyframes btnEffect1_reverse {
  0% {
    border-top-color: #0D004C;
    border-right-color: #FFF;
    border-bottom-color: #FFF;
    border-left-color: #0D004C;
    transform: translate(0, 0); }
  70% {
    border-top-color: #0D004C;
    border-right-color: #FFF;
    border-bottom-color: #FFF;
    border-left-color: #0D004C;
    transform: translate(100%, 100%); }
  100% {
    border-top-color: #8BC53F;
    border-right-color: #FFF;
    border-bottom-color: #FFF;
    border-left-color: #8BC53F;
    transform: translate(0, 0); } }

@keyframes opacityPrompt {
  0% {
    opacity: 0; }
  10% {
    opacity: 0.1; }
  20% {
    opacity: 0.2; }
  30% {
    opacity: 0.3; }
  40% {
    opacity: 0.4; }
  50% {
    opacity: 0.5; }
  60% {
    opacity: 0.6; }
  70% {
    opacity: 0.7; }
  80% {
    opacity: 0.8; }
  90% {
    opacity: 0.9; }
  100% {
    opacity: 1; } }

@keyframes bouncePrompt {
  0% {
    transform: translateY(0); }
  10% {
    transform: translateY(3px); }
  20% {
    transform: translateY(6px); }
  30% {
    transform: translateY(9px); }
  40% {
    transform: translateY(12px); }
  50% {
    transform: translateY(15px); }
  60% {
    transform: translateY(18px); }
  70% {
    transform: translateY(21px); }
  80% {
    transform: translateY(24px); }
  90% {
    transform: translateY(27px); }
  100% {
    transform: translateY(30px); } }

.f-jc-c {
  justify-content: center; }

.f-jc-between {
  justify-content: space-between; }

.text-main, .txt-main, .cl-main {
  color: var(--cl-blue, #000); }

.text-blue, .txt-blue, .cl-blue, .blue {
  color: var(--cl-blue, #000); }

.text-red, .txt-red, .cl-red, .red {
  color: var(--cl-red, #000); }

.text-main-imp, .txt-main-imp, .cl-main-imp {
  color: var(--cl-blue, #000) !important; }

.text-blue-imp, .txt-blue-imp, .cl-blue-imp, .blue-imp {
  color: var(--cl-blue, #000) !important; }

.text-red-imp, .txt-red-imp, .cl-red-imp, .red-imp {
  color: var(--cl-red, #000) !important; }

.clear-fix {
  clear: both; }

.ov-hide {
  overflow: hidden; }

.m-auto {
  margin-left: auto;
  margin-right: auto; }

.ml-auto {
  margin-left: auto; }

.mr-auto {
  margin-right: auto; }

.mt-auto {
  margin-top: auto; }

.thin {
  font-weight: 100 !important; }

.extra-light {
  font-weight: 200 !important; }

.light {
  font-weight: 300 !important; }

.regular {
  font-weight: 400 !important; }

.normal {
  font-weight: 400 !important; }

.medium {
  font-weight: 500 !important; }

.semi-bold {
  font-weight: 600 !important; }

.bold {
  font-weight: 700 !important; }

.extra-bold {
  font-weight: 800 !important; }

.ultra-bold {
  font-weight: 900; }

.m-c {
  margin-left: auto;
  margin-right: auto; }

.df-cc {
  display: flex;
  justify-content: center;
  align-items: center; }

.tc {
  text-align: center; }

.tl {
  text-align: left; }

.tr {
  text-align: right; }

.uppercase {
  text-transform: uppercase !important; }

.bold {
  font-weight: bold !important; }

.italic {
  font-style: italic !important; }

.d-none {
  display: none !important; }

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.scrollgsap {
  position: relative;
  transition: top .5s ease,opacity .1s ease;
  top: 150px;
  opacity: 0; }
  .scrollgsap.gsap-loaded {
    top: 0;
    opacity: 1; }

.f-align-center {
  align-items: center; }

.obj-contain {
  object-fit: contain; }

.obj-cover {
  object-fit: cover; }

.toast-msg {
  position: fixed;
  bottom: 80px;
  right: 20px;
  z-index: 11111;
  list-style-type: none; }
  @media screen and (min-width: 1024px) {
    .toast-msg {
      bottom: 20px; } }
  .toast-msg li {
    display: block;
    background-color: #333;
    transform: translateX(calc(100% + 20px));
    transition: 0.6s all cubic-bezier(0.175, 0.885, 0.32, 1.275);
    cursor: pointer; }
    .toast-msg li.success {
      background-color: #40f467; }
    .toast-msg li.error {
      background-color: #D81A1A; }
    .toast-msg li > p {
      color: #fff;
      font-weight: 500;
      font-size: 15px;
      padding: 6px 8px; }
      @media screen and (min-width: 768px) {
        .toast-msg li > p {
          font-size: 16px; } }
      @media screen and (min-width: 1024px) {
        .toast-msg li > p {
          font-size: 16px; } }
      @media screen and (min-width: 1024px) {
        .toast-msg li > p {
          padding: 8px 12px; } }
    .toast-msg li.active {
      transform: translateX(0); }
    .toast-msg li > * {
      user-select: none;
      pointer-events: none; }
  .toast-msg li + li {
    margin-top: 10px; }

.writeux {
  font-family: "League Spartan", sans-serif !important;
  color: currentColor;
  font-size: 16px;
  line-height: 1.388888889;
  color: var(--cl-content, #000); }
  @media screen and (min-width: 768px) {
    .writeux {
      font-size: 18px; } }
  @media screen and (min-width: 1024px) {
    .writeux {
      font-size: 18px; } }
  .writeux.justify {
    text-align: justify; }
  .writeux .float-left {
    float: left; }
  .writeux .float-right {
    float: right;
    width: 120px; }
  .writeux .clear-fix {
    clear: both; }
  .writeux h2 {
    font-size: 24px;
    font-weight: 600;
    color: var(--cl-title, #000);
    line-height: 1.2; }
    @media screen and (min-width: 768px) {
      .writeux h2 {
        font-size: 28px; } }
    @media screen and (min-width: 1024px) {
      .writeux h2 {
        font-size: 32px; } }
  .writeux h3 {
    font-size: 18px;
    font-weight: 500;
    color: var(--cl-title, #000);
    line-height: 1.333334; }
    @media screen and (min-width: 768px) {
      .writeux h3 {
        font-size: 20px; } }
    @media screen and (min-width: 1024px) {
      .writeux h3 {
        font-size: 20px; } }
  .writeux h4 {
    font-size: 16px;
    font-weight: 500;
    color: var(--cl-title, #000);
    line-height: 1.3333333; }
    @media screen and (min-width: 768px) {
      .writeux h4 {
        font-size: 18px; } }
    @media screen and (min-width: 1024px) {
      .writeux h4 {
        font-size: 18px; } }
  .writeux div[class|="col"] {
    display: flex;
    flex-direction: column; }
    .writeux div[class|="col"] a[data-fancybox] {
      display: block;
      flex: 1; }
      .writeux div[class|="col"] a[data-fancybox] img {
        width: 100%;
        height: 100%; }
    .writeux div[class|="col"] img {
      display: block;
      flex: 1; }
      .writeux div[class|="col"] img.f-auto {
        flex: 0 0 auto; }
      .writeux div[class|="col"] img.full-w {
        width: 100%; }
  .writeux .red, .writeux .cl-red {
    color: var(--cl-red, #000); }
  .writeux .cl-main, .writeux .blue, .writeux .cl-blue {
    color: var(--cl-blue, #000); }
  .writeux p {
    line-height: 156%; }
    .writeux p.inline-block {
      display: inline-block; }
    .writeux p.inline {
      display: inline; }
  .writeux br.cp {
    display: none; }
    @media screen and (min-width: 1024px) {
      .writeux br.cp {
        display: block; } }
  .writeux br.tb {
    display: none; }
    @media screen and (min-width: 768px) {
      .writeux br.tb {
        display: block; } }
  .writeux br.mb {
    display: none; }
    @media screen and (min-width: 768px) {
      .writeux br.mb {
        display: block; } }
  .writeux span.block {
    display: block; }
  .writeux span.inline-block {
    display: inline-block; }
  .writeux ul, .writeux ol {
    padding-left: 1.4rem; }
    .writeux ul.style-none, .writeux ol.style-none {
      list-style-type: none;
      padding-left: 0; }
    .writeux ul.tab-left, .writeux ol.tab-left {
      margin-left: 0; }
      @media screen and (min-width: 768px) {
        .writeux ul.tab-left, .writeux ol.tab-left {
          margin-left: 2rem; } }
  .writeux img {
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    width: auto; }
    @media screen and (max-width: 767px) {
      .writeux img {
        width: 100% !important; } }
    .writeux img.obj-cover {
      width: 100%; }
    .writeux img.icon {
      width: auto !important; }
  .writeux a {
    display: inline-block;
    color: -webkit-link; }
    .writeux a:hover {
      color: var(--cl-blue, #000); }
  .writeux a[data-fancybox] {
    display: inline-block; }
  .writeux figure figcaption {
    font-size: 14px;
    font-style: italic;
    color: var(--cl-caption);
    text-align: center;
    font-style: italic; }
  .writeux .l3image {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-items: stretch;
    position: relative;
    width: 100%; }
    .writeux .l3image li:nth-of-type(1) {
      flex: 0 0 69.5%; }
    .writeux .l3image li:nth-of-type(2) {
      position: absolute;
      height: calc(50% - 5px);
      top: 0;
      left: calc(69.5% + 10px);
      width: calc(30.5% - 10px); }
    .writeux .l3image li:nth-of-type(3) {
      position: absolute;
      top: calc(50% + 5px);
      height: calc(50% - 5px);
      left: calc(69.5% + 10px);
      width: calc(30.5% - 10px); }
    .writeux .l3image li > img {
      display: block;
      height: 100%; }
  .writeux p.caption {
    font-size: 14px;
    line-height: 2;
    font-style: italic;
    color: var(--cl_gray);
    text-align: center;
    font-style: italic; }
  .writeux table {
    width: 100%; }
    .writeux table:not(:first-child) {
      margin-top: 8px; }
    .writeux table thead {
      transition: .16s all ease-in-out; }
    .writeux table thead > tr > th {
      font-size: 16px;
      color: var(--cl-blue, #000);
      font-weight: 500;
      padding-top: 14px;
      padding-bottom: 14px;
      min-width: 20%; }
      @media screen and (min-width: 768px) {
        .writeux table thead > tr > th {
          font-size: 18px; } }
      @media screen and (min-width: 1024px) {
        .writeux table thead > tr > th {
          font-size: 18px; } }
      .writeux table thead > tr > th:first-child {
        text-align: left;
        padding-left: 16px; }
      .writeux table thead > tr > th:last-child {
        text-align: right;
        padding-right: 16px; }
      .writeux table thead > tr > th:not(:first-child):not(:last-child) {
        text-align: center;
        padding-left: 8px;
        padding-right: 8px; }
    .writeux table tbody > tr > td {
      font-size: 16px;
      color: var(--cl-content);
      white-space: nowrap;
      padding: 14px 0; }
      @media screen and (min-width: 768px) {
        .writeux table tbody > tr > td {
          font-size: 18px; } }
      @media screen and (min-width: 1024px) {
        .writeux table tbody > tr > td {
          font-size: 18px; } }
      .writeux table tbody > tr > td:first-child {
        text-align: left;
        color: var(--cl-b);
        padding-left: 16px; }
      .writeux table tbody > tr > td:last-child {
        text-align: right;
        padding-right: 16px; }
      .writeux table tbody > tr > td:not(:first-child):not(:last-child) {
        text-align: center;
        padding-left: 8px;
        padding-right: 8px; }
    @media screen and (max-width: 1023px) {
      .writeux table thead, .writeux table thead > tr, .writeux table thead > tr > td {
        display: none; }
      .writeux table tbody, .writeux table tbody > tr {
        display: flex;
        flex-direction: column;
        text-align: left !important; }
      .writeux table tbody > tr > td {
        display: table-cell;
        text-align: left !important; }
      .writeux table tbody > tr > td {
        position: relative;
        display: flex;
        align-items: center;
        white-space: normal;
        padding: 8px 16px !important; }
        .writeux table tbody > tr > td::before {
          content: attr(data-title);
          width: 50%;
          flex: 0 0 50%;
          align-self: stretch;
          display: inline-flex;
          align-items: center;
          font-size: 16px;
          color: var(--cl-blue, #000);
          font-weight: 500;
          margin-right: 8px; } }
    @media screen and (max-width: 1023px) and (min-width: 768px) {
      .writeux table tbody > tr > td::before {
        font-size: 18px; } }
    @media screen and (max-width: 1023px) and (min-width: 1024px) {
      .writeux table tbody > tr > td::before {
        font-size: 18px; } }
    @media screen and (max-width: 1023px) {
        .writeux table tbody > tr > td:not(:first-child) {
          border-top: 1px solid #E1E1E1; }
      .writeux table tbody > tr {
        border-left: 1px solid var(--cl-blue);
        border-right: 1px solid var(--cl-blue); }
        .writeux table tbody > tr:first-child {
          border-top: 1px solid var(--cl-blue);
          border-top-right-radius: 10px;
          border-top-left-radius: 10px; }
        .writeux table tbody > tr:not(:first-child) {
          border-top: 1px solid var(--cl-blue); }
        .writeux table tbody > tr:last-child {
          border-bottom: 1px solid var(--cl-blue);
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px; } }
  .writeux .table {
    display: block;
    max-width: 100%;
    overflow-x: auto;
    /* width */
    /* Track */
    /* Handle */
    /* Handle on hover */ }
    .writeux .table::-webkit-scrollbar {
      width: 4px;
      height: 4px; }
    .writeux .table::-webkit-scrollbar-track {
      background: #f1f1f1; }
    .writeux .table::-webkit-scrollbar-thumb {
      background: #888; }
    .writeux .table::-webkit-scrollbar-thumb:hover {
      background: var(--cl-blue, #555); }
    .writeux .table.table-column {
      --borderOuter: #E1E1E1; }
      .writeux .table.table-column thead, .writeux .table.table-column thead > tr, .writeux .table.table-column thead > tr > td {
        display: none; }
      .writeux .table.table-column tbody, .writeux .table.table-column tbody > tr, .writeux .table.table-column tbody > tr > td {
        display: block;
        text-align: left !important; }
      .writeux .table.table-column tbody > tr > td {
        position: relative;
        display: flex;
        align-items: center;
        white-space: normal;
        padding: 8px 16px !important; }
        .writeux .table.table-column tbody > tr > td::before {
          content: attr(data-title);
          display: inline-block;
          width: 50%;
          flex: 0 0 50%;
          align-self: stretch;
          display: inline-flex;
          align-items: center;
          font-size: 16px;
          color: var(--cl-blue, #000);
          font-weight: 500;
          margin-right: 8px; }
          @media screen and (min-width: 768px) {
            .writeux .table.table-column tbody > tr > td::before {
              font-size: 18px; } }
          @media screen and (min-width: 1024px) {
            .writeux .table.table-column tbody > tr > td::before {
              font-size: 18px; } }
        .writeux .table.table-column tbody > tr > td:not(:first-child) {
          border-top: 1px solid #E1E1E1; }
      .writeux .table.table-column tbody > tr {
        border-left: 1px solid var(--borderOuter);
        border-right: 1px solid var(--borderOuter); }
        .writeux .table.table-column tbody > tr:first-child {
          border-top: 1px solid var(--borderOuter);
          border-top-right-radius: 10px;
          border-top-left-radius: 10px; }
        .writeux .table.table-column tbody > tr:not(:first-child) {
          border-top: 1px solid var(--borderOuter); }
        .writeux .table.table-column tbody > tr:last-child {
          border-bottom: 1px solid var(--borderOuter);
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px; }
      .writeux .table.table-column tbody > tr:first-child td:first-child {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px; }
        .writeux .table.table-column tbody > tr:first-child td:first-child::before {
          border-top-left-radius: 10px; }
      .writeux .table.table-column tbody > tr:last-child td:last-child {
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px; }
        .writeux .table.table-column tbody > tr:last-child td:last-child::before {
          border-bottom-left-radius: 10px; }
      .writeux .table.table-column tbody > tr > td {
        display: flex;
        align-items: center;
        padding: 0px !important;
        color: var(--cl-title, #000); }
        .writeux .table.table-column tbody > tr > td::before {
          padding: 8px 8px 8px 16px !important;
          width: 50%;
          flex: 0 0 50%; }
          @media screen and (min-width: 768px) {
            .writeux .table.table-column tbody > tr > td::before {
              width: 24%;
              flex: 0 0 24%; } }
        .writeux .table.table-column tbody > tr > td:nth-of-type(2n + 1)::before {
          background: #D7E5FF; }
        .writeux .table.table-column tbody > tr > td:nth-of-type(2n)::before {
          background: #E8F0FF; }
    @media screen and (max-width: 1023px) {
      .writeux .table table tbody, .writeux .table table tbody > tr {
        display: flex;
        flex-direction: column;
        text-align: left !important; } }
  .writeux .table-container .head {
    text-align: center;
    padding: 10px 0;
    color: white;
    background-color: var(--cl-blue, #000); }
  .writeux .table-container table {
    margin-top: 0;
    width: 100%;
    border-collapse: collapse; }
  .writeux .table-container td, .writeux .table-container th {
    border: 1px solid #cccccc; }
  .writeux .table-container table tbody > tr > td:not(:first-child):not(:last-child) {
    text-align: left; }
  .writeux .table-container table tbody > tr > td:first-child {
    padding-right: 8px;
    padding-left: 8px; }
  .writeux .table-container table tbody > tr > td:last-child {
    text-align: left;
    padding-right: 8px;
    padding-left: 8px; }
  @media screen and (max-width: 1023px) {
    .writeux .table-container thead, .writeux .table-container thead > tr, .writeux .table-container thead > tr > td {
      display: block; }
    .writeux .table-container tbody, .writeux .table-container tbody > tr,
    .writeux .table-container tbody > tr > td {
      display: table-cell;
      text-align: left !important; }
    .writeux .table-container tbody > tr > td {
      position: relative;
      white-space: normal;
      padding: 8px 16px !important; }
      .writeux .table-container tbody > tr > td::before {
        content: none;
        width: 50%;
        flex: 0 0 50%;
        align-self: stretch;
        display: inline-flex;
        align-items: center;
        font-size: 16px;
        color: var(--cl-blue, #000);
        font-weight: 500;
        margin-right: 8px; } }
    @media screen and (max-width: 1023px) and (min-width: 768px) {
      .writeux .table-container tbody > tr > td::before {
        font-size: 18px; } }
    @media screen and (max-width: 1023px) and (min-width: 1024px) {
      .writeux .table-container tbody > tr > td::before {
        font-size: 18px; } }
  @media screen and (max-width: 1023px) {
      .writeux .table-container tbody > tr > td:not(:first-child) {
        border-top: 1px solid #E1E1E1; }
    .writeux .table-container tbody > tr {
      border-left: none;
      border-right: none; }
      .writeux .table-container tbody > tr:first-child {
        border-top: none;
        border-top-right-radius: 0;
        border-top-left-radius: 0; }
      .writeux .table-container tbody > tr:not(:first-child) {
        border-top: none; }
      .writeux .table-container tbody > tr:last-child {
        border-bottom: none;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0; }
    .writeux .table-container tr {
      display: flex;
      flex-wrap: wrap; }
      .writeux .table-container tr > td:first-child {
        display: block; }
        .writeux .table-container tr > td:first-child[rowspan], .writeux .table-container tr > td:first-child[colspan] {
          flex: 0 0 100%;
          background: #666666;
          color: var(--cl-w); }
        .writeux .table-container tr > td:first-child:not([rowspan]):not([colspan]) {
          flex: 1; }
      .writeux .table-container tr > td:not(:first-child) {
        flex: 1; } }
  .writeux .table-container + .table-container {
    margin-top: 28px; }
    @media screen and (min-width: 768px) {
      .writeux .table-container + .table-container {
        margin-top: 32px; } }
    @media screen and (min-width: 1024px) {
      .writeux .table-container + .table-container {
        margin-top: 40px; } }

.share-socials {
  display: flex;
  align-items: flex-end; }
  .share-socials > * {
    font-size: 16px;
    font-weight: 400;
    color: #666666; }
    @media screen and (min-width: 768px) {
      .share-socials > * {
        font-size: 18px; } }
    @media screen and (min-width: 1024px) {
      .share-socials > * {
        font-size: 18px; } }
  .share-socials ul {
    list-style-type: none;
    margin-left: 20px;
    display: flex;
    align-items: flex-end; }
    .share-socials ul li + li {
      margin-left: 18px; }

@keyframes rotateSvg {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(359deg); } }

@keyframes strokeSvg {
  0% {
    stroke-dashoffset: 0;
    stroke-dasharray: 15 5; }
  100% {
    stroke-dashoffset: -179;
    stroke-dasharray: 15 5; } }

.settings {
  z-index: 999;
  position: fixed;
  top: calc(var(--hHeader, 0px) + 20px);
  left: 0%; }
  .settings-item {
    --bgItem: var(--cl-blue);
    --bgFixed: linear-gradient(82.44deg, var(--cl-red, #000) 10.71%, var(--cl-blue, #000) 110.16%);
    --whFixed: 36px;
    --timeAnimation: 3s; }
    .settings-item:not(:first-child) {
      margin-top: 38px; }
    .settings-item .fixed {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start; }
      .settings-item .fixed [stroke]:not([stroke="none"]) {
        stroke: currentColor; }
      .settings-item .fixed [fill]:not([fill="none"]) {
        fill: currentColor; }
      .settings-item .fixed-icons {
        order: 2;
        background: var(--bgFixed, #000);
        color: #FFF;
        width: var(--whFixed, auto);
        height: var(--whFixed, auto);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0% 50% 50% 0%;
        cursor: pointer; }
        .settings-item .fixed-icons .rotateSvg {
          transform-origin: center;
          animation: rotateSvg var(--timeAnimation) linear infinite; }
        .settings-item .fixed-icons .strokeSvg {
          animation: strokeSvg var(--timeAnimation) linear infinite; }
        .settings-item .fixed-icons.clicked ~ .fixed-list {
          transform: scale(1);
          height: auto;
          width: auto;
          opacity: 1;
          transition: .16s ease; }
      .settings-item .fixed-list {
        background-color: #FFF;
        position: relative;
        border-radius: 0 0 10px 0px;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
        height: 0;
        width: 0;
        padding: 0;
        transform: scale(0);
        opacity: 0;
        transform-origin: top left; }
        .settings-item .fixed-list::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: var(--whFixed, 0px);
          background-color: var(--cl-red, #000); }
        .settings-item .fixed-list > * {
          position: relative;
          z-index: 1; }
        .settings-item .fixed-list > *:first-child {
          border-top-left-radius: 10px; }
        .settings-item .fixed-list > *:last-child {
          border-bottom-right-radius: 10px; }
      .settings-item .fixed-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 16px 10px;
        width: 73px;
        border: 1px solid var(--fillFixed);
        background: var(--bgItem, #000);
        color: #FFF;
        cursor: pointer;
        transition: .5s ease; }
        .settings-item .fixed-item:not(:first-child) {
          margin-top: 1px; }
        .settings-item .fixed-item__title {
          margin-top: 10px;
          text-align: center; }
          .settings-item .fixed-item__title > * {
            transition: .5s ease;
            color: var(--fillFixed);
            font-size: 13px;
            font-weight: 600;
            color: currentColor; }
            @media screen and (min-width: 768px) {
              .settings-item .fixed-item__title > * {
                font-size: 13px; } }
            @media screen and (min-width: 1024px) {
              .settings-item .fixed-item__title > * {
                font-size: 14px; } }
        .settings-item .fixed-item:hover {
          background-color: var(--cl-red, #000); }

.scroll-to-top {
  z-index: 997;
  position: fixed;
  bottom: 16%;
  right: 20px;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  border: 1px solid #1E4487;
  background-color: var(--cl-w, #FFF);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: .5s ease; }
  .scroll-to-top svg {
    transform-origin: center; }
  .scroll-to-top [stroke]:not([stroke="none"]) {
    stroke: currentColor; }
  .scroll-to-top [fill]:not([fill="none"]) {
    fill: currentColor; }
  .scroll-to-top:hover {
    color: var(--cl-w, #FFF);
    background-color: #1E4487; }

.fixed-contact {
  position: fixed;
  z-index: 998;
  top: 40%;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3d93ff;
  color: #FFF;
  font-size: 15px;
  font-weight: 500;
  border-radius: 40px;
  transform: rotate(90deg);
  transform-origin: right center;
  --circleContact: 26px;
  height: var(--circleContact, 0px);
  border-radius: var(--circleContact, 0px);
  overflow: hidden;
  padding: calc(var(--circleContact, 0px) / 2);
  white-space: nowrap;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  animation: loopFixedBtn 10s infinite linear; }
  @media screen and (min-width: 768px) {
    .fixed-contact {
      right: 40px; } }
  @media screen and (min-width: 768px) {
    .fixed-contact {
      font-size: 16px; } }
  @media screen and (min-width: 1024px) {
    .fixed-contact {
      font-size: 16px; } }

@keyframes loopFixedBtn {
  0%, 20%, 80%, 100% {
    color: #FFF;
    clip-path: circle(70.7% at 50% 50%); }
  30%, 50%, 70% {
    clip-path: circle(calc(var(--circleContact, 0px) / 2) at 50% 50%);
    color: transparent; } }

@keyframes hoverFixedBtn {
  100% {
    color: #FFF;
    clip-path: circle(70.7% at 50% 50%); } }
  .fixed-contact:hover {
    animation: hoverFixedBtn 1s linear forwards; }

.block-tb-container {
  --tb-title-bg: var(--cl-blue, #000);
  --tb-content: var(--cl-content, #000);
  --tb-content-light: var(--cl-w, #FFF);
  --tb-border: #cccccc;
  --tb-row-bg: var(--cl-w, #FFF);
  --tb-col-bg: var(--cl-w, #FFF); }
  .block-tb-container .table_img-content {
    text-align: center;
    padding: 10px 0;
    color: white;
    background-color: var(--cl-blue, #000); }
  .block-tb-container .table_img-image {
    position: relative;
    display: block;
    width: 100%;
    padding-top: 56.25%; }
    .block-tb-container .table_img-image img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block; }
  .block-tb-container .table_title {
    padding: 10px 0;
    background-color: var(--tb-title-bg);
    color: var(--tb-content-light);
    font-size: 16px;
    text-align: center; }
    @media screen and (min-width: 768px) {
      .block-tb-container .table_title {
        padding: 16px 0; } }
    @media screen and (min-width: 768px) {
      .block-tb-container .table_title {
        font-size: 18px; } }
    @media screen and (min-width: 1024px) {
      .block-tb-container .table_title {
        font-size: 18px; } }
    .block-tb-container .table_title > * {
      color: inherit;
      font-size: inherit; }
  .block-tb-container .tb-container {
    border-top: solid 1px var(--tb-border);
    border-left: solid 1px var(--tb-border); }
    .block-tb-container .tb-container-row {
      display: flex;
      height: 100%;
      border-bottom: solid 1px var(--tb-border);
      background-color: var(--tb-row-bg); }
      .block-tb-container .tb-container-row .table-items {
        border-right: solid 1px var(--tb-border);
        height: 100%; }
        .block-tb-container .tb-container-row .table-items > *:not(:last-child) {
          border-bottom: solid 1px var(--tb-border); }
      .block-tb-container .tb-container-row .items {
        text-align: center;
        padding: 10px 0;
        color: var(--tb-content); }
      .block-tb-container .tb-container-row .head {
        padding-left: 10px;
        padding-right: 10px;
        min-width: 120px;
        display: flex;
        align-items: center;
        border-right: solid 1px var(--tb-border); }
        @media screen and (min-width: 1024px) {
          .block-tb-container .tb-container-row .head {
            padding-left: 60px; } }
        .block-tb-container .tb-container-row .head > * {
          font-weight: 700; }
      .block-tb-container .tb-container-row .infor {
        flex: 1;
        display: flex;
        align-items: center; }
  .block-tb-container + .block-tb-container {
    margin-top: 40px; }
    @media screen and (min-width: 768px) {
      .block-tb-container + .block-tb-container {
        margin-top: 48px; } }
    @media screen and (min-width: 1024px) {
      .block-tb-container + .block-tb-container {
        margin-top: 60px; } }

.overflow {
  overflow: auto; }

.overflow-y {
  overflow-x: hidden;
  overflow-y: auto; }

.overflow-x {
  overflow-y: hidden;
  overflow-x: auto; }

.error404 .container {
  padding: 54px 0;
  min-height: calc(100vh - var(--hHeader, 0px));
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center; }
  @media screen and (min-width: 768px) {
    .error404 .container {
      padding: 72px 0; } }
  @media screen and (min-width: 1024px) {
    .error404 .container {
      padding: 100px 0; } }
  .error404 .container .background {
    max-width: 90%; }
    .error404 .container .background > * {
      width: 100%;
      height: auto; }

.error404-btn {
  margin-top: 28px; }
  @media screen and (min-width: 768px) {
    .error404-btn {
      margin-top: 32px; } }
  @media screen and (min-width: 1024px) {
    .error404-btn {
      margin-top: 40px; } }
  .error404-btn .btn-error {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 16px 20px 12px 20px;
    min-width: 148px;
    font-size: 15px;
    color: var(--cl-w, #FFF);
    background: var(--cl-blue, #000);
    border-radius: 120px; }
    @media screen and (min-width: 768px) {
      .error404-btn .btn-error {
        padding: 21px 30px 17px 30px;
        min-width: 194px; } }
    @media screen and (min-width: 768px) {
      .error404-btn .btn-error {
        font-size: 16px; } }
    @media screen and (min-width: 1024px) {
      .error404-btn .btn-error {
        font-size: 16px; } }

.header {
  position: relative;
  z-index: 999; }
  .header .container {
    display: flex;
    justify-content: space-between;
    padding: 18px 0 20px 0; }
    @media screen and (min-width: 768px) {
      .header .container {
        padding: 33px 0 33px 0; } }
  .header__logo {
    display: block;
    width: 90px; }
    @media screen and (min-width: 768px) {
      .header__logo {
        width: 112px; } }
    .header__logo img, .header__logo svg {
      display: block; }
  .header-right {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .header-right > * {
      display: flex;
      align-items: center;
      margin-left: 12px; }
      @media screen and (min-width: 768px) {
        .header-right > * {
          margin-left: 32px; } }
    .header-right-toggle {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 4px;
      font-size: 1.8rem;
      position: relative;
      width: 30px;
      height: 30px;
      cursor: pointer;
      transition: .2s all linear; }
      @media screen and (min-width: 768px) {
        .header-right-toggle {
          display: none; } }
      .header-right-toggle span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: transparent;
        width: 60%;
        height: 0;
        border-top: 3px solid var(--cl-blue-dark, #000);
        border-radius: 10px;
        transition: .2s all linear;
        font-size: 0px; }
      .header-right-toggle::after, .header-right-toggle::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 60%;
        height: 0;
        border-top: 3px solid var(--cl-blue-dark, #000);
        border-radius: 10px;
        transition: .2s all linear; }
      .header-right-toggle::before {
        transform: translate(-50%, calc(-50% + 6px)) rotate(0); }
      .header-right-toggle::after {
        transform: translate(-50%, calc(-50% - 6px)) rotate(0); }
      .header-right-toggle.showed::after, .header-right-toggle.showed::before {
        width: 70%; }
      .header-right-toggle.showed span {
        font-size: 0;
        width: 0; }
      .header-right-toggle.showed ~ .header-right-nav {
        display: flex;
        flex-direction: column; }
      .header-right-toggle.showed::before {
        transform: translate(-50%, -50%) rotate(-45deg); }
      .header-right-toggle.showed::after {
        transform: translate(-50%, -50%) rotate(45deg); }
    @media screen and (min-width: 768px) {
      .header-right-nav ul {
        list-style-type: none;
        display: flex;
        align-items: center; }
        .header-right-nav ul li + li {
          margin-left: 12px; } }
    @media screen and (min-width: 768px) and (min-width: 1024px) {
      .header-right-nav ul li + li {
        margin-left: 23px; } }
    @media screen and (min-width: 768px) {
        .header-right-nav ul > li {
          text-align: center;
          font-size: 14px;
          line-height: 143%;
          font-weight: 500;
          text-transform: uppercase;
          color: var(--cl-content, #000);
          position: relative;
          padding: 8px 0; }
          .header-right-nav ul > li.is-actived {
            color: var(--cl-red, #000); }
          .header-right-nav ul > li a:hover {
            color: var(--cl-red, #000); }
          .header-right-nav ul > li:hover > ul {
            display: flex; }
          .header-right-nav ul > li .icon {
            display: none; }
          .header-right-nav ul > li > ul {
            display: none;
            position: absolute;
            top: 100%;
            left: -11px;
            width: auto;
            min-width: 140px;
            align-items: flex-start;
            flex-direction: column;
            background-color: #FFF;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            text-align: left;
            padding: 15px 21px 15px 11px; }
            .header-right-nav ul > li > ul li + li {
              margin-left: 0;
              margin-top: 10px; }
            .header-right-nav ul > li > ul > li {
              width: auto;
              white-space: nowrap;
              text-align: left;
              padding: 0;
              color: var(--cl-content, #000); }
              .header-right-nav ul > li > ul > li.is-actived {
                color: var(--cl-blue, #000); }
              .header-right-nav ul > li > ul > li a {
                text-transform: uppercase; }
              .header-right-nav ul > li > ul > li a:hover {
                color: var(--cl-blue, #000); } }
    @media screen and (max-width: 767px) {
      .header-right-nav {
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        width: 100%;
        height: calc(100vh - var(--hHeader, 0px));
        background-color: var(--cl-blue, #FFF);
        overflow-x: hidden;
        overflow-y: auto; }
        .header-right-nav ul {
          width: 100%;
          flex-direction: column;
          align-items: flex-start;
          color: var(--cl-content-light, #000); }
          .header-right-nav ul > li {
            font-size: 14px;
            line-height: 143%;
            font-weight: 500;
            text-transform: uppercase;
            color: var(--cl-content, #000);
            text-align: left;
            width: 100%;
            color: var(--cl-content-light, #000);
            padding: 10px 2vw;
            display: flex;
            align-items: center;
            flex-wrap: wrap; }
            .header-right-nav ul > li > .icon {
              flex: 1;
              text-align: right; }
              .header-right-nav ul > li > .icon svg {
                transition: .2s transform linear; }
            .header-right-nav ul > li > ul {
              margin-top: 4px;
              display: none; }
              .header-right-nav ul > li > ul > li {
                width: auto;
                white-space: nowrap;
                text-align: left;
                padding: 8px 2vw;
                color: var(--cl-content-light, #000); }
                .header-right-nav ul > li > ul > li.is-actived {
                  color: var(--cl-w, #000); }
                .header-right-nav ul > li > ul > li a {
                  text-transform: initial; }
            .header-right-nav ul > li.is-actived {
              color: var(--cl-red, #000); }
            .header-right-nav ul > li.is-showed .icon svg {
              transform: rotate(180deg); }
            .header-right-nav ul > li.is-showed > ul {
              display: block;
              flex: 0 0 100%;
              max-width: 100%; }
            .header-right-nav ul > li.is-showed a:hover {
              color: var(--cl-red, #000); } }
    .header-right-account {
      position: relative;
      height: 100%; }
      .header-right-account::after {
        content: "";
        position: absolute;
        top: calc(50% + 20px);
        left: 50%;
        width: 300%;
        height: 20px;
        background-color: transparent;
        transform: translate(-50%, -50%);
        z-index: 2; }
      .header-right-account > a {
        display: block;
        width: 20px;
        height: 20px;
        color: var(--cl-b, #000); }
        .header-right-account > a img, .header-right-account > a svg {
          display: block;
          object-fit: contain;
          height: 100%;
          width: auto;
          max-width: 100%; }
        .header-right-account > a [stroke]:not([stroke="none"]) {
          stroke: currentColor; }
        .header-right-account > a [fill]:not([fill="none"]) {
          fill: currentColor; }
      .header-right-account.is-actived, .about-mv-options li.is-actived > a.header-right-account {
        color: var(--cl-red, #000); }
      .header-right-account > a:hover, .header-right-account > a:focus {
        color: var(--cl-red, #000); }
        .header-right-account > a:hover ~ ul, .header-right-account > a:focus ~ ul {
          display: flex; }
      .header-right-account:hover > ul {
        display: flex; }
      .header-right-account > ul {
        display: none;
        position: absolute;
        top: calc(50% + 20px);
        right: -11px;
        left: auto;
        width: auto;
        min-width: 140px;
        list-style-type: none;
        align-items: flex-start;
        flex-direction: column;
        background-color: #FFF;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        text-align: left;
        padding: 15px 21px 15px 11px; }
        .header-right-account > ul li + li {
          margin-left: 0;
          margin-top: 10px; }
        .header-right-account > ul > li {
          width: auto;
          white-space: nowrap;
          text-align: left;
          padding: 0;
          color: var(--cl-content, #000); }
          .header-right-account > ul > li.is-actived {
            color: var(--cl-blue, #000); }
          .header-right-account > ul > li a:hover {
            color: var(--cl-blue, #000); }
    .header-right-languages ul {
      display: flex;
      list-style-type: none; }
      .header-right-languages ul li + li {
        margin-left: 15px; }
      .header-right-languages ul li {
        height: 22px;
        width: 32.64px; }
        .header-right-languages ul li a {
          display: block;
          width: 100%;
          height: 100%; }
          .header-right-languages ul li a img, .header-right-languages ul li a svg {
            display: block;
            width: 100%;
            height: 100%; }
        .header-right-languages ul li.selected {
          position: relative; }
          .header-right-languages ul li.selected::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--cl-blue, #000);
            opacity: .8;
            mix-blend-mode: multiply; }

.banner-section {
  position: relative;
  max-width: 100%; }
  .banner-section__thumb {
    position: relative;
    width: 100%;
    height: 280px;
    max-height: calc(100vh - var(--hHeader, 0px));
    z-index: -1; }
    @media screen and (min-width: 576px) {
      .banner-section__thumb {
        padding-top: 41.667%;
        width: 100%;
        height: 0; } }
    .banner-section__thumb > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
      @media screen and (min-width: 768px) {
        .banner-section__thumb > * {
          clip-path: ellipse(96% 100% at 50% 0%); } }
      @media screen and (min-width: 1024px) {
        .banner-section__thumb > * {
          clip-path: ellipse(86% 100% at 50% 0%); } }
      @media screen and (min-width: 1280px) {
        .banner-section__thumb > * {
          clip-path: ellipse(70% 100% at 50% 0%); } }
  .banner-section .banner-img {
    position: relative;
    width: 100%;
    height: 400px; }
    @media screen and (min-width: 576px) {
      .banner-section .banner-img {
        padding-top: 41.667%;
        width: 100%;
        height: 0; } }
    .banner-section .banner-img img, .banner-section .banner-img svg, .banner-section .banner-img video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
      @media screen and (min-width: 768px) {
        .banner-section .banner-img img, .banner-section .banner-img svg, .banner-section .banner-img video {
          clip-path: ellipse(96% 100% at 50% 0%); } }
      @media screen and (min-width: 1024px) {
        .banner-section .banner-img img, .banner-section .banner-img svg, .banner-section .banner-img video {
          clip-path: ellipse(86% 100% at 50% 0%); } }
      @media screen and (min-width: 1280px) {
        .banner-section .banner-img img, .banner-section .banner-img svg, .banner-section .banner-img video {
          clip-path: ellipse(70% 100% at 50% 0%); } }

@keyframes iconLeftRotate_banner {
  0%, 20%, 80%, 100% {
    bottom: -28%;
    left: -8.7%;
    transform: scale(1);
    display: block; }
  30%, 50%, 70% {
    bottom: -60%;
    left: -80%;
    transform: rotate(-40deg) scale(3);
    display: block; } }

@keyframes iconRightRotate_banner {
  0%, 20%, 80%, 100% {
    right: -7%;
    bottom: -20%;
    transform: scale(1);
    display: block; }
  30%, 50%, 70% {
    bottom: -45%;
    right: -60%;
    transform: rotate(45deg) scale(2);
    display: block; } }
  @media screen and (min-width: 768px) {
    @keyframes iconLeftRotate_banner {
      0%, 20%, 80%, 100% {
        bottom: -25%;
        left: -8%;
        transform: scale(1);
        display: block; }
      30%, 50%, 70% {
        bottom: -25%;
        left: -60%;
        transform: rotate(-40deg) scale(3);
        display: block; } }
    @keyframes iconRightRotate_banner {
      0%, 20%, 80%, 100% {
        right: -6%;
        bottom: -20%;
        transform: scale(1);
        display: block; }
      30%, 50%, 70% {
        right: -35%;
        bottom: -45%;
        transform: rotate(45deg) scale(2);
        display: block; } } }
  @media screen and (min-width: 1024px) {
    @keyframes iconLeftRotate_banner {
      0%, 20%, 80%, 100% {
        bottom: -25%;
        left: -1.7%;
        transform: scale(1);
        display: block; }
      30%, 50%, 70% {
        bottom: -25%;
        left: -60%;
        transform: rotate(-40deg) scale(3);
        display: block; } }
    @keyframes iconRightRotate_banner {
      0%, 20%, 80%, 100% {
        bottom: -16.5%;
        right: 0%;
        transform: scale(1);
        display: block; }
      30%, 50%, 70% {
        bottom: -38%;
        right: -30%;
        transform: rotate(45deg) scale(2);
        display: block; } } }
  .banner-section .icon-left, .banner-section .icon-right {
    opacity: 0.45;
    display: none; }
    @media screen and (min-width: 768px) {
      .banner-section .icon-left, .banner-section .icon-right {
        opacity: 1; } }
    .banner-section .icon-left svg, .banner-section .icon-right svg {
      width: 100%; }
  .banner-section .icon-left {
    position: absolute;
    display: block;
    width: 80px;
    bottom: -28%;
    left: -8.7%;
    transform: scale(1);
    animation: 14s iconLeftRotate_banner infinite ease; }
    @media screen and (min-width: 420px) {
      .banner-section .icon-left {
        width: 100px; } }
    @media screen and (min-width: 768px) {
      .banner-section .icon-left {
        width: 196px;
        bottom: -25%;
        left: -8%;
        transform: scale(1); } }
    @media screen and (min-width: 1024px) {
      .banner-section .icon-left {
        bottom: -25%;
        left: -1.7%;
        transform: scale(1); } }
  .banner-section .icon-right {
    position: absolute;
    display: block;
    right: -7%;
    bottom: -20%;
    transform: scale(1);
    width: 60px;
    animation: 14s iconRightRotate_banner infinite ease; }
    @media screen and (min-width: 420px) {
      .banner-section .icon-right {
        width: 80px; } }
    @media screen and (min-width: 768px) {
      .banner-section .icon-right {
        width: 140px;
        right: -6%;
        bottom: -20%;
        transform: scale(1); } }
    @media screen and (min-width: 1024px) {
      .banner-section .icon-right {
        bottom: -16.5%;
        right: 0%;
        transform: scale(1); } }

.footer-wrapper {
  position: relative;
  color: var(--cl-content-light, #FFF);
  background-image: url("../assets/images/footer-bg.jpg");
  background-size: cover;
  padding: 70px 0 64px 0; }
  .footer-wrapper::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--cl-blue, #000);
    opacity: 0.9;
    z-index: 1; }
  .footer-wrapper > * {
    position: relative;
    z-index: 2; }

.footer-copyright {
  border-top: 1px solid #FFFFFF;
  background-color: #002E5C;
  color: var(--cl-content-light, #FFF);
  font-size: 14px;
  font-weight: 400;
  line-height: 157%;
  padding: 18px 0; }
  .footer-copyright a {
    display: inline-block;
    color: currentColor; }
    .footer-copyright a:hover {
      color: var(--cl-red, #FFF); }
  .footer-copyright .container {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .footer-copyright .container .footer-copyright__content {
      flex: 1; }
  .footer-copyright-socials ul {
    list-style-type: none;
    display: flex; }
    .footer-copyright-socials ul li + li {
      margin-left: 22px; }
    .footer-copyright-socials ul li {
      width: 18px;
      height: 18px; }
      .footer-copyright-socials ul li a {
        display: block;
        width: 100%;
        height: 100%;
        transition: .16s transform linear; }
        .footer-copyright-socials ul li a svg, .footer-copyright-socials ul li a img {
          display: block;
          max-width: 100%;
          width: auto;
          height: 100%;
          object-fit: contain; }
        .footer-copyright-socials ul li a:hover {
          transform: scale(1.08); }

.footer-infor {
  font-size: 15px;
  line-height: 188%; }
  @media screen and (min-width: 768px) {
    .footer-infor {
      font-size: 16px; } }
  @media screen and (min-width: 1024px) {
    .footer-infor {
      font-size: 16px; } }
  .footer-infor p, .footer-infor span {
    font-weight: 500; }
  .footer-infor a {
    color: currentColor; }
    .footer-infor a:hover {
      color: var(--cl-red, #FFF); }
  .footer-infor__logo {
    display: block;
    width: 114px;
    max-width: 100%;
    margin-bottom: 32px; }
    .footer-infor__logo img, .footer-infor__logo svg {
      display: block; }
    @media screen and (min-width: 768px) {
      .footer-infor__logo {
        margin-left: auto;
        margin-right: auto; } }
    @media screen and (min-width: 1024px) {
      .footer-infor__logo {
        margin-left: 0;
        margin-right: 0; } }
  @media screen and (min-width: 768px) {
    .footer-infor {
      text-align: center; } }
  @media screen and (min-width: 1024px) {
    .footer-infor {
      text-align: left; } }

.footer-nav h3 {
  font-size: 18px;
  line-height: 175%;
  font-weight: 500;
  text-transform: uppercase; }
  @media screen and (min-width: 768px) {
    .footer-nav h3 {
      font-size: 20px; } }
  @media screen and (min-width: 1024px) {
    .footer-nav h3 {
      font-size: 20px; } }

.footer-nav ul {
  margin-top: 15px;
  list-style-type: none; }
  .footer-nav ul > li {
    display: block;
    font-size: 15px;
    font-weight: 400;
    line-height: 133%;
    text-transform: uppercase; }
    .footer-nav ul > li a {
      color: currentColor; }
      .footer-nav ul > li a:hover {
        color: var(--cl-red, #FFF); }
    .footer-nav ul > li + li {
      margin-top: 18px; }

.breadcrumbs {
  width: 100%;
  padding-top: 20px; }
  @media screen and (min-width: 1024px) {
    .breadcrumbs {
      padding-top: 40px; } }
  .breadcrumbs-list {
    display: flex;
    align-items: center;
    list-style-type: none;
    justify-content: center; }
  .breadcrumbs-item {
    display: flex;
    align-items: center;
    line-height: 1.4;
    white-space: nowrap; }
    .breadcrumbs-item > * {
      font-size: 16px;
      color: var(--cl-content, #000);
      font-weight: 500;
      margin-right: 8px; }
      @media screen and (min-width: 768px) {
        .breadcrumbs-item > * {
          font-size: 18px; } }
      @media screen and (min-width: 1024px) {
        .breadcrumbs-item > * {
          font-size: 18px; } }
      @media screen and (min-width: 768px) {
        .breadcrumbs-item > * {
          margin-right: 12px; } }
    .breadcrumbs-item * {
      transition: .3s all linear; }
    .breadcrumbs-item.is-actived .breadcrumbs-item__link, .about-mv-options li.is-actived > a.breadcrumbs-item .breadcrumbs-item__link {
      cursor: initial;
      pointer-events: none;
      color: var(--cl-blue, #000); }
    .breadcrumbs-item:hover .breadcrumbs-item__link {
      color: var(--cl-red, #000); }

.popup-form {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  justify-content: center;
  align-items: center;
  display: none; }
  .popup-form.is-actived, .about-mv-options li.is-actived > a.popup-form {
    display: flex; }
  .popup-form .popup-form-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.8; }
  .popup-form .modal {
    position: relative;
    z-index: 1; }
    .popup-form .modal-close {
      position: absolute;
      top: calc(100% + 40px);
      left: 50%;
      transform: translateX(-50%);
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background-color: #FFF;
      cursor: pointer;
      transition: .2s all linear; }
      .popup-form .modal-close::after, .popup-form .modal-close::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 42%;
        height: 0;
        border-top: 1px solid #000;
        border-radius: 10px; }
      .popup-form .modal-close::before {
        transform: translate(-50%, -50%) rotate(-45deg); }
      .popup-form .modal-close::after {
        transform: translate(-50%, -50%) rotate(45deg); }
      .popup-form .modal-close:hover, .popup-form .modal-close:focus {
        transform: translateX(-50%) rotate(90deg) scale(1.1); }
    .popup-form .modal-title {
      position: absolute;
      bottom: calc(100%);
      left: 0;
      width: 100%;
      padding-top: 10px;
      padding-bottom: 30px;
      text-align: center; }
      .popup-form .modal-title > * {
        color: var(--cl-title-light, #FFF);
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase; }
        @media screen and (min-width: 768px) {
          .popup-form .modal-title > * {
            font-size: 22px; } }
        @media screen and (min-width: 1024px) {
          .popup-form .modal-title > * {
            font-size: 24px; } }
    .popup-form .modal-dialog {
      background-color: var(--cl-w, #FFF);
      padding: 40px 30px;
      width: var(--wDialog, 36vw);
      border-radius: 10px;
      max-width: var(--wContainer, 100%);
      max-height: calc(100vh - 180px);
      overflow: auto; }
    .popup-form .modal-content {
      font-size: 20px;
      font-weight: 500;
      color: var(--cl-content, #000);
      text-align: center; }
      @media screen and (min-width: 768px) {
        .popup-form .modal-content {
          font-size: 22px; } }
      @media screen and (min-width: 1024px) {
        .popup-form .modal-content {
          font-size: 24px; } }
    .popup-form .modal-buttons {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap; }
      .popup-form .modal-buttons > * {
        appearance: none;
        background: none;
        outline: none;
        border: none;
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 10px;
        min-width: 140px;
        border-radius: 10px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: var(--cl-w, #FFF);
        font-size: 20px;
        font-weight: 500;
        padding: 10px 18px 6px 18px;
        background-color: var(--cl-red, #000);
        transition: .2s all linear; }
        @media screen and (min-width: 768px) {
          .popup-form .modal-buttons > * {
            font-size: 22px; } }
        @media screen and (min-width: 1024px) {
          .popup-form .modal-buttons > * {
            font-size: 24px; } }
        .popup-form .modal-buttons > *:hover {
          background-color: var(--cl-blue, #000);
          box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25); }
    .popup-form .modal-form .group-input {
      display: flex;
      flex-direction: column; }
      .popup-form .modal-form .group-input label {
        font-size: 15px;
        font-weight: 500; }
        @media screen and (min-width: 768px) {
          .popup-form .modal-form .group-input label {
            font-size: 16px; } }
        @media screen and (min-width: 1024px) {
          .popup-form .modal-form .group-input label {
            font-size: 16px; } }
        .popup-form .modal-form .group-input label span {
          color: var(--cl-red, red); }
      .popup-form .modal-form .group-input > .row > *:last-child {
        text-align: right; }
      .popup-form .modal-form .group-input input, .popup-form .modal-form .group-input textarea {
        margin-top: 8px;
        resize: none;
        appearance: none;
        background: none;
        outline: none;
        border: none;
        background-color: #F9F9F9;
        border-radius: 4px;
        border: 1px solid #EEEEEE;
        color: #111111;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.2;
        padding: 8px; }
        @media screen and (min-width: 768px) {
          .popup-form .modal-form .group-input input, .popup-form .modal-form .group-input textarea {
            font-size: 16px; } }
        @media screen and (min-width: 1024px) {
          .popup-form .modal-form .group-input input, .popup-form .modal-form .group-input textarea {
            font-size: 16px; } }
        .popup-form .modal-form .group-input input:disabled, .popup-form .modal-form .group-input textarea:disabled {
          pointer-events: none;
          opacity: .7; }
    .popup-form .modal-form .group-submit {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap; }
      .popup-form .modal-form .group-submit button, .popup-form .modal-form .group-submit input {
        appearance: none;
        background: none;
        outline: none;
        border: none;
        display: block;
        width: 100%;
        padding: 8px 20px;
        border-radius: 8px;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.2;
        color: var(--cl-w, #FFF);
        background-color: var(--cl-red, #000);
        transition: .2s all linear; }
        @media screen and (min-width: 768px) {
          .popup-form .modal-form .group-submit button, .popup-form .modal-form .group-submit input {
            font-size: 16px; } }
        @media screen and (min-width: 1024px) {
          .popup-form .modal-form .group-submit button, .popup-form .modal-form .group-submit input {
            font-size: 16px; } }
        .popup-form .modal-form .group-submit button:hover, .popup-form .modal-form .group-submit input:hover {
          background-color: var(--cl-blue, #000);
          box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25); }
    .popup-form .modal-form a {
      color: var(--cl-blue, #000);
      display: inline-block; }
      .popup-form .modal-form a:hover {
        color: var(--cl-red, #000); }

.index-intro {
  padding-top: 20px;
  overflow: hidden; }
  @media screen and (min-width: 768px) {
    .index-intro {
      padding-top: 34px; } }
  .index-intro-wrapper {
    background-color: var(--cl-w, #FFF);
    position: relative;
    --timerEffect: 10s;
    z-index: 10;
    padding-bottom: 80px; }
    @media screen and (min-width: 768px) {
      @keyframes textTranslate_1 {
        0%, 20%, 80%, 100% {
          transform: translateX(0%); }
        30%, 50%, 70% {
          transform: translateX(-8%); } }
      @keyframes textTranslate_2 {
        0%, 20%, 80%, 100% {
          transform: translateX(0%); }
        30%, 50%, 70% {
          transform: translateX(14%); } }
      @keyframes textTranslate_3 {
        0%, 20%, 80%, 100% {
          transform: translateX(0%); }
        30%, 50%, 70% {
          transform: translateX(-8%); } } }
    @media screen and (min-width: 1024px) {
      @keyframes textTranslate_1 {
        0%, 20%, 80%, 100% {
          transform: translateX(0%); }
        30%, 50%, 70% {
          transform: translateX(-10%); } }
      @keyframes textTranslate_2 {
        0%, 20%, 80%, 100% {
          transform: translateX(0%); }
        30%, 50%, 70% {
          transform: translateX(16%); } }
      @keyframes textTranslate_3 {
        0%, 20%, 80%, 100% {
          transform: translateX(0%); }
        30%, 50%, 70% {
          transform: translateX(-10%); } } }

@keyframes iconLeftRotate {
  0%, 20%, 80%, 100% {
    bottom: 37%;
    left: -3%;
    transform: scale(1); }
  30%, 50%, 70% {
    bottom: 30%;
    left: -30%;
    transform: rotate(-40deg) scale(3); } }

@keyframes iconRightRotate {
  0%, 20%, 80%, 100% {
    right: -3%;
    bottom: 34%;
    transform: scale(1); }
  30%, 50%, 70% {
    right: -68%;
    bottom: 4%;
    transform: rotate(-45deg) scale(2) translate(0%, 0%); } }
    @media screen and (min-width: 768px) {
      @keyframes iconLeftRotate {
        0%, 20%, 80%, 100% {
          bottom: 37%;
          left: -3%;
          transform: scale(1); }
        30%, 50%, 70% {
          bottom: 30%;
          left: -30%;
          transform: rotate(-40deg) scale(3); } }
      @keyframes iconRightRotate {
        0%, 20%, 80%, 100% {
          right: -8%;
          bottom: 34%;
          transform: scale(1); }
        30%, 50%, 70% {
          right: -80%;
          bottom: 4%;
          transform: rotate(-45deg) scale(2) translate(0%, 0%); } } }
    @media screen and (min-width: 1024px) {
      @keyframes iconLeftRotate {
        0%, 20%, 80%, 100% {
          bottom: 37%;
          left: -3%;
          transform: scale(1); }
        30%, 50%, 70% {
          bottom: 30%;
          left: -30%;
          transform: rotate(-40deg) scale(3); } }
      @keyframes iconRightRotate {
        0%, 20%, 80%, 100% {
          right: -14%;
          bottom: 34%;
          transform: scale(1); }
        30%, 50%, 70% {
          right: -68%;
          bottom: 20%;
          transform: rotate(-45deg) scale(2) translate(0%, 0%); } } }
    @media screen and (min-width: 1280px) {
      @keyframes iconLeftRotate {
        0%, 20%, 80%, 100% {
          bottom: 37%;
          left: -3%;
          transform: scale(1); }
        30%, 50%, 70% {
          bottom: 30%;
          left: -30%;
          transform: rotate(-40deg) scale(3); } }
      @keyframes iconRightRotate {
        0%, 20%, 80%, 100% {
          right: -4%;
          bottom: 34%;
          transform: scale(1); }
        30%, 50%, 70% {
          right: -68%;
          bottom: 20%;
          transform: rotate(-45deg) scale(2) translate(0%, 0%); } } }
    .index-intro-wrapper .icon-left {
      position: absolute;
      z-index: -1;
      bottom: 0%;
      left: 0%;
      width: 80px;
      opacity: 0.45;
      animation: var(--timerEffect, 10s) iconLeftRotate infinite ease; }
      .index-intro-wrapper .icon-left svg, .index-intro-wrapper .icon-left img {
        display: block;
        width: 100%;
        height: auto; }
      @media screen and (min-width: 768px) {
        .index-intro-wrapper .icon-left {
          width: 98px;
          opacity: 1; } }
      @media screen and (min-width: 1024px) {
        .index-intro-wrapper .icon-left {
          width: 130px; } }
      @media screen and (min-width: 1280px) {
        .index-intro-wrapper .icon-left {
          width: 196px; } }
    .index-intro-wrapper .icon-right {
      position: absolute;
      z-index: -1;
      right: 0%;
      bottom: 0%;
      width: 160px;
      opacity: 0.45;
      animation: var(--timerEffect, 10s) iconRightRotate infinite ease; }
      .index-intro-wrapper .icon-right svg, .index-intro-wrapper .icon-right img {
        display: block;
        width: 100%;
        height: auto; }
      @media screen and (min-width: 768px) {
        .index-intro-wrapper .icon-right {
          width: 320px;
          opacity: 1; } }
      @media screen and (min-width: 1024px) {
        .index-intro-wrapper .icon-right {
          width: 380px; } }
      @media screen and (min-width: 1280px) {
        .index-intro-wrapper .icon-right {
          width: 459px; } }
    .index-intro-wrapper .title {
      margin-left: 6px;
      margin-right: auto;
      font-weight: 700;
      line-height: 1;
      font-size: 68px;
      text-align: center;
      padding-top: 6px; }
      @media screen and (min-width: 768px) {
        .index-intro-wrapper .title {
          font-size: 100px; } }
      @media screen and (min-width: 1024px) {
        .index-intro-wrapper .title {
          font-size: 131px; } }
      @media screen and (min-width: 768px) {
        .index-intro-wrapper .title {
          padding-top: 20px; } }
      @media screen and (min-width: 1024px) {
        .index-intro-wrapper .title {
          padding-top: 31px; } }
      .index-intro-wrapper .title span {
        margin-top: -6px;
        display: block;
        text-transform: uppercase;
        background-image: url(../assets/images/homepage/intro-bg.jpg);
        background-repeat: repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-size: cover; }
        @media screen and (min-width: 768px) {
          .index-intro-wrapper .title span {
            margin-top: -20px; } }
        @media screen and (min-width: 1024px) {
          .index-intro-wrapper .title span {
            margin-top: -31px; } }
      .index-intro-wrapper .title span:nth-of-type(1) {
        animation: textTranslate_1 10s infinite cubic-bezier(0.175, 0.885, 0.32, 1.275); }
      .index-intro-wrapper .title span:nth-of-type(2) {
        animation: textTranslate_2 10s infinite cubic-bezier(0.175, 0.885, 0.32, 1.275); }
      .index-intro-wrapper .title span:nth-of-type(3) {
        animation: textTranslate_3 10s infinite cubic-bezier(0.175, 0.885, 0.32, 1.275); }
    .index-intro-wrapper .content {
      font-size: 16px;
      line-height: 1.3888889;
      color: var(--cl-content, #000);
      width: 100%;
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;
      text-align: center; }
      @media screen and (min-width: 768px) {
        .index-intro-wrapper .content {
          font-size: 18px; } }
      @media screen and (min-width: 1024px) {
        .index-intro-wrapper .content {
          font-size: 18px; } }
      @media screen and (min-width: 768px) {
        .index-intro-wrapper .content {
          width: 75%; } }
      @media screen and (min-width: 1024px) {
        .index-intro-wrapper .content {
          width: 50%; } }
      @media screen and (min-width: 768px) {
        .index-intro-wrapper .content {
          margin-top: 34px; } }
    @media screen and (min-width: 768px) {
      .index-intro-wrapper {
        padding-bottom: 100px;
        clip-path: ellipse(96% 100% at 50% 0%); } }
    @media screen and (min-width: 1024px) {
      .index-intro-wrapper {
        padding-bottom: 198px;
        clip-path: ellipse(86% 100% at 50% 0%); } }
    @media screen and (min-width: 1280px) {
      .index-intro-wrapper {
        clip-path: ellipse(70% 100% at 50% 0%); } }
  .index-intro__thumb {
    display: block;
    position: relative;
    z-index: -1;
    width: 100%;
    height: 0;
    padding-top: 40.3%; }
    @media screen and (min-width: 768px) {
      .index-intro__thumb {
        margin-top: -130px; } }
    @media screen and (min-width: 1024px) {
      .index-intro__thumb {
        margin-top: -230px; } }
    .index-intro__thumb img, .index-intro__thumb svg {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    .index-intro__thumb .swiper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }

.index-about-wrapper {
  margin-top: 54px;
  display: flex;
  flex-direction: column; }
  @media screen and (min-width: 768px) {
    .index-about-wrapper {
      margin-top: 72px; } }
  @media screen and (min-width: 1024px) {
    .index-about-wrapper {
      margin-top: 100px; } }
  @media screen and (min-width: 768px) {
    .index-about-wrapper {
      flex-direction: row; } }
  .index-about-wrapper .title {
    flex: 1; }
    @media screen and (min-width: 768px) {
      .index-about-wrapper .title {
        margin-top: -10px; } }
    @media screen and (min-width: 1280px) {
      .index-about-wrapper .title {
        margin-top: -18px;
        padding-left: 75px; } }
    .index-about-wrapper .title > * {
      transition: 3s transform cubic-bezier(0.075, 0.82, 0.165, 1); }
    .index-about-wrapper .title h1 {
      font-size: 80px;
      font-weight: 700;
      line-height: 93%;
      color: var(--cl-blue, #000);
      text-transform: uppercase;
      white-space: nowrap;
      margin-top: 9px; }
      @media screen and (min-width: 768px) {
        .index-about-wrapper .title h1 {
          font-size: 112px; } }
      @media screen and (min-width: 1024px) {
        .index-about-wrapper .title h1 {
          font-size: 140px; } }
    .index-about-wrapper .title h2 {
      font-size: 40px;
      font-weight: 500;
      line-height: 133%;
      color: var(--cl-title, #000); }
      @media screen and (min-width: 768px) {
        .index-about-wrapper .title h2 {
          font-size: 48px; } }
      @media screen and (min-width: 1024px) {
        .index-about-wrapper .title h2 {
          font-size: 60px; } }
  .index-about-wrapper--right {
    margin-top: 20px; }
    @media screen and (min-width: 768px) {
      .index-about-wrapper--right {
        margin-top: 0;
        padding-left: 28px; } }
    @media screen and (min-width: 1024px) {
      .index-about-wrapper--right {
        padding-left: 40px; } }
    @media screen and (min-width: 1280px) {
      .index-about-wrapper--right {
        padding-left: 75px; } }
    .index-about-wrapper--right .content {
      text-align: justify;
      line-height: 1.3888889;
      color: var(--cl-content, #000);
      font-size: 16px; }
      @media screen and (min-width: 768px) {
        .index-about-wrapper--right .content {
          font-size: 18px; } }
      @media screen and (min-width: 1024px) {
        .index-about-wrapper--right .content {
          font-size: 18px; } }
    .index-about-wrapper--right .readmore {
      margin-top: 24px;
      color: var(--cl-content, #000);
      font-size: 15px; }
      @media screen and (min-width: 768px) {
        .index-about-wrapper--right .readmore {
          font-size: 16px; } }
      @media screen and (min-width: 1024px) {
        .index-about-wrapper--right .readmore {
          font-size: 16px; } }

.index-services {
  margin-top: 54px; }
  @media screen and (min-width: 768px) {
    .index-services {
      margin-top: 72px; } }
  @media screen and (min-width: 1024px) {
    .index-services {
      margin-top: 100px; } }
  .index-services-top {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    position: relative; }
    .index-services-top .readmore {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      margin-top: 10px;
      color: var(--cl-readmore, #000); }
  .index-services .title {
    text-align: center; }
    .index-services .title > * {
      letter-spacing: -1px;
      font-size: 40px;
      color: var(--cl-content, #000);
      text-align: center;
      line-height: 133%; }
      @media screen and (min-width: 768px) {
        .index-services .title > * {
          font-size: 48px; } }
      @media screen and (min-width: 1024px) {
        .index-services .title > * {
          font-size: 60px; } }
  .index-services-wrapper {
    margin-top: 40px; }
    @media screen and (min-width: 768px) {
      .index-services-wrapper {
        margin-top: 48px; } }
    @media screen and (min-width: 1024px) {
      .index-services-wrapper {
        margin-top: 60px; } }
    .index-services-wrapper .item {
      position: relative; }
      .index-services-wrapper .item-thumb {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 110%; }
        .index-services-wrapper .item-thumb > * {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%; }
      .index-services-wrapper .item img {
        display: block; }
      .index-services-wrapper .item .item-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 22px 20px;
        color: var(--cl-content-light, #FFF);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        transition: .3s all; }
        @media screen and (min-width: 1280px) {
          .index-services-wrapper .item .item-content {
            padding: 28px 44px; } }
        .index-services-wrapper .item .item-content .item-desc, .index-services-wrapper .item .item-content .item-more {
          opacity: 0;
          visibility: hidden;
          transform: translateY(100%); }
      .index-services-wrapper .item-name {
        position: absolute;
        right: 44px;
        bottom: 28px;
        left: 44px;
        transition: .3s all; }
        .index-services-wrapper .item-name > * {
          font-size: 20px;
          color: var(--cl-title-light, #FFF);
          line-height: 167%;
          font-weight: 700;
          text-transform: uppercase; }
          @media screen and (min-width: 768px) {
            .index-services-wrapper .item-name > * {
              font-size: 22px; } }
          @media screen and (min-width: 1024px) {
            .index-services-wrapper .item-name > * {
              font-size: 24px; } }
      .index-services-wrapper .item-desc {
        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
        margin-top: 20px;
        font-size: 16px;
        color: var(--cl-content-light, #FfF);
        line-height: 139%; }
        @media screen and (min-width: 576px) and (max-width: 680px) {
          .index-services-wrapper .item-desc {
            -webkit-line-clamp: 5; } }
        @media screen and (min-width: 768px) {
          .index-services-wrapper .item-desc {
            font-size: 18px; } }
        @media screen and (min-width: 1024px) {
          .index-services-wrapper .item-desc {
            font-size: 18px; } }
      .index-services-wrapper .item-more {
        flex: 1;
        display: flex;
        align-items: flex-end;
        margin-top: 12px;
        color: var(--cl-readmore-light, #FFF); }
      .index-services-wrapper .item:hover .item-content {
        background-color: var(--cl-blue, #000); }
        .index-services-wrapper .item:hover .item-content .item-desc, .index-services-wrapper .item:hover .item-content .item-more {
          opacity: 1;
          visibility: visible;
          transform: translateY(0%);
          transition: .3s all; }
      .index-services-wrapper .item:hover .item-name {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto; }

.index-form {
  margin-top: 54px;
  position: relative; }
  @media screen and (min-width: 768px) {
    .index-form {
      margin-top: 72px; } }
  @media screen and (min-width: 1024px) {
    .index-form {
      margin-top: 100px; } }
  .index-form-bg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    width: 100%;
    height: 100%; }
    .index-form-bg svg, .index-form-bg path {
      height: 100%;
      width: auto;
      display: none; }
    @media screen and (min-width: 768px) {
      .index-form-bg {
        clip-path: ellipse(96% 100% at 50% 0%); } }
    @media screen and (min-width: 1024px) {
      .index-form-bg {
        clip-path: ellipse(86% 100% at 50% 0%); } }
    @media screen and (min-width: 1280px) {
      .index-form-bg {
        clip-path: ellipse(70% 100% at 50% 0%); } }
    .index-form-bg img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover; }
    .index-form-bg::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--cl-blue, #000);
      opacity: .75; }
  .index-form-wrapper {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    padding-bottom: 100px;
    padding-bottom: 54px;
    padding-top: 40px; }
    @media screen and (min-width: 768px) {
      .index-form-wrapper {
        padding-bottom: 72px; } }
    @media screen and (min-width: 1024px) {
      .index-form-wrapper {
        padding-bottom: 100px; } }
    @media screen and (min-width: 768px) {
      .index-form-wrapper {
        padding-top: 52px; } }
    @media screen and (min-width: 1024px) {
      .index-form-wrapper {
        padding-top: 64px; } }
    .index-form-wrapper .title > * {
      font-size: 40px;
      font-weight: 500;
      line-height: 133%;
      color: var(--cl-title-light, #FFF); }
      @media screen and (min-width: 768px) {
        .index-form-wrapper .title > * {
          font-size: 48px; } }
      @media screen and (min-width: 1024px) {
        .index-form-wrapper .title > * {
          font-size: 60px; } }
    .index-form-wrapper .subtitle {
      margin-top: 5px; }
      .index-form-wrapper .subtitle > * {
        font-size: 24px;
        line-height: 133%;
        font-weight: 700;
        text-transform: uppercase;
        color: var(--cl-title-light, #FFF); }
        @media screen and (min-width: 768px) {
          .index-form-wrapper .subtitle > * {
            font-size: 28px; } }
        @media screen and (min-width: 1024px) {
          .index-form-wrapper .subtitle > * {
            font-size: 32px; } }
    .index-form-wrapper .form {
      width: 700px;
      max-width: 96vw;
      margin: 43px auto 0 auto;
      text-align: left;
      color: var(--cl-content-light, #FFF); }
      .index-form-wrapper .form-group {
        display: block; }
        .index-form-wrapper .form-group label {
          font-size: 15px;
          font-weight: 400;
          line-height: 150%;
          color: var(--cl-content-light, #FFF); }
          @media screen and (min-width: 768px) {
            .index-form-wrapper .form-group label {
              font-size: 16px; } }
          @media screen and (min-width: 1024px) {
            .index-form-wrapper .form-group label {
              font-size: 16px; } }
          .index-form-wrapper .form-group label a, .index-form-wrapper .form-group label span {
            color: var(--cl-red, currentColor);
            display: inline-block; }
        .index-form-wrapper .form-group input:not([type="checkbox"]):not([type="radio"]), .index-form-wrapper .form-group textarea {
          resize: none;
          width: 100%;
          background: transparent;
          border: none;
          color: var(--cl-content-light, #FFF);
          border-bottom: 1px solid currentColor;
          font-size: 15px;
          line-height: 150%;
          margin-top: -4px; }
          @media screen and (min-width: 768px) {
            .index-form-wrapper .form-group input:not([type="checkbox"]):not([type="radio"]), .index-form-wrapper .form-group textarea {
              font-size: 16px; } }
          @media screen and (min-width: 1024px) {
            .index-form-wrapper .form-group input:not([type="checkbox"]):not([type="radio"]), .index-form-wrapper .form-group textarea {
              font-size: 16px; } }
        .index-form-wrapper .form-group .checkbox-custom {
          display: inline;
          --whCb: 20px;
          line-height: 1.5;
          width: var(--whCb, 20px);
          height: var(--whCb, 20px);
          margin-right: 6px;
          position: relative; }
          .index-form-wrapper .form-group .checkbox-custom ~ label {
            display: inline; }
          .index-form-wrapper .form-group .checkbox-custom input[type="checkbox"] {
            width: 100%;
            height: 100%;
            opacity: 0;
            display: none;
            position: absolute;
            top: 0;
            left: 0; }
            .index-form-wrapper .form-group .checkbox-custom input[type="checkbox"]:checked ~ .checkmark {
              background: currentColor; }
              .index-form-wrapper .form-group .checkbox-custom input[type="checkbox"]:checked ~ .checkmark::after {
                display: block; }
          .index-form-wrapper .form-group .checkbox-custom .checkmark {
            display: inline-block;
            cursor: pointer;
            width: var(--whCb, 20px);
            height: var(--whCb, 20px);
            color: var(--cl-content-light, #FFF);
            border: 1px solid currentColor;
            background: none;
            border-radius: 4px;
            transform: translateY(29%); }
            .index-form-wrapper .form-group .checkbox-custom .checkmark::after {
              content: "";
              position: absolute;
              display: none;
              top: 46%;
              left: 50%;
              width: 36%;
              height: 64%;
              border: solid var(--cl-blue, #000);
              border-width: 0 3px 3px 0;
              transform: translate(-50%, -50%) rotate(45deg); }
          .index-form-wrapper .form-group .checkbox-custom:hover .checkmark {
            background: #ccc; }
        .index-form-wrapper .form-group button[type="submit"] {
          background: none;
          border: none;
          outline: none;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-size: 15px;
          font-weight: 400;
          line-height: 150%;
          color: var(--cl-content-light, #FFF);
          background-color: var(--cl-red, #000);
          border-radius: 10px;
          padding: 10px 20px; }
          @media screen and (min-width: 768px) {
            .index-form-wrapper .form-group button[type="submit"] {
              font-size: 16px; } }
          @media screen and (min-width: 1024px) {
            .index-form-wrapper .form-group button[type="submit"] {
              font-size: 16px; } }
          .index-form-wrapper .form-group button[type="submit"] svg {
            transition: .2s transform linear;
            margin-left: 10px; }
          .index-form-wrapper .form-group button[type="submit"]:hover {
            background-color: var(--cl-blue, #000); }
            .index-form-wrapper .form-group button[type="submit"]:hover svg {
              transform: rotate(-90deg); }

.index-news {
  margin-top: 54px; }
  @media screen and (min-width: 768px) {
    .index-news {
      margin-top: 72px; } }
  @media screen and (min-width: 1024px) {
    .index-news {
      margin-top: 100px; } }
  .index-news-top {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .index-news-top .title > * {
      font-size: 40px;
      font-weight: 500;
      line-height: 133%;
      color: var(--cl-title, #000); }
      @media screen and (min-width: 768px) {
        .index-news-top .title > * {
          font-size: 48px; } }
      @media screen and (min-width: 1024px) {
        .index-news-top .title > * {
          font-size: 60px; } }
    .index-news-top .readmore {
      margin-top: 10px;
      color: var(--cl-readmore, #000); }
  .index-news-wrapper {
    margin-top: 24px; }
    @media screen and (min-width: 768px) {
      .index-news-wrapper {
        margin-top: 40px; } }

.index-logo {
  margin-top: 54px;
  margin-bottom: 54px; }
  @media screen and (min-width: 768px) {
    .index-logo {
      margin-top: 72px; } }
  @media screen and (min-width: 1024px) {
    .index-logo {
      margin-top: 100px; } }
  @media screen and (min-width: 768px) {
    .index-logo {
      margin-bottom: 72px; } }
  @media screen and (min-width: 1024px) {
    .index-logo {
      margin-bottom: 100px; } }
  .index-logo-wrapper {
    width: 100%;
    position: relative;
    padding: 0px 10% 54px; }
    .index-logo-wrapper::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: calc(100% - 30px);
      border: 1px solid var(--cl-blue, #000);
      border-radius: 20px;
      z-index: -1; }
    @media screen and (min-width: 768px) {
      .index-logo-wrapper {
        padding: 0px 20px 40px; } }
    @media screen and (min-width: 1024px) {
      .index-logo-wrapper {
        padding: 0px 10% 54px; } }
    .index-logo-wrapper .title {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      padding: 3px 20px;
      text-align: center;
      background-color: var(--cl-blue, #000);
      color: var(--cl-title-light, #FFF);
      border-radius: 10px; }
      @media screen and (min-width: 768px) {
        .index-logo-wrapper .title {
          width: 70%; } }
      .index-logo-wrapper .title > * {
        font-size: 28px;
        font-weight: 500;
        line-height: 150%; }
        @media screen and (min-width: 768px) {
          .index-logo-wrapper .title > * {
            font-size: 32px; } }
        @media screen and (min-width: 1024px) {
          .index-logo-wrapper .title > * {
            font-size: 36px; } }
    .index-logo-wrapper .swiper {
      margin-top: 20px; }
    .index-logo-wrapper .sw-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 60px; }
      .index-logo-wrapper .sw-item > * {
        display: block;
        max-width: 90%;
        height: 100%;
        object-fit: contain; }

.contact-infor {
  --wBtnSw: 40px;
  position: relative; }
  @media screen and (min-width: 1024px) {
    .contact-infor {
      --hMap: 41.667vw; } }
  @media screen and (min-width: 1280px) {
    .contact-infor {
      --wInforPlus: 36px;
      --hInforMinus: 32px; } }
  .contact-infor__map {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    padding-top: var(--hMap, 400px); }
    .contact-infor__map > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .contact-infor-wrapper {
    background-size: cover;
    background-position: 51% 0%;
    margin-left: -2vw;
    margin-right: -2vw; }
    @media screen and (min-width: 1280px) {
      .contact-infor-wrapper {
        margin-left: 0vw;
        margin-right: 0vw; } }
    .contact-infor-wrapper > .row {
      align-items: stretch;
      flex-direction: column;
      margin-left: 2vw;
      margin-right: 2vw; }
      @media screen and (min-width: 1280px) {
        .contact-infor-wrapper > .row {
          margin-left: 0vw;
          margin-right: 0vw; } }
      @media screen and (min-width: 1280px) {
        .contact-infor-wrapper > .row {
          flex-direction: row; } }
    .contact-infor-wrapper .content {
      position: relative;
      display: block;
      background-color: var(--cl-blue, #000);
      padding: 20px 30px var(--wBtnSw, 40px) 30px; }
      @media screen and (min-width: 1280px) {
        .contact-infor-wrapper .content {
          width: calc(100% + var(--wInforPlus, 0px)); } }
      .contact-infor-wrapper .content .content__title, .contact-infor-wrapper .content h1 {
        font-size: 40px;
        line-height: 110%;
        font-weight: 500;
        color: var(--cl-title-light, #FFF);
        margin-bottom: 6px; }
        @media screen and (min-width: 768px) {
          .contact-infor-wrapper .content .content__title, .contact-infor-wrapper .content h1 {
            font-size: 48px; } }
        @media screen and (min-width: 1024px) {
          .contact-infor-wrapper .content .content__title, .contact-infor-wrapper .content h1 {
            font-size: 60px; } }
        @media screen and (min-width: 768px) {
          .contact-infor-wrapper .content .content__title, .contact-infor-wrapper .content h1 {
            margin-bottom: 12px; } }
      .contact-infor-wrapper .content .row > * {
        color: #BBC2DD;
        line-height: 166%;
        font-size: 16px; }
        @media screen and (min-width: 768px) {
          .contact-infor-wrapper .content .row > * {
            font-size: 17px; } }
        .contact-infor-wrapper .content .row > * p:first-child {
          color: #FFF; }
        .contact-infor-wrapper .content .row > * a {
          display: inline-block;
          color: currentColor; }
          .contact-infor-wrapper .content .row > * a:hover {
            color: var(--cl-red, #FFF); }
        .contact-infor-wrapper .content .row > *:first-child {
          font-size: 16px; }
          @media screen and (min-width: 768px) {
            .contact-infor-wrapper .content .row > *:first-child {
              font-size: 18px; } }
          @media screen and (min-width: 1024px) {
            .contact-infor-wrapper .content .row > *:first-child {
              font-size: 18px; } }
    .contact-infor-wrapper .desc {
      padding: 20px 0 20px 0;
      width: 100%;
      text-align: center; }
      @media screen and (min-width: 768px) {
        .contact-infor-wrapper .desc {
          width: calc(var(--wContainer) * 9 / 12); } }
      @media screen and (min-width: 1280px) {
        .contact-infor-wrapper .desc {
          width: 100%;
          text-align: left; } }
      .contact-infor-wrapper .desc > * {
        display: inline-block; }
      @media screen and (min-width: 1280px) {
        .contact-infor-wrapper .desc {
          padding: 80px;
          /* min-height: calc(100% + 40px); */
          display: flex;
          flex-direction: column;
          justify-content: center;
          min-height: 100%; }
          .contact-infor-wrapper .desc > * {
            display: block;
            margin-right: 0; } }
      .contact-infor-wrapper .desc > * {
        font-size: 24px;
        line-height: 134%;
        font-weight: 700;
        color: var(--cl-title-light);
        text-transform: uppercase; }
        @media screen and (min-width: 768px) {
          .contact-infor-wrapper .desc > * {
            font-size: 36px; } }
        @media screen and (min-width: 1024px) {
          .contact-infor-wrapper .desc > * {
            font-size: 42px; } }
        @media screen and (min-width: 1280px) {
          .contact-infor-wrapper .desc > * {
            font-size: 60px; } }
  .contact-infor .swiper-button-customs {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    padding-top: var(--hMap, 400px); }
  .contact-infor .swiper-button-next, .contact-infor .swiper-button-prev {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 50%;
    margin-top: 0;
    width: var(--wBtnSw, 40px);
    height: var(--wBtnSw, 40px);
    --iCol: 12; }
    .contact-infor .swiper-button-next::after, .contact-infor .swiper-button-prev::after {
      content: none; }
    .contact-infor .swiper-button-next [stroke]:not([stroke="none"]), .contact-infor .swiper-button-prev [stroke]:not([stroke="none"]) {
      stroke: currentColor; }
    .contact-infor .swiper-button-next [fill]:not([fill="none"]), .contact-infor .swiper-button-prev [fill]:not([fill="none"]) {
      fill: currentColor; }
    .contact-infor .swiper-button-next[aria-disabled="true"], .contact-infor .swiper-button-prev[aria-disabled="true"] {
      background-color: var(--cl-w, #FFF);
      opacity: .4;
      color: #B3B4B8; }
    .contact-infor .swiper-button-next[aria-disabled="false"], .contact-infor .swiper-button-prev[aria-disabled="false"] {
      background-color: var(--cl-w, #000);
      opacity: 1;
      color: #B3B4B8; }
    @media screen and (min-width: 768px) {
      .contact-infor .swiper-button-next, .contact-infor .swiper-button-prev {
        --iCol: 9; } }
    @media screen and (min-width: 1280px) {
      .contact-infor .swiper-button-next, .contact-infor .swiper-button-prev {
        --iCol: 5; } }
    @media screen and (min-width: 768px) {
      .contact-infor .swiper-button-next:hover, .contact-infor .swiper-button-prev:hover {
        background-color: var(--cl-blue, #000) !important;
        color: var(--cl-w) !important; } }
  .contact-infor .swiper-button-prev {
    display: none;
    left: auto;
    right: calc(2vw + var(--wBtnSw, 40px)); }
    @media screen and (min-width: 768px) {
      .contact-infor .swiper-button-prev {
        display: flex;
        right: auto;
        --colInfor: calc((var(--wContainer, 100vw) * var(--iCol, 12) / 12) + var(--wInforPlus, 0px));
        left: calc( 1vw + (var(--colInfor, 0))); } }
    @media screen and (min-width: 1280px) {
      .contact-infor .swiper-button-prev {
        right: auto;
        --colInfor: calc((var(--wContainer, 100vw) * var(--iCol, 12) / 12) + var(--wInforPlus, 0px));
        left: calc( (100% - var(--wContainer, 100vw) ) / 2 + (var(--colInfor, 0))); } }
  .contact-infor .swiper-button-next {
    right: 2vw;
    left: auto;
    display: none; }
    @media screen and (min-width: 768px) {
      .contact-infor .swiper-button-next {
        display: flex;
        right: auto;
        --colInfor: calc((var(--wContainer, 100vw) * var(--iCol, 12) / 12) + var(--wInforPlus, 0px) + var(--wBtnSw, 40px));
        left: calc( 1vw + (var(--colInfor, 0))); } }
    @media screen and (min-width: 1280px) {
      .contact-infor .swiper-button-next {
        right: auto;
        --colInfor: calc((var(--wContainer, 100vw) * var(--iCol, 12) / 12) + var(--wInforPlus, 0px) + var(--wBtnSw, 40px));
        left: calc( (100% - var(--wContainer, 100vw) ) / 2 + (var(--colInfor, 0))); } }
  .contact-infor-thumbnails {
    position: absolute;
    z-index: 1;
    top: var(--hMap, 400px);
    left: calc( ( 100% - var(--wContainer) ) / 2);
    transform: translateY(-100%);
    width: calc(var(--wContainer) * 12 / 12); }
    @media screen and (min-width: 768px) {
      .contact-infor-thumbnails {
        width: calc(var(--wContainer) * 9 / 12); } }
    @media screen and (min-width: 1280px) {
      .contact-infor-thumbnails {
        width: calc(var(--wContainer) * 5 / 12 + var(--wInforPlus)); } }
    .contact-infor-thumbnails .contact-infor-item {
      padding: 10px 8px;
      height: 40px;
      background: var(--cl-blue);
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      cursor: pointer; }
      .contact-infor-thumbnails .contact-infor-item > * {
        color: var(--cl-content-light, #FFF); }
    .contact-infor-thumbnails .swiper-slide.is-actived .contact-infor-item, .contact-infor-thumbnails .about-mv-options li.is-actived > a.swiper-slide .contact-infor-item, .about-mv-options .contact-infor-thumbnails li.is-actived > a.swiper-slide .contact-infor-item {
      background: var(--cl-red); }

.contact-form {
  margin-top: 54px; }
  @media screen and (min-width: 768px) {
    .contact-form {
      margin-top: 72px; } }
  @media screen and (min-width: 1024px) {
    .contact-form {
      margin-top: 100px; } }
  .contact-form-infor {
    height: 100%;
    background-image: url("../assets/images/contact/bg-information.jpg");
    background-size: cover;
    background-position: bottom right;
    color: var(--cl-content-light, #FFF);
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    padding: 32px 20px 40px 20px; }
    @media screen and (min-width: 768px) {
      .contact-form-infor {
        padding: 48px 80px 68px 40px; } }
    .contact-form-infor::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #1E4487;
      opacity: .5;
      z-index: 0; }
    .contact-form-infor > * {
      position: relative;
      z-index: 1; }
    .contact-form-infor h1 {
      color: var(--cl-title-light, #FFF);
      font-weight: 500;
      font-size: 40px; }
      @media screen and (min-width: 768px) {
        .contact-form-infor h1 {
          font-size: 48px; } }
      @media screen and (min-width: 1024px) {
        .contact-form-infor h1 {
          font-size: 60px; } }
    .contact-form-infor p {
      margin-top: 10px;
      font-size: 16px;
      font-weight: 400;
      color: var(--cl-content-light, #FFF); }
      @media screen and (min-width: 768px) {
        .contact-form-infor p {
          font-size: 18px; } }
      @media screen and (min-width: 1024px) {
        .contact-form-infor p {
          font-size: 18px; } }
    .contact-form-infor ul {
      margin-top: 48px;
      list-style-type: none; }
      .contact-form-infor ul > li {
        display: flex;
        align-items: center; }
        .contact-form-infor ul > li .icon {
          width: 24px;
          height: 24px;
          margin-right: 13px; }
          .contact-form-infor ul > li .icon > * {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover; }
        .contact-form-infor ul > li p {
          margin-top: 0; }
        .contact-form-infor ul > li + li {
          margin-top: 40px; }
  .contact-form .form {
    display: flex;
    height: 100%;
    align-items: center; }
    .contact-form .form-group {
      display: block; }
      .contact-form .form-group label {
        font-size: 15px;
        font-weight: 400;
        line-height: 150%;
        color: var(--cl-content, #000); }
        @media screen and (min-width: 768px) {
          .contact-form .form-group label {
            font-size: 16px; } }
        @media screen and (min-width: 1024px) {
          .contact-form .form-group label {
            font-size: 16px; } }
        .contact-form .form-group label a, .contact-form .form-group label span {
          color: var(--cl-red, currentColor); }
      .contact-form .form-group input:not([type="checkbox"]):not([type="radio"]), .contact-form .form-group textarea {
        resize: none;
        width: 100%;
        background: transparent;
        border: none;
        color: var(--cl-content, #000);
        border-bottom: 1px solid currentColor;
        font-size: 15px;
        line-height: 150%;
        margin-top: -4px; }
        @media screen and (min-width: 768px) {
          .contact-form .form-group input:not([type="checkbox"]):not([type="radio"]), .contact-form .form-group textarea {
            font-size: 16px; } }
        @media screen and (min-width: 1024px) {
          .contact-form .form-group input:not([type="checkbox"]):not([type="radio"]), .contact-form .form-group textarea {
            font-size: 16px; } }
      .contact-form .form-group .checkbox-custom {
        display: inline-block;
        --whCb: 20px;
        width: var(--whCb, 20px);
        height: var(--whCb, 20px);
        margin-right: 6px;
        position: relative; }
        .contact-form .form-group .checkbox-custom input[type="checkbox"] {
          width: 100%;
          height: 100%;
          opacity: 0;
          display: none;
          position: absolute;
          top: 0;
          left: 0; }
          .contact-form .form-group .checkbox-custom input[type="checkbox"]:checked ~ .checkmark {
            background: currentColor; }
            .contact-form .form-group .checkbox-custom input[type="checkbox"]:checked ~ .checkmark::after {
              display: block; }
        .contact-form .form-group .checkbox-custom .checkmark {
          display: inline-block;
          cursor: pointer;
          width: var(--whCb, 20px);
          height: var(--whCb, 20px);
          color: var(--cl-content, #000);
          border: 1px solid currentColor;
          background: none;
          border-radius: 4px;
          transform: translateY(29%); }
          .contact-form .form-group .checkbox-custom .checkmark::after {
            content: "";
            position: absolute;
            display: none;
            top: 46%;
            left: 50%;
            width: 36%;
            height: 64%;
            border: solid var(--cl-blue, #000);
            border-width: 0 3px 3px 0;
            transform: translate(-50%, -50%) rotate(45deg); }
        .contact-form .form-group .checkbox-custom:hover .checkmark {
          background: #ccc; }
      .contact-form .form-group button[type="submit"] {
        background: none;
        border: none;
        outline: none;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        font-weight: 400;
        line-height: 150%;
        color: var(--cl-content-light, #FFF);
        background-color: var(--cl-red, #000);
        border-radius: 10px;
        padding: 10px 20px; }
        @media screen and (min-width: 768px) {
          .contact-form .form-group button[type="submit"] {
            font-size: 16px; } }
        @media screen and (min-width: 1024px) {
          .contact-form .form-group button[type="submit"] {
            font-size: 16px; } }
        .contact-form .form-group button[type="submit"] svg {
          transition: .2s transform linear;
          margin-left: 10px; }
        .contact-form .form-group button[type="submit"]:hover {
          background-color: var(--cl-blue, #000); }
          .contact-form .form-group button[type="submit"]:hover svg {
            transform: rotate(-90deg); }

.contact-footer {
  margin-top: 54px;
  background-color: var(--cl-blue, #000); }
  @media screen and (min-width: 768px) {
    .contact-footer {
      margin-top: 72px; } }
  @media screen and (min-width: 1024px) {
    .contact-footer {
      margin-top: 100px; } }
  .contact-footer-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 60px 0 60px 0; }
    @media screen and (min-width: 768px) {
      .contact-footer-wrapper {
        flex-direction: row;
        padding: 80px 0 80px 0; } }
    @media screen and (min-width: 1024px) {
      .contact-footer-wrapper {
        padding: 80px 97px 80px 51px; } }
    .contact-footer-wrapper .content {
      display: flex;
      align-items: center;
      color: var(--cl-content-light);
      width: 100%; }
      @media screen and (min-width: 768px) {
        .contact-footer-wrapper .content {
          width: 48%; } }
      @media screen and (min-width: 1024px) {
        .contact-footer-wrapper .content {
          width: 43.5%; } }
      .contact-footer-wrapper .content__icon {
        display: block;
        margin-right: 10px;
        height: 100px;
        width: 100px; }
        @media screen and (min-width: 768px) {
          .contact-footer-wrapper .content__icon {
            min-width: 118px; } }
        .contact-footer-wrapper .content__icon img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: contain; }
      .contact-footer-wrapper .content__text h2 {
        /* title h3 pc */
        font-weight: 400;
        font-size: 20px;
        line-height: 146%;
        /* identical to box height, or 146% */
        color: var(--cl-content-light); }
        @media screen and (min-width: 768px) {
          .contact-footer-wrapper .content__text h2 {
            font-size: 22px; } }
        @media screen and (min-width: 1024px) {
          .contact-footer-wrapper .content__text h2 {
            font-size: 24px; } }
      .contact-footer-wrapper .content__text p {
        font-size: 20px;
        font-weight: 400;
        line-height: 1.0833;
        color: var(--cl-content-light); }
        @media screen and (min-width: 768px) {
          .contact-footer-wrapper .content__text p {
            font-size: 22px; } }
        @media screen and (min-width: 1024px) {
          .contact-footer-wrapper .content__text p {
            font-size: 24px; } }
  .contact-footer-form {
    display: block;
    width: 100%;
    margin-top: 20px; }
    @media screen and (min-width: 768px) {
      .contact-footer-form {
        width: auto;
        flex: 1;
        margin-top: 0;
        margin-left: 16px; } }
    .contact-footer-form .form-group {
      display: flex;
      width: 100%;
      position: relative;
      flex-direction: column; }
      @media screen and (min-width: 768px) {
        .contact-footer-form .form-group {
          flex-direction: row; } }
      .contact-footer-form .form-group ::-webkit-input-placeholder {
        /* Edge */
        color: #ffffffbb; }
      .contact-footer-form .form-group :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #ffffffbb; }
      .contact-footer-form .form-group ::placeholder {
        color: #ffffffbb; }
      .contact-footer-form .form-group input {
        display: block;
        width: 100%;
        appearance: none;
        background: none;
        outline: none;
        color: var(--cl-content-light);
        border: 1px solid rgba(255, 255, 255, 0.16);
        border-radius: 4px;
        padding: 20px 20px;
        font-size: 15px;
        line-height: 1.5; }
        @media screen and (min-width: 768px) {
          .contact-footer-form .form-group input {
            font-size: 16px; } }
        @media screen and (min-width: 1024px) {
          .contact-footer-form .form-group input {
            font-size: 16px; } }
      .contact-footer-form .form-group input[type="submit"], .contact-footer-form .form-group button[type="submit"] {
        width: 100%;
        margin-top: 16px;
        min-width: 175px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        background-color: var(--cl-red, #000);
        color: var(--cl-content-light, #FFF);
        font-size: 15px;
        line-height: 1.5; }
        @media screen and (min-width: 768px) {
          .contact-footer-form .form-group input[type="submit"], .contact-footer-form .form-group button[type="submit"] {
            position: absolute;
            top: 0;
            right: 0;
            width: auto;
            margin-top: 0; } }
        @media screen and (min-width: 768px) {
          .contact-footer-form .form-group input[type="submit"], .contact-footer-form .form-group button[type="submit"] {
            font-size: 16px; } }
        @media screen and (min-width: 1024px) {
          .contact-footer-form .form-group input[type="submit"], .contact-footer-form .form-group button[type="submit"] {
            font-size: 16px; } }

.about-introduction {
  margin-top: 40px; }
  @media screen and (min-width: 768px) {
    .about-introduction {
      margin-top: 48px; } }
  @media screen and (min-width: 1024px) {
    .about-introduction {
      margin-top: 60px; } }
  .about-introduction-wrapper {
    width: var(--wContainer3, 100%);
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    color: var(--cl-content, #000);
    line-height: 1.388889; }
    @media screen and (min-width: 768px) {
      .about-introduction-wrapper {
        font-size: 18px; } }
    @media screen and (min-width: 1024px) {
      .about-introduction-wrapper {
        font-size: 18px; } }

.about-mv {
  margin-top: 50px; }
  .about-mv-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    height: 100%; }
    @media screen and (min-width: 768px) {
      .about-mv-bg {
        height: calc(100% - 40px); } }
    .about-mv-bg img {
      display: block;
      width: 100%;
      height: 100%; }
      @media screen and (min-width: 768px) {
        .about-mv-bg img {
          clip-path: ellipse(96% 180% at 50% 180%); } }
      @media screen and (min-width: 1024px) {
        .about-mv-bg img {
          clip-path: ellipse(86% 180% at 50% 180%); } }
      @media screen and (min-width: 1280px) {
        .about-mv-bg img {
          clip-path: ellipse(72% 180% at 50% 180%); } }
    .about-mv-bg::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--cl-blue, #000);
      opacity: .5; }
      @media screen and (min-width: 768px) {
        .about-mv-bg::after {
          clip-path: ellipse(96% 180% at 50% 180%); } }
      @media screen and (min-width: 1024px) {
        .about-mv-bg::after {
          clip-path: ellipse(86% 180% at 50% 180%); } }
      @media screen and (min-width: 1280px) {
        .about-mv-bg::after {
          clip-path: ellipse(72% 180% at 50% 180%); } }
  .about-mv-container {
    position: relative;
    padding-top: 54px; }
    @media screen and (min-width: 768px) {
      .about-mv-container {
        padding-top: 72px; } }
    @media screen and (min-width: 1024px) {
      .about-mv-container {
        padding-top: 100px; } }
    @media screen and (max-width: 767px) {
      .about-mv-container {
        padding-bottom: 54px; } }
  @media screen and (max-width: 767px) and (min-width: 768px) {
    .about-mv-container {
      padding-bottom: 72px; } }
  @media screen and (max-width: 767px) and (min-width: 1024px) {
    .about-mv-container {
      padding-bottom: 100px; } }
    .about-mv-container h1, .about-mv-container h2, .about-mv-container h3 {
      text-align: center;
      color: var(--cl-title-light, #fff);
      font-size: 40px;
      line-height: 1.33333; }
      @media screen and (min-width: 768px) {
        .about-mv-container h1, .about-mv-container h2, .about-mv-container h3 {
          font-size: 48px; } }
      @media screen and (min-width: 1024px) {
        .about-mv-container h1, .about-mv-container h2, .about-mv-container h3 {
          font-size: 60px; } }
  .about-mv-options {
    margin-top: 40px;
    display: flex;
    list-style-type: none;
    justify-content: center; }
    @media screen and (min-width: 768px) {
      .about-mv-options {
        margin-top: 48px; } }
    @media screen and (min-width: 1024px) {
      .about-mv-options {
        margin-top: 60px; } }
    .about-mv-options li + li {
      margin-left: 33px; }
    @media screen and (max-width: 767px) {
      .about-mv-options {
        flex-direction: column; }
        .about-mv-options li a {
          width: 100%; }
        .about-mv-options li + li {
          margin-top: 20px;
          margin-left: 0; } }
  .about-mv-wrapper {
    margin-top: 40px;
    display: flex;
    width: var(--wContainer2, 100%);
    margin-left: auto;
    margin-right: auto;
    display: none; }
    @media screen and (min-width: 768px) {
      .about-mv-wrapper {
        margin-top: 48px; } }
    @media screen and (min-width: 1024px) {
      .about-mv-wrapper {
        margin-top: 60px; } }
    .about-mv-wrapper.is-actived, .about-mv-options li.is-actived > a.about-mv-wrapper {
      display: flex; }
    .about-mv-wrapper .content {
      display: block;
      width: 100%; }
      @media screen and (max-width: 767px) {
        .about-mv-wrapper .content {
          display: flex;
          flex-direction: column; } }
      .about-mv-wrapper .content__title {
        order: 1; }
        .about-mv-wrapper .content__title > * {
          font-weight: 500;
          font-size: 40px;
          line-height: 1.333333;
          color: var(--cl-title, #000); }
          @media screen and (min-width: 768px) {
            .about-mv-wrapper .content__title > * {
              font-size: 48px; } }
          @media screen and (min-width: 1024px) {
            .about-mv-wrapper .content__title > * {
              font-size: 60px; } }
      .about-mv-wrapper .content__desc {
        order: 3;
        margin-top: 20px;
        font-size: 16px;
        line-height: 1.3888889;
        color: var(--cl-content, #000); }
        @media screen and (min-width: 768px) {
          .about-mv-wrapper .content__desc {
            font-size: 18px; } }
        @media screen and (min-width: 1024px) {
          .about-mv-wrapper .content__desc {
            font-size: 18px; } }
        .about-mv-wrapper .content__desc ul {
          padding-left: 16px; }
    .about-mv-wrapper .thumb {
      order: 2;
      margin-top: 8px; }
      @media screen and (min-width: 768px) {
        .about-mv-wrapper .thumb {
          float: right;
          flex: 0 0 48%;
          max-width: 48%;
          max-height: var(--varHeight, auto);
          margin: 0 0 10px 20px; } }
      @media screen and (min-width: 1024px) {
        .about-mv-wrapper .thumb {
          flex: 0 0 34.8%;
          max-width: 34.8%;
          margin: 0 0 10px 60px; } }
      .about-mv-wrapper .thumb img {
        display: block; }

.about-certificates {
  margin-top: 54px;
  margin-bottom: 54px;
  min-height: 400px;
  padding-top: 60px;
  position: relative; }
  @media screen and (min-width: 768px) {
    .about-certificates {
      margin-top: 72px; } }
  @media screen and (min-width: 1024px) {
    .about-certificates {
      margin-top: 100px; } }
  @media screen and (min-width: 768px) {
    .about-certificates {
      margin-bottom: 72px; } }
  @media screen and (min-width: 1024px) {
    .about-certificates {
      margin-bottom: 100px; } }
  .about-certificates-bg {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: var(--wContainer, 100%); }
  .about-certificates .container + .container {
    margin-top: 54px; }
    @media screen and (min-width: 768px) {
      .about-certificates .container + .container {
        margin-top: 72px; } }
    @media screen and (min-width: 1024px) {
      .about-certificates .container + .container {
        margin-top: 100px; } }
  .about-certificates-wrapper {
    position: relative;
    z-index: 1; }
    .about-certificates-wrapper .title > * {
      font-size: 40px;
      font-weight: 500;
      line-height: 1.3333333;
      color: var(--cl-title, #000); }
      @media screen and (min-width: 768px) {
        .about-certificates-wrapper .title > * {
          font-size: 48px; } }
      @media screen and (min-width: 1024px) {
        .about-certificates-wrapper .title > * {
          font-size: 60px; } }
    .about-certificates-wrapper .swiper {
      padding-top: 28px;
      padding-bottom: 40px;
      padding-left: 6px;
      padding-right: 6px;
      margin-left: -6px;
      margin-right: -6px; }
      @media screen and (min-width: 768px) {
        .about-certificates-wrapper .swiper {
          padding-top: 32px; } }
      @media screen and (min-width: 1024px) {
        .about-certificates-wrapper .swiper {
          padding-top: 40px; } }
      @media screen and (min-width: 768px) {
        .about-certificates-wrapper .swiper {
          padding-bottom: 48px; } }
      @media screen and (min-width: 1024px) {
        .about-certificates-wrapper .swiper {
          padding-bottom: 60px; } }
      .about-certificates-wrapper .swiper .swiper-slide a, .about-certificates-wrapper .swiper .swiper-slide img {
        display: block;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25); }
      .about-certificates-wrapper .swiper .swiper-pagination {
        text-align: left; }
        .about-certificates-wrapper .swiper .swiper-pagination-bullet {
          --swiper-pagination-bullet-size: 10px;
          --swiper-pagination-bullet-inactive-color: #D9D9D9;
          --swiper-pagination-color: var(--cl-red, #000);
          --swiper-pagination-bullet-inactive-opacity: .8;
          border-radius: 0; }

.services-list {
  margin-top: 40px;
  margin-bottom: 54px; }
  @media screen and (min-width: 768px) {
    .services-list {
      margin-top: 48px; } }
  @media screen and (min-width: 1024px) {
    .services-list {
      margin-top: 60px; } }
  @media screen and (min-width: 768px) {
    .services-list {
      margin-bottom: 72px; } }
  @media screen and (min-width: 1024px) {
    .services-list {
      margin-bottom: 100px; } }
  .services-list .service-item {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    height: 100%;
    flex-direction: column; }
    .services-list .service-item-head {
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column; }
      .services-list .service-item-head .head-bg {
        position: absolute;
        z-index: 1;
        top: 0%;
        left: 0;
        width: 100%;
        height: 0;
        padding-top: 73.66%;
        transition: .3s all linear; }
        .services-list .service-item-head .head-bg img, .services-list .service-item-head .head-bg svg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: .3s all linear; }
        .services-list .service-item-head .head-bg::after {
          content: "";
          position: absolute;
          --varside: 32px;
          top: var(--varside, 0px);
          left: var(--varside, 0px);
          bottom: var(--varside, 0px);
          right: var(--varside, 0px);
          background-color: var(--cl-blue, #000);
          opacity: 0.8;
          transition: .3s all linear; }
      .services-list .service-item-head .head-content {
        position: relative;
        z-index: 1;
        width: 100%;
        padding-top: 73.66%; }
      .services-list .service-item-head .head-name {
        position: absolute;
        z-index: 1;
        top: 0%;
        left: 0;
        width: 100%;
        height: 0;
        padding-top: 73.66%; }
        .services-list .service-item-head .head-name__wrapper {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center; }
          .services-list .service-item-head .head-name__wrapper > * {
            color: var(--cl-title-light, #FFF);
            margin-top: 10px;
            font-size: 20px;
            font-weight: 700;
            line-height: 1.666667;
            text-transform: uppercase; }
            @media screen and (min-width: 768px) {
              .services-list .service-item-head .head-name__wrapper > * {
                font-size: 22px; } }
            @media screen and (min-width: 1024px) {
              .services-list .service-item-head .head-name__wrapper > * {
                font-size: 24px; } }
      .services-list .service-item-head .head-desc {
        flex: 1;
        padding: 25px 32px;
        font-size: 16px;
        color: var(--cl-content, #000);
        line-height: 1.388889;
        text-align: center; }
        @media screen and (min-width: 768px) {
          .services-list .service-item-head .head-desc {
            font-size: 18px; } }
        @media screen and (min-width: 1024px) {
          .services-list .service-item-head .head-desc {
            font-size: 18px; } }
    .services-list .service-item-more {
      padding: 25px 32px;
      position: relative;
      text-align: center;
      transition: .3s all ease; }
      .services-list .service-item-more a {
        text-align: center;
        justify-content: center; }
      .services-list .service-item-more::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: calc(100% - 32px - 32px);
        transform: translateX(-50%);
        height: 0;
        border-top: 1px solid #DDDDDD;
        transition: .3s all ease; }
      .services-list .service-item-more::after {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 0;
        height: 100%;
        transform: translateX(-50%);
        background-color: var(--cl-red, #000);
        transition: .3s all ease;
        z-index: -1; }
    .services-list .service-item:hover .service-item-head .head-bg {
      height: 100%; }
      .services-list .service-item:hover .service-item-head .head-bg::after {
        --varside: 0; }
    .services-list .service-item:hover .service-item-head .head-desc {
      color: var(--cl-content-light, #FFF); }
    .services-list .service-item:hover .service-item-more {
      --cl-hover: var(--cl-content-light, #FFF);
      color: var(--cl-content-light, #FFF); }
      .services-list .service-item:hover .service-item-more::before, .services-list .service-item:hover .service-item-more::after {
        border-top: 0px;
        width: 100%; }

.news-list {
  margin-top: 40px;
  margin-bottom: 54px;
  --sizeLoader: 10px; }
  @media screen and (min-width: 768px) {
    .news-list {
      margin-top: 48px; } }
  @media screen and (min-width: 1024px) {
    .news-list {
      margin-top: 60px; } }
  @media screen and (min-width: 768px) {
    .news-list {
      margin-bottom: 72px; } }
  @media screen and (min-width: 1024px) {
    .news-list {
      margin-bottom: 100px; } }
  .news-list-wrapper ul {
    list-style-type: none; }
  .news-list .item-thumb {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 75%;
    overflow: hidden; }
    .news-list .item-thumb > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: .3s linear transform; }
  .news-list .item img {
    display: block; }
  .news-list .item-content__desc {
    margin-top: 20px;
    text-align: center; }
    .news-list .item-content__desc > * {
      font-size: 20px;
      font-weight: 500;
      line-height: 108%;
      color: var(--cl-blue, #000); }
      @media screen and (min-width: 768px) {
        .news-list .item-content__desc > * {
          font-size: 22px; } }
      @media screen and (min-width: 1024px) {
        .news-list .item-content__desc > * {
          font-size: 24px; } }
  .news-list .item:hover .item-thumb > * {
    transform: scale(1.2); }
  .news-list .item:hover .item-content__desc > * {
    color: var(--cl-red, #000); }

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translate3d(0, calc(-1 * var(--sizeLoader, 16px)), 0); } }
  .news-list .loadmore {
    margin-top: 40px;
    text-align: center;
    color: var(--cl-blue, #000);
    font-size: 16px;
    line-height: 1.38888; }
    @media screen and (min-width: 768px) {
      .news-list .loadmore {
        font-size: 18px; } }
    @media screen and (min-width: 1024px) {
      .news-list .loadmore {
        font-size: 18px; } }
    .news-list .loadmore:hover {
      color: var(--cl-red, #000); }
    .news-list .loadmore.loading {
      pointer-events: none;
      cursor: none;
      display: flex;
      justify-content: center; }
      .news-list .loadmore.loading::after, .news-list .loadmore.loading::before {
        content: ""; }
      .news-list .loadmore.loading > *, .news-list .loadmore.loading::after, .news-list .loadmore.loading::before {
        width: var(--sizeLoader, 16px);
        height: var(--sizeLoader, 16px);
        background: #8385aa;
        border-radius: 50%;
        animation: bouncing-loader 0.6s infinite alternate;
        color: transparent;
        margin: 0 0.2rem; }
      .news-list .loadmore.loading > a {
        animation-delay: 0.2s; }
      .news-list .loadmore.loading::after {
        animation-delay: 0.4s; }

.tools-list {
  margin-top: 40px;
  margin-bottom: 54px; }
  @media screen and (min-width: 768px) {
    .tools-list {
      margin-top: 48px; } }
  @media screen and (min-width: 1024px) {
    .tools-list {
      margin-top: 60px; } }
  @media screen and (min-width: 768px) {
    .tools-list {
      margin-bottom: 72px; } }
  @media screen and (min-width: 1024px) {
    .tools-list {
      margin-bottom: 100px; } }
  .tools-list-wrapper {
    background-size: cover;
    padding: 16px;
    border-radius: 10px;
    position: relative; }
    @media screen and (min-width: 768px) {
      .tools-list-wrapper {
        padding: 27px 32px; } }
    .tools-list-wrapper::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--cl-blue, #000);
      opacity: .51;
      border-radius: 10px; }
    .tools-list-wrapper > * {
      position: relative;
      z-index: 1; }
  .tools-list .tool-item {
    background-color: var(--cl-w, #FFF);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 20px;
    transition: .3s all linear;
    position: relative; }
    @media screen and (min-width: 768px) {
      .tools-list .tool-item {
        padding: 36px; } }
    .tools-list .tool-item > * {
      position: relative;
      z-index: 1; }
    .tools-list .tool-item::after {
      content: "";
      z-index: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--cl-blue, #FFF);
      transition: .3s all linear;
      clip-path: circle(0% at 50% 83px); }
      @media screen and (min-width: 768px) {
        .tools-list .tool-item::after {
          clip-path: circle(0% at 50% 109px); } }
    .tools-list .tool-item__icon {
      width: 146px;
      height: 146px;
      background-color: var(--cl-blue, #000);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      overflow: hidden;
      margin-bottom: 20px;
      transition: .3s all linear; }
      .tools-list .tool-item__icon img, .tools-list .tool-item__icon svg {
        width: auto;
        max-width: 86%;
        max-height: 90%;
        object-fit: contain; }
    .tools-list .tool-item__name {
      flex: 1;
      text-align: center; }
      .tools-list .tool-item__name > * {
        font-size: 20px;
        color: var(--cl-blue, #000);
        font-weight: 700;
        line-height: 1.6666667;
        text-transform: uppercase; }
        @media screen and (min-width: 768px) {
          .tools-list .tool-item__name > * {
            font-size: 22px; } }
        @media screen and (min-width: 1024px) {
          .tools-list .tool-item__name > * {
            font-size: 24px; } }
    .tools-list .tool-item:hover::after {
      clip-path: circle(100% at 50% 83px); }
      @media screen and (min-width: 768px) {
        .tools-list .tool-item:hover::after {
          clip-path: circle(100% at 50% 109px); } }
    .tools-list .tool-item:hover .tool-item__icon {
      background-color: var(--cl-w, #FFF); }
    .tools-list .tool-item:hover .tool-item__name > * {
      color: var(--cl-w, #FFF); }

.tools-category {
  margin-top: 40px;
  margin-bottom: 54px; }
  @media screen and (min-width: 768px) {
    .tools-category {
      margin-top: 48px; } }
  @media screen and (min-width: 1024px) {
    .tools-category {
      margin-top: 60px; } }
  @media screen and (min-width: 768px) {
    .tools-category {
      margin-bottom: 72px; } }
  @media screen and (min-width: 1024px) {
    .tools-category {
      margin-bottom: 100px; } }
  .tools-category-wrapper .content:not([class="writeux"]) ul {
    list-style-type: none; }
  .tools-category-item {
    position: relative;
    width: 100%;
    display: block; }
    .tools-category-item .thumbnails {
      position: relative;
      width: 100%;
      height: 0;
      padding-top: 71%;
      overflow: hidden; }
      .tools-category-item .thumbnails > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        transition: .3s transform ease; }
      .tools-category-item .thumbnails::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--cl-blue, #000);
        opacity: 0;
        transition: .3s transform linear; }
    .tools-category-item .name {
      z-index: 1;
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 20px;
      background-color: var(--cl-blue, #000);
      color: var(--cl-title-light, #FFF);
      text-align: center; }
      .tools-category-item .name > * {
        font-size: 20px;
        line-height: 1.6666667;
        font-weight: 700;
        text-transform: uppercase;
        color: inherit; }
        @media screen and (min-width: 768px) {
          .tools-category-item .name > * {
            font-size: 22px; } }
        @media screen and (min-width: 1024px) {
          .tools-category-item .name > * {
            font-size: 24px; } }
    .tools-category-item:hover .thumbnails > * {
      transform: scale(1.16); }
    .tools-category-item:hover .thumbnails::after {
      opacity: .5; }
    .tools-category-item:hover .name {
      background-color: var(--cl-red, #000); }
    .tools-category-item.small .thumbnails {
      padding-top: 97.21%; }
    .tools-category-item.small .name {
      padding: 8px 12px; }

#accountPage .breadcrumbs-list {
  justify-content: flex-start; }

.account-section {
  margin-top: 54px;
  margin-bottom: 54px; }
  @media screen and (min-width: 768px) {
    .account-section {
      margin-top: 72px; } }
  @media screen and (min-width: 1024px) {
    .account-section {
      margin-top: 100px; } }
  @media screen and (min-width: 768px) {
    .account-section {
      margin-bottom: 72px; } }
  @media screen and (min-width: 1024px) {
    .account-section {
      margin-bottom: 100px; } }
  .account-section__title {
    margin-bottom: 28px;
    border-bottom: 1px solid #000;
    padding-bottom: 28px; }
    @media screen and (min-width: 768px) {
      .account-section__title {
        margin-bottom: 32px; } }
    @media screen and (min-width: 1024px) {
      .account-section__title {
        margin-bottom: 40px; } }
    @media screen and (min-width: 768px) {
      .account-section__title {
        padding-bottom: 32px; } }
    @media screen and (min-width: 1024px) {
      .account-section__title {
        padding-bottom: 40px; } }
  .account-section-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media screen and (min-width: 1024px) {
      .account-section-wrapper {
        align-items: flex-start;
        flex-direction: row; } }
  .account-section-sidebar {
    display: inline-block;
    flex: 0 0 auto;
    padding: 20px;
    border: 1px solid #666666;
    border-radius: 10px; }
    @media screen and (min-width: 1024px) {
      .account-section-sidebar {
        max-width: 50%; } }
    .account-section-sidebar .sidebar-top {
      font-size: 15px;
      color: #666666; }
      @media screen and (min-width: 768px) {
        .account-section-sidebar .sidebar-top {
          font-size: 16px; } }
      @media screen and (min-width: 1024px) {
        .account-section-sidebar .sidebar-top {
          font-size: 16px; } }
      .account-section-sidebar .sidebar-top .email {
        font-size: 18px;
        font-weight: 500;
        color: #111111; }
        @media screen and (min-width: 768px) {
          .account-section-sidebar .sidebar-top .email {
            font-size: 20px; } }
        @media screen and (min-width: 1024px) {
          .account-section-sidebar .sidebar-top .email {
            font-size: 20px; } }
      .account-section-sidebar .sidebar-top > *:not(:first-child) {
        margin-top: 10px; }
    .account-section-sidebar .sidebar-list {
      margin-top: 20px;
      padding-top: 18px;
      border-top: 1px solid #000;
      list-style-type: none; }
      .account-section-sidebar .sidebar-list li + li {
        margin-top: 20px; }
      .account-section-sidebar .sidebar-list li a {
        display: flex;
        align-items: center;
        color: var(--cl-b, #000);
        white-space: nowrap; }
        .account-section-sidebar .sidebar-list li a svg, .account-section-sidebar .sidebar-list li a img {
          object-fit: contain;
          height: 20px;
          margin-right: 15px; }
        .account-section-sidebar .sidebar-list li a [stroke]:not([stroke="none"]) {
          stroke: currentColor; }
        .account-section-sidebar .sidebar-list li a [fill]:not([fill="none"]) {
          fill: currentColor; }
        .account-section-sidebar .sidebar-list li a:hover {
          color: var(--cl-red, #000); }
      .account-section-sidebar .sidebar-list li.is-actived a {
        color: var(--cl-red, #000); }
  .account-section-form {
    flex: 1;
    max-width: 100%;
    padding: 30px 20px;
    margin-top: 20px;
    border: 1px solid #666666;
    border-radius: 10px; }
    @media screen and (min-width: 1024px) {
      .account-section-form {
        margin-top: 0;
        margin-left: 60px;
        padding: 60px 40px; } }
    @media screen and (min-width: 1440px) {
      .account-section-form {
        padding: 60px 132px; } }
    .account-section-form .group-input {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      color: var(--cl-title, #111111); }
      .account-section-form .group-input label {
        order: 1;
        font-size: 18px;
        white-space: nowrap;
        width: 100%; }
        @media screen and (min-width: 768px) {
          .account-section-form .group-input label {
            font-size: 20px; } }
        @media screen and (min-width: 1024px) {
          .account-section-form .group-input label {
            font-size: 20px; } }
        @media screen and (min-width: 768px) {
          .account-section-form .group-input label {
            width: var(--wLabel, auto); } }
        .account-section-form .group-input label span {
          color: var(--cl-red, red); }
      .account-section-form .group-input textarea, .account-section-form .group-input input {
        order: 2;
        resize: none;
        appearance: none;
        outline: none;
        background: none;
        border: 1px solid #EEEEEE;
        color: #666666;
        background: #F9F9F9;
        border-radius: 4px;
        flex: 0 0 100%;
        width: 100%;
        max-width: 100%;
        margin-top: 8px;
        line-height: 25px;
        padding: 8px 15px; }
        .account-section-form .group-input textarea:focus ~ label, .account-section-form .group-input input:focus ~ label {
          color: var(--cl-red, currentColor); }
    .account-section-form .forgotpw {
      text-align: center;
      color: var(--cl-blue, #000); }
      .account-section-form .forgotpw a:hover, .account-section-form .forgotpw a:focus {
        color: var(--cl-red, #000); }
    .account-section-form .group-submit {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column; }
      @media screen and (min-width: 768px) {
        .account-section-form .group-submit input, .account-section-form .group-submit button {
          margin-top: 20px; } }
      .account-section-form .group-submit .forgotpw {
        margin-bottom: 20px; }
        .account-section-form .group-submit .forgotpw ~ input, .account-section-form .group-submit .forgotpw button {
          margin-top: 0px; }
      .account-section-form .group-submit input, .account-section-form .group-submit button {
        appearance: none;
        outline: none;
        background: none;
        border: none;
        border-radius: 8px;
        width: 312px;
        max-width: 100%;
        padding: 13px 20px;
        color: var(--cl-content-light, #000);
        background-color: var(--cl-red, #FFF); }

#trackTracePage .breadcrumbs-list {
  justify-content: flex-start; }

.tracktrace-searches {
  margin-top: 54px;
  margin-bottom: 54px; }
  @media screen and (min-width: 768px) {
    .tracktrace-searches {
      margin-top: 72px; } }
  @media screen and (min-width: 1024px) {
    .tracktrace-searches {
      margin-top: 100px; } }
  @media screen and (min-width: 768px) {
    .tracktrace-searches {
      margin-bottom: 72px; } }
  @media screen and (min-width: 1024px) {
    .tracktrace-searches {
      margin-bottom: 100px; } }
  .tracktrace-searches-wrapper {
    margin-top: 16px; }
    .tracktrace-searches-wrapper .wrapper-select {
      position: relative;
      border-radius: 10px;
      padding: 44px 33px; }
      .tracktrace-searches-wrapper .wrapper-select::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--cl-blue, #000);
        opacity: .76;
        border-radius: inherit; }
      .tracktrace-searches-wrapper .wrapper-select > * {
        position: relative;
        z-index: 1; }
      .tracktrace-searches-wrapper .wrapper-select .item {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        padding: 30px 20px;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.4;
        text-transform: uppercase;
        background-color: var(--cl-blue, #000);
        color: var(--cl-content-light, #FFF);
        box-shadow: -5px 6px 20px rgba(0, 0, 0, 0.15); }
        @media screen and (min-width: 768px) {
          .tracktrace-searches-wrapper .wrapper-select .item {
            font-size: 16px; } }
        @media screen and (min-width: 1024px) {
          .tracktrace-searches-wrapper .wrapper-select .item {
            font-size: 16px; } }
        .tracktrace-searches-wrapper .wrapper-select .item:hover, .tracktrace-searches-wrapper .wrapper-select .item.is-actived, .tracktrace-searches-wrapper .wrapper-select .about-mv-options li.is-actived > a.item, .about-mv-options .tracktrace-searches-wrapper .wrapper-select li.is-actived > a.item {
          background: var(--cl-w, #FFF);
          color: var(--cl-blue, #000); }
    .tracktrace-searches-wrapper .wrapper-option .option-item {
      margin-top: 50px;
      display: none; }
      .tracktrace-searches-wrapper .wrapper-option .option-item.is-actived, .tracktrace-searches-wrapper .wrapper-option .about-mv-options li.is-actived > a.option-item, .about-mv-options .tracktrace-searches-wrapper .wrapper-option li.is-actived > a.option-item {
        display: block; }
      .tracktrace-searches-wrapper .wrapper-option .option-item form .group-input input, .tracktrace-searches-wrapper .wrapper-option .option-item form .group-input textarea {
        resize: none;
        appearance: none;
        background: none;
        outline: none;
        border: none;
        padding: 20px;
        height: 60px;
        font-size: 15px;
        color: #171717;
        font-weight: 600;
        text-transform: uppercase;
        background: #FFF;
        border: 1px solid #BFBFBF;
        border-radius: 4px; }
        @media screen and (min-width: 768px) {
          .tracktrace-searches-wrapper .wrapper-option .option-item form .group-input input, .tracktrace-searches-wrapper .wrapper-option .option-item form .group-input textarea {
            font-size: 16px; } }
        @media screen and (min-width: 1024px) {
          .tracktrace-searches-wrapper .wrapper-option .option-item form .group-input input, .tracktrace-searches-wrapper .wrapper-option .option-item form .group-input textarea {
            font-size: 16px; } }
      .tracktrace-searches-wrapper .wrapper-option .option-item form .group-submit, .tracktrace-searches-wrapper .wrapper-option .option-item form .group-input {
        height: 100%;
        display: flex;
        flex-direction: column; }
        .tracktrace-searches-wrapper .wrapper-option .option-item form .group-submit label, .tracktrace-searches-wrapper .wrapper-option .option-item form .group-input label {
          margin-bottom: 10px;
          font-size: 20px;
          font-weight: 400;
          color: var(--cl-title, #000); }
          @media screen and (min-width: 768px) {
            .tracktrace-searches-wrapper .wrapper-option .option-item form .group-submit label, .tracktrace-searches-wrapper .wrapper-option .option-item form .group-input label {
              margin-bottom: 16px; } }
          @media screen and (min-width: 768px) {
            .tracktrace-searches-wrapper .wrapper-option .option-item form .group-submit label, .tracktrace-searches-wrapper .wrapper-option .option-item form .group-input label {
              font-size: 22px; } }
          @media screen and (min-width: 1024px) {
            .tracktrace-searches-wrapper .wrapper-option .option-item form .group-submit label, .tracktrace-searches-wrapper .wrapper-option .option-item form .group-input label {
              font-size: 24px; } }
          .tracktrace-searches-wrapper .wrapper-option .option-item form .group-submit label span, .tracktrace-searches-wrapper .wrapper-option .option-item form .group-input label span {
            color: var(--cl-red, currentColor); }
      .tracktrace-searches-wrapper .wrapper-option .option-item form .group-submit {
        display: block; }
        .tracktrace-searches-wrapper .wrapper-option .option-item form .group-submit label {
          width: 100%;
          display: none; }
          @media screen and (min-width: 1024px) {
            .tracktrace-searches-wrapper .wrapper-option .option-item form .group-submit label {
              display: block; } }
        .tracktrace-searches-wrapper .wrapper-option .option-item form .group-submit input[type=submit], .tracktrace-searches-wrapper .wrapper-option .option-item form .group-submit button[type="submit"] {
          appearance: none;
          background: none;
          outline: none;
          border: none;
          width: auto;
          flex: 0 0 auto;
          height: 60px;
          padding: 20px;
          min-width: 175px;
          max-width: 100%;
          width: 100%;
          border-radius: 10px;
          font-size: 15px;
          font-weight: 500;
          background: var(--cl-red, #000);
          color: #FFF; }
          @media screen and (min-width: 576px) {
            .tracktrace-searches-wrapper .wrapper-option .option-item form .group-submit input[type=submit], .tracktrace-searches-wrapper .wrapper-option .option-item form .group-submit button[type="submit"] {
              width: auto; } }
          @media screen and (min-width: 768px) {
            .tracktrace-searches-wrapper .wrapper-option .option-item form .group-submit input[type=submit], .tracktrace-searches-wrapper .wrapper-option .option-item form .group-submit button[type="submit"] {
              font-size: 16px; } }
          @media screen and (min-width: 1024px) {
            .tracktrace-searches-wrapper .wrapper-option .option-item form .group-submit input[type=submit], .tracktrace-searches-wrapper .wrapper-option .option-item form .group-submit button[type="submit"] {
              font-size: 16px; } }
      .tracktrace-searches-wrapper .wrapper-option .option-item-results {
        margin-top: 40px;
        list-style-type: none;
        position: relative; }
        @media screen and (min-width: 768px) {
          .tracktrace-searches-wrapper .wrapper-option .option-item-results {
            margin-top: 48px; } }
        @media screen and (min-width: 1024px) {
          .tracktrace-searches-wrapper .wrapper-option .option-item-results {
            margin-top: 60px; } }
        .tracktrace-searches-wrapper .wrapper-option .option-item-results > ul {
          list-style-type: none; }
        .tracktrace-searches-wrapper .wrapper-option .option-item-results .result-item-name {
          display: flex;
          justify-content: space-between;
          background-color: var(--cl-blue, #000);
          color: var(--cl-title-light, #FFF);
          border-radius: 8px; }
          .tracktrace-searches-wrapper .wrapper-option .option-item-results .result-item-name > * {
            color: currentColor;
            padding: 17px 32px 17px 32px;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.33333; }
            @media screen and (min-width: 768px) {
              .tracktrace-searches-wrapper .wrapper-option .option-item-results .result-item-name > * {
                font-size: 18px; } }
            @media screen and (min-width: 1024px) {
              .tracktrace-searches-wrapper .wrapper-option .option-item-results .result-item-name > * {
                font-size: 18px; } }
          .tracktrace-searches-wrapper .wrapper-option .option-item-results .result-item-name .icon {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center; }
            .tracktrace-searches-wrapper .wrapper-option .option-item-results .result-item-name .icon svg {
              transform: rotate(180deg);
              transition: .2s all ease; }
          .tracktrace-searches-wrapper .wrapper-option .option-item-results .result-item-name [stroke]:not([stroke="none"]) {
            stroke: currentColor; }
          .tracktrace-searches-wrapper .wrapper-option .option-item-results .result-item-name [fill]:not([fill="none"]) {
            fill: currentColor; }
          .tracktrace-searches-wrapper .wrapper-option .option-item-results .result-item-name.is-actived .icon svg, .tracktrace-searches-wrapper .wrapper-option .option-item-results .about-mv-options li.is-actived > a.result-item-name .icon svg, .about-mv-options .tracktrace-searches-wrapper .wrapper-option .option-item-results li.is-actived > a.result-item-name .icon svg {
            transform: rotate(0deg); }
          .tracktrace-searches-wrapper .wrapper-option .option-item-results .result-item-name.is-actived ~ .result-item-content, .tracktrace-searches-wrapper .wrapper-option .option-item-results .about-mv-options li.is-actived > a.result-item-name ~ .result-item-content, .about-mv-options .tracktrace-searches-wrapper .wrapper-option .option-item-results li.is-actived > a.result-item-name ~ .result-item-content {
            display: block; }
        .tracktrace-searches-wrapper .wrapper-option .option-item-results .result-item-content {
          display: none;
          margin-top: 30px; }
        .tracktrace-searches-wrapper .wrapper-option .option-item-results li + li, .tracktrace-searches-wrapper .wrapper-option .option-item-results .result-item + .result-item {
          margin-top: 40px; }
          @media screen and (min-width: 768px) {
            .tracktrace-searches-wrapper .wrapper-option .option-item-results li + li, .tracktrace-searches-wrapper .wrapper-option .option-item-results .result-item + .result-item {
              margin-top: 48px; } }
          @media screen and (min-width: 1024px) {
            .tracktrace-searches-wrapper .wrapper-option .option-item-results li + li, .tracktrace-searches-wrapper .wrapper-option .option-item-results .result-item + .result-item {
              margin-top: 60px; } }
        .tracktrace-searches-wrapper .wrapper-option .option-item-results > * {
          font-size: 16px;
          color: var(--cl-content, #000);
          font-weight: 500; }
          @media screen and (min-width: 768px) {
            .tracktrace-searches-wrapper .wrapper-option .option-item-results > * {
              font-size: 18px; } }
          @media screen and (min-width: 1024px) {
            .tracktrace-searches-wrapper .wrapper-option .option-item-results > * {
              font-size: 18px; } }
          .tracktrace-searches-wrapper .wrapper-option .option-item-results > * > a {
            color: var(--cl-blue, currentColor);
            text-decoration: underline; }
          .tracktrace-searches-wrapper .wrapper-option .option-item-results > *:not(:first-child) {
            margin-top: 10px; }
