.index-intro{
    padding-top: 20px;
    @include rpmin(md){
        padding-top: 34px;
    }

    overflow: hidden;

    &-wrapper{
        background-color: var(--cl-w, #FFF);
        position: relative;

        --timerEffect: 10s;

        // @keyframes textTranslate_1 {
        //     0%, 20%, 80%, 100%{
        //         transform: translateX(0%);
        //     }
    
        //     30%, 50%, 70%{
        //         transform: translateX(-8%);
        //     }
        // }
        // @keyframes textTranslate_2 {
        //     0%, 20%, 80%, 100%{
        //         transform: translateX(0%);
        //     }
    
        //     30%, 50%, 70%{
        //         transform: translateX(14%);
        //     }
        // }
        // @keyframes textTranslate_3 {
        //     0%, 20%, 80%, 100%{
        //         transform: translateX(0%);
        //     }
    
        //     30%, 50%, 70%{
        //         transform: translateX(-8%);
        //     }
        // }

        @include rpmin(md){
            @keyframes textTranslate_1 {
                0%, 20%, 80%, 100%{
                    transform: translateX(0%);
                }
        
                30%, 50%, 70%{
                    transform: translateX(-8%);
                }
            }
            @keyframes textTranslate_2 {
                0%, 20%, 80%, 100%{
                    transform: translateX(0%);
                }
        
                30%, 50%, 70%{
                    transform: translateX(14%);
                }
            }
            @keyframes textTranslate_3 {
                0%, 20%, 80%, 100%{
                    transform: translateX(0%);
                }
        
                30%, 50%, 70%{
                    transform: translateX(-8%);
                }
            }
        }

        @include rpmin(lg){
            @keyframes textTranslate_1 {
                0%, 20%, 80%, 100%{
                    transform: translateX(0%);
                }
        
                30%, 50%, 70%{
                    transform: translateX(-10%);
                }
            }
            @keyframes textTranslate_2 {
                0%, 20%, 80%, 100%{
                    transform: translateX(0%);
                }
        
                30%, 50%, 70%{
                    transform: translateX(16%);
                }
            }
            @keyframes textTranslate_3 {
                0%, 20%, 80%, 100%{
                    transform: translateX(0%);
                }
        
                30%, 50%, 70%{
                    transform: translateX(-10%);
                }
            }
        }

        @keyframes iconLeftRotate {
            0%, 20%, 80%, 100%{
                bottom: 37%;
                left: -3%;
                transform: scale(1);
            }
    
            30%, 50%, 70%{
                bottom: 30%;
                left: -30%;
                transform: rotate(-40deg) scale(3);
            }
        }
    
        @keyframes iconRightRotate {
            0%, 20%, 80%, 100%{
                right: -3%;
                bottom: 34%;
            
                transform: scale(1);
            }
    
            30%, 50%, 70%{
                right: -68%;
                bottom: 4%;
                transform: rotate(-45deg) scale(2) translate(0%,0%);
            }
        }
        @include rpmin(md){
            @keyframes iconLeftRotate {
                0%, 20%, 80%, 100%{
                    bottom: 37%;
                    left: -3%;
                    transform: scale(1);
                }
        
                30%, 50%, 70%{
                    bottom: 30%;
                    left: -30%;
                    transform: rotate(-40deg) scale(3);
                }
            }
            @keyframes iconRightRotate {
                0%, 20%, 80%, 100%{
                    right: -8%;
                    bottom: 34%;
                    transform: scale(1);
                }
        
                30%, 50%, 70%{
                    right: -80%;
                    bottom: 4%;
                    transform: rotate(-45deg) scale(2) translate(0%,0%);
                }
            }
        }
        @include rpmin(lg){
            @keyframes iconLeftRotate {
                0%, 20%, 80%, 100%{
                    bottom: 37%;
                    left: -3%;
                    transform: scale(1);
                }
        
                30%, 50%, 70%{
                    bottom: 30%;
                    left: -30%;
                    transform: rotate(-40deg) scale(3);
                }
            }
            @keyframes iconRightRotate {
                0%, 20%, 80%, 100%{
                    right: -14%;
                    bottom: 34%;
                    transform: scale(1);
                }
        
                30%, 50%, 70%{
                    right: -68%;
                    bottom: 20%;
                    transform: rotate(-45deg) scale(2) translate(0%,0%);
                }
            }
        }

        @include rpmin(xl){
            @keyframes iconLeftRotate {
                0%, 20%, 80%, 100%{
                    bottom: 37%;
                    left: -3%;
                    transform: scale(1);
                }
        
                30%, 50%, 70%{
                    bottom: 30%;
                    left: -30%;
                    transform: rotate(-40deg) scale(3);
                }
            }
            @keyframes iconRightRotate {
                0%, 20%, 80%, 100%{
                    right: -4%;
                    bottom: 34%;
                    transform: scale(1);
                }
        
                30%, 50%, 70%{
                    right: -68%;
                    bottom: 20%;
                    transform: rotate(-45deg) scale(2) translate(0%,0%);
                }
            }
        }
    
        .icon-left{
            position: absolute;
            z-index: -1;

            bottom: 0%;
            left: 0%;
            // transform: translate(-17%,-120%);
            // bottom: 27.5%;
            // left: -3%;

            width: 80px;
            // transform: translate(-17%, -40px);
            opacity: 0.45;

            svg, img{
                display: block;
                width: 100%;
                height: auto;
            }

            @include rpmin(md){
                width: 98px;
                // transform: translate(-17%,-216%);
                opacity: 1;
            }
            @include rpmin(lg){
                width: 130px;
                // transform: translate(-17%,-167%);
            }
            @include rpmin(xl){
                width: 196px;
                // transform: translate(-17%,-120%);
            }

            animation: var(--timerEffect, 10s) iconLeftRotate infinite ease;
        }

        .icon-right{
            position: absolute;
            z-index: -1;
            // bottom: 24%;
            // right: -14%;

            right: 0%;
            bottom: 0%;

            width: 160px;
            // transform: translate(29%,-40px);
            opacity: 0.45;
            
            svg, img{
                display: block;
                width: 100%;
                height: auto;
            }
            
            @include rpmin(md){
                width: 320px;
                // transform: translate(29%,-50%);
                opacity: 1;
            }
            @include rpmin(lg){
                width: 380px;
                // transform: translate(29%,-50%);
            }
            @include rpmin(xl){
                width: 459px;
                // transform: translate(29%,-44%);
            }

            animation: var(--timerEffect, 10s) iconRightRotate infinite ease;
        }


        .title{
            margin-left: 6px;
            margin-right: auto;

            @include bold;
            line-height: 1;
            // font-size: 131px;
            @include title(131px);

            // overflow: hidden;
            text-align: center;
            
            padding-top: 6px;
            @include rpmin(md){
                padding-top: 20px;
            }
            @include rpmin(lg){
                padding-top: 31px;
            }

            span{
                margin-top: -6px;
                @include rpmin(md){
                    margin-top: -20px;
                }
                @include rpmin(lg){
                    margin-top: -31px;
                }

                display: block;
                text-transform: uppercase;

                // background-position: 0% 0%;
                background-image: url(../assets/images/homepage/intro-bg.jpg);
                background-repeat: repeat;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                background-size: cover;
            }

            span:nth-of-type(1){
                // transform: translateX(-10%);
                animation: textTranslate_1 10s infinite cubic-bezier(0.175, 0.885, 0.32, 1.275);
            }
            span:nth-of-type(2){
                // transform: translateX(16%);
                animation: textTranslate_2 10s infinite cubic-bezier(0.175, 0.885, 0.32, 1.275);
            }
            span:nth-of-type(3){
                // transform: translateX(-10%);
                animation: textTranslate_3 10s infinite cubic-bezier(0.175, 0.885, 0.32, 1.275);
            }
        }

        .content{
            // font-size: 16px;
            @include content(18px);
            line-height: 1.3888889;

            // line-height: 156%;
            color: var(--cl-content, #000);

            width: 100%;
            @include rpmin(md){
                width: 75%;
            }
            @include rpmin(lg){
                width: 50%;
            }
            margin-top: 20px;
            @include rpmin(md){
                margin-top: 34px;
            }

            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }

        z-index: 10;
        
        padding-bottom: 80px;
        
        @include rpmin(md){
            padding-bottom: 100px;
            clip-path: ellipse(96% 100% at 50% 0%);
        }
        @include rpmin(lg){
            padding-bottom: 198px;
            clip-path: ellipse(86% 100% at 50% 0%);
        }
        @include rpmin(xl){
            clip-path: ellipse(70% 100% at 50% 0%);
        }
    }

    &__thumb{
        @include rpmin(md){
            margin-top: -130px;
        }
        @include rpmin(lg){
            margin-top: -230px;
        }
        
        display: block;

        position: relative;
        z-index: -1;
        width: 100%;
        height: 0;
        padding-top: 40.3%;
        img, svg{
            display: block;

            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .swiper{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.index-about{
    &-wrapper{
        // margin-top: 100px;
        @include space(margin-top, 100px);


        display: flex;
        flex-direction: column;
        @include rpmin(md){
            flex-direction: row;
        }

        .title{
            @include rpmin(md){
                margin-top: -10px;
            }
            @include rpmin(xl){
                margin-top: -18px;
                padding-left: 75px;
            }

            flex: 1;
            &>*{
                transition: 3s transform cubic-bezier(0.075, 0.82, 0.165, 1);
            }
            h1{
                // font-size: 140px;
                @include title(140px);

                font-weight: 700;
                line-height: 93%;

                // color: #1E4487;
                color: var(--cl-blue, #000);

                text-transform: uppercase;
                white-space: nowrap;

                margin-top: 9px;
            }

            h2{
                // font-size: 60px;
                @include title(60px);
                font-weight: 500;
                line-height: 133%;

                color: var(--cl-title, #000);
            }
        }

        &--right{
            // margin-left: 75px;
            
            margin-top: 20px;
            @include rpmin(md){
                margin-top: 0;
                padding-left: 28px;
            }
            @include rpmin(lg){
                padding-left: 40px;
            }
            @include rpmin(xl){
                padding-left: 75px;
            }
            .content{
                text-align: justify;
                line-height: 1.3888889;
                color: var(--cl-content, #000);
                // font-size: 16px;
                @include content(18px);
            }
            
            .readmore{
                // font-size: 16px;
                margin-top: 24px;
                color: var(--cl-content, #000);
                @include content(16px);
            }
        }
    }
}

.index-services{
    @include space(margin-top, 100px);

    &-top{
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;

        position: relative;

        .readmore{
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(0, -50%);

            margin-top: 10px;
            color: var(--cl-readmore, #000);
        }
    }
    .title{
        // margin-left: -12px;
        text-align: center;
        
        &>*{
            letter-spacing: -1px;
            // font-size: 60px;
            @include title(60px);
            // color: #000;
            color: var(--cl-content, #000);

            text-align: center;
            line-height: 133%;
        }
    }

    &-wrapper{
        // margin-top: 60px;
        @include space(margin-top, 60px);

        .item{
            position: relative;

            &-thumb{
                position: relative;
                width: 100%;
                height: 0;
                padding-top: 110%;
                
                &>*{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }

            img{
                display: block;
            }

            .item-content{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                padding: 22px 20px;
                @include rpmin(xl){
                    padding: 28px 44px;
                }
                color: var(--cl-content-light, #FFF);

                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                transition: .3s all;


                .item-desc, .item-more{
                    opacity: 0;
                    visibility: hidden;

                    transform: translateY(100%);
                }
            }

            &-name{
                position: absolute;
                right: 44px;
                bottom: 28px;
                left: 44px;

                transition: .3s all;

                &>*{
                    // font-size: 24px;
                    @include title(24px);
                    color: var(--cl-title-light, #FFF);
                    
                    line-height: 167%;

                    @include bold;
                    text-transform: uppercase;
                }
            }

            &-desc{
                -webkit-line-clamp: 8;
                @media screen and (min-width: 576px) and (max-width: 680px){
                    -webkit-line-clamp: 5;
                }
                -webkit-box-orient: vertical;
                overflow: hidden;
                display: -webkit-box;

                margin-top: 20px;

                // font-size: 18px;
                @include content(18px);
                color: var(--cl-content-light, #FfF);
                line-height: 139%;
            }

            &-more{
                flex: 1;
                display: flex;
                align-items: flex-end;

                margin-top: 12px;

                color: var(--cl-readmore-light, #FFF);
            }

            &:hover{
                .item-content{
                    background-color: var(--cl-blue, #000);

                    .item-desc, .item-more{
                        opacity: 1;
                        visibility: visible;
    
                        transform: translateY(0%);
                        transition: .3s all;
                    }
                }

                .item-name{
                    position: relative;
                    top: auto;
                    left: auto;
                    right: auto;
                    bottom: auto;
                }
            }
        }
    }
}

.index-form{
    @include space(margin-top, 100px);
    position: relative;

    &-bg{
        svg,path{
            height: 100%;
            width: auto;
            display: none;
        }

        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
    
        width: 100%;
        height: 100%;

        @include rpmin(md){
            clip-path: ellipse(96% 100% at 50% 0%);
        }
        @include rpmin(lg){
            clip-path: ellipse(86% 100% at 50% 0%);
        }
        
        @include rpmin(xl){
            clip-path: ellipse(70% 100% at 50% 0%);
        }

        img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            
            display: block;
            object-fit: cover;
        }

        &::after{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--cl-blue, #000);
            opacity: .75;
        }
    }

    &-wrapper{
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;


        padding-bottom: 100px;
        @include space(padding-bottom, 100px);
        @include space(padding-top, 64px);

        .title{
            &>*{
                // font-size: 60px;
                @include title(60px);

                font-weight: 500;
                line-height: 133%;

                color: var(--cl-title-light, #FFF);
            }
        } 
        .subtitle{
            margin-top: 5px;
            &>*{
                // font-size: 32px;
                @include title(32px);

                line-height: 133%;
                font-weight: 700;
                text-transform: uppercase;

                color: var(--cl-title-light, #FFF);
            }
        }

        .form{
            width: 700px;
            max-width: 96vw;
            margin: 43px auto 0 auto;
            text-align: left;
            color: var(--cl-content-light, #FFF);

            &-group{
                display: block;
                label{
                    // font-size: 16px;
                    @include content(16px);

                    font-weight: 400;
                    line-height: 150%;
                    color: var(--cl-content-light, #FFF);
                    
                    a, span{
                        color: var(--cl-red, currentColor);
                        display: inline-block;
                    }
                }

                input:not([type="checkbox"]):not([type="radio"]), textarea{
                    resize: none;
                    width: 100%;

                    background:transparent;
                    border: none;
                    
                    color: var(--cl-content-light, #FFF);
                    border-bottom: 1px solid currentColor;

                    @include content(16px);
                    line-height: 150%;

                    margin-top: -4px;
                }

                .checkbox-custom{
                    display: inline;
                    --whCb: 20px;
                    line-height: 1.5;

                    width: var(--whCb, 20px);
                    height: var(--whCb, 20px);
                    margin-right: 6px;

                    position: relative;

                    & ~ label{
                        display: inline;
                    }

                    input[type="checkbox"]{
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        display: none;

                        position: absolute;
                        top: 0;
                        left: 0;

                        &:checked ~ .checkmark{
                            background:currentColor;
                            &::after{
                                display:block;
                            }
                        }
                    }

                    .checkmark{
                        display: inline-block;
                        cursor: pointer;
    
                        width: var(--whCb, 20px);
                        height: var(--whCb, 20px);

                        color: var(--cl-content-light, #FFF);
                        border: 1px solid currentColor;
    
                        background: none;
                        border-radius: 4px;
    
                        transform: translateY(29%);

                        &::after{
                            content: "";
                            position: absolute;
                            display: none;

                            top: 46%;
                            left: 50%;
                            width: 36%;
                            height: 64%;

                            border: solid var(--cl-blue, #000);
                            border-width: 0 3px 3px 0;
                            transform:translate(-50%,-50%) rotate(45deg);
                        }
                    }

                    &:hover{
                        .checkmark{
                            background:#ccc;
                        }
                    }
                }

                button[type="submit"]{

                    background: none;
                    border: none;
                    outline: none;

                    display: inline-flex;
                    justify-content: center;
                    align-items: center;

                    @include content(16px);

                    font-weight: 400;
                    line-height: 150%;

                    color: var(--cl-content-light, #FFF);

                    background-color: var(--cl-red, #000);
                    border-radius: 10px;

                    padding: 10px 20px;

                    svg{
                        transition: .2s transform linear;

                        margin-left: 10px;
                    }

                    &:hover{
                        background-color: var(--cl-blue, #000);
                        svg{
                            transform: rotate(-90deg);
                        }
                    }
                }
            }
        }
    }
}

.index-news{
    // margin-top: 100px;
    @include space(margin-top, 100px);

    &-top{
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title{
            &>*{
                // font-size: 60px;
                @include title(60px);

                font-weight: 500;
                line-height: 133%;

                color: var(--cl-title, #000);
            }
        }
        .readmore{
            margin-top: 10px;
            color: var(--cl-readmore, #000);
        }
    }

    &-wrapper{
        margin-top: 24px;
        @include rpmin(md){
            margin-top: 40px;
        }

    }
}

.index-logo{
    @include space(margin-top, 100px);
    @include space(margin-bottom, 100px);
    &-wrapper{
        width: 100%;
        position: relative;
        &::after{
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;

            width: 100%;
            height: calc(100% - 30px);

            border: 1px solid var(--cl-blue, #000);
            border-radius: 20px;
            z-index: -1;
        }

        padding: 0px 10% 54px;
        @include rpmin(md){
            padding: 0px 20px 40px;
        }
        @include rpmin(lg){
            padding: 0px 10% 54px;
        }

        .title{
            width: 100%;
            @include rpmin(md){
                width: 70%;
            }

            margin-left: auto;
            margin-right: auto;
            
            padding: 3px 20px;
            text-align: center;
            background-color: var(--cl-blue, #000);
            color: var(--cl-title-light, #FFF);
            border-radius: 10px;

            // transform: translateY(-50%);
            &>*{
                // font-size: 36px;
                @include title(36px);
                font-weight: 500;
                line-height: 150%;
            }
        }

        .swiper{
            margin-top: 20px;
        }

        .sw-item{
            display: flex;
            align-items: center;
            justify-content: center;

            width: 100%;
            height: 60px;

            &>*{
                display: block;
                max-width: 90%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}