@keyframes appearBox {
    from {
        transform: translateY(100px);
        opacity: 0;
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
}

@keyframes opacity {
	0% {
		opacity: 0;
	}

	10% {
		opacity: 0.1;
	}

	20% {
		opacity: 0.2;
	}

	30% {
		opacity: 0.3;
	}

	40% {
		opacity: 0.4;
	}

	50% {
		opacity: 0.5;
	}

	60% {
		opacity: 0.6;
	}

	70% {
		opacity: 0.7;
	}

	80% {
		opacity: 0.8;
	}

	90% {
		opacity: 0.9;
	}

	100% {
		opacity: 1;
	}
}

@keyframes bounce {
	0% {
		transform: translateY(0);
	}

	10% {
		transform: translateY(2px);
	}

	20% {
		transform: translateY(4px);
	}

	30% {
		transform: translateY(6px);
	}

	40% {
		transform: translateY(8px);
	}

	50% {
		transform: translateY(10px);
	}

	60% {
		transform: translateY(12px);
	}

	70% {
		transform: translateY(14px);
	}

	80% {
		transform: translateY(16px);
	}

	90% {
		transform: translateY(18px);
	}

	100% {
		transform: translateY(20px);
	}
}


@keyframes circleRotate {
	0% {
		transform:rotateY(0deg);
	}

	100% {
		transform:rotateY(360deg);
	}
}

@keyframes ring {
    0% {
        transform: rotate(0) scale(1) skew(1deg)
    }
    10% {
        transform: rotate(-25deg) scale(1) skew(1deg)
    }
    20% {
        transform: rotate(25deg) scale(1) skew(1deg)
    }
    30% {
        transform: rotate(-25deg) scale(1) skew(1deg)
    }
    40% {
        transform: rotate(25deg) scale(1) skew(1deg)
    }
    50% {
        transform: rotate(0) scale(1) skew(1deg)
    }
    100% {
        transform: rotate(0) scale(1) skew(1deg)
    }
}


@keyframes btnEffect1 {
    0%{
        border-top-color: #8BC53F;
        border-right-color: var(--btn-cl, #FFF);
        border-bottom-color: var(--btn-cl, #FFF);
        border-left-color: #8BC53F;

        transform: translate(0, 0);
    }

    70%{
        border-top-color: #8BC53F;
        border-right-color: var(--btn-cl, #FFF);
        border-bottom-color: var(--btn-cl, #FFF);
        border-left-color: #8BC53F;

        transform: translate(100%, 100%);
    }
    100%{
        border-top-color: #0D004C;
        border-right-color: var(--btn-cl, #FFF);
        border-bottom-color: var(--btn-cl, #FFF);
        border-left-color: #0D004C;

        transform: translate(0, 0);
    }
}

@keyframes btnEffect1_reverse {
    0%{
        border-top-color: #0D004C;
        border-right-color: #FFF;
        border-bottom-color: #FFF;
        border-left-color: #0D004C;

        transform: translate(0, 0);
    }

    70%{
        border-top-color: #0D004C;
        border-right-color: #FFF;
        border-bottom-color: #FFF;
        border-left-color: #0D004C;

        transform: translate(100%, 100%);
    }
    100%{
        border-top-color: #8BC53F;
        border-right-color: #FFF;
        border-bottom-color: #FFF;
        border-left-color: #8BC53F;

        transform: translate(0, 0);
    }
}

@keyframes opacityPrompt {
	0% {
		opacity: 0;
	}

	10% {
		opacity: 0.1;
	}

	20% {
		opacity: 0.2;
	}

	30% {
		opacity: 0.3;
	}

	40% {
		opacity: 0.4;
	}

	50% {
		opacity: 0.5;
	}

	60% {
		opacity: 0.6;
	}

	70% {
		opacity: 0.7;
	}

	80% {
		opacity: 0.8;
	}

	90% {
		opacity: 0.9;
	}

	100% {
		opacity: 1;
	}
}

@keyframes bouncePrompt {
	0% {
		transform: translateY(0);
	}

	10% {
		transform: translateY(3px);
	}

	20% {
		transform: translateY(6px);
	}

	30% {
		transform: translateY(9px);
	}

	40% {
		transform: translateY(12px);
	}

	50% {
		transform: translateY(15px);
	}

	60% {
		transform: translateY(18px);
	}

	70% {
		transform: translateY(21px);
	}

	80% {
		transform: translateY(24px);
	}

	90% {
		transform: translateY(27px);
	}

	100% {
		transform: translateY(30px);
	}
}