/*!
* source: https://getbootstrap.com/
* Bootstrap Grid v5.0.2 (https://getbootstrap.com/)
* Copyright 2011-2021 The Bootstrap Authors
* Copyright 2011-2021 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
*/

// mobile first

.container,
.container-left,
.container-right {
  // --bs-gutter-x: -1.5rem;

  width: 96vw;
  // padding-right: var(--bs-gutter-x, 0.75rem);
  // padding-left: var(--bs-gutter-x, 0.75rem);

  margin-left: auto;
  margin-right: auto;
}

.container-96{
  width: 97.2vw;

  max-width: calc(1440px - 2.8vw);
  
  margin-left: auto;
  margin-right: auto;
}

.container-left,
.container-right{
  width: calc( (100vw - #{var(--wContainer)})/2  + #{var(--wContainer)} );
}
.container-right{
  margin-right: initial;
  margin-left: auto;
}
.container-left{
  margin-right: auto;
  margin-left: initial;
}

@each $key, $size in $minWidth {
  @media (min-width: $size){  //> 576px
    .container{
      // --bs-gutter-x: 0;
      width: map-get($containerWidth, $key);
    }
    // .container-right,
    // .container-left{
    //   // --bs-gutter-x: 0;
    //   // $wKey: map-get($containerWidth, $key);
    //   width: map-get($containerWidth, $key);
    // }

    // @media (min-width: map-get($minWidth, lg)){  
    //   .container-right{
    //     margin-right: auto;
    //     margin-left: initial;
    //   }
    //   // .container-left{
    //   //   margin-right: initial;
    //   //   margin-left: auto;
    //   // }
    //   // .container-right,
    //   // .container-left{
    //   //   // --bs-gutter-x: 0;
    //   //   $wKey: map-get($containerWidth, $key);
    //   //   width: calc(#{$wKey} + ((100% - #{$wKey}) / 2));
    //   // }
    // }
  }
}


// @each $key, $size in $mdWidth {
//   @media (min-width: $size){  //> 576px
//     .container{
//       --bs-gutter-x: 0;
//       width: map-get($containerWidth, $key);
//     }
//   }
// }

.col {
  flex: 1 0 0%;
}

.row{
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;

  // xét cho 2 bên hông ra ngoài sát viền và top
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);
  width: calc(100% + (var(--bs-gutter-x) * 0.5 * 2));

  &>* {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;

    // khoảng 2 column top và left right
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
  }
  &-cols{
    &-auto>* {
      flex: 0 0 auto;
      width: auto;
    }
    
    &-1>* {
      flex: 0 0 auto;
      width: 100%;
    }
    
    &-2>* {
      flex: 0 0 auto;
      width: 50%;
    }
    
    &-3>* {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
    
    &-4>* {
      flex: 0 0 auto;
      width: 25%;
    }
    
    &-5>* {
      flex: 0 0 auto;
      width: 20%;
    }
    
    &-6>* {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
  }
}

@each $key, $size in $minWidth{
  @media (min-width: $size){
    .col-#{$key}{
      flex: 1 0 0%;
    }

    .row-cols-#{$key}-auto>* {
      flex: 0 0 auto;
      width: auto;
    }

    @each $num, $valNum in $flexRow{
      .row-cols-#{$num}-#{$key}>*{
        flex: 0 0 auto;
        width: $valNum;
      }
    }
  }
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-none{
  width: 0;
  flex: 0 0 0;
}

@each $colNum, $colVal in $flexCol{
  .col-#{$colNum}{
    flex: 0 0 auto;
    width: $colVal;
  }
}

@for $i from 1 through 10{
  .order-#{$i}{
    order: $i;
  }
}

@each $key, $size in $minWidth{
  @media (min-width: $size){
    @for $i from 1 through 10{
      .order-#{$i}-#{$key}{
        order: $i;
      }
    }
  }
}

@each $offNum, $offVal in $flexOffset{
  .offset-#{$offNum} {
    margin-left: $offVal;
  }
}
@each $offNum, $offVal in $flexOffset{
  .offset-l-#{$offNum} {
    margin-left: $offVal;
  }
}

@each $offNum, $offVal in $flexOffset{
  .offset-r-#{$offNum} {
    margin-right: $offVal;
  }
}

@each $gutNum, $gutVal in $flexGutter{
  .gx-#{$gutNum} {
    --bs-gutter-x: #{$gutVal};
  }
  .gy-#{$gutNum} {
    --bs-gutter-y: #{$gutVal};
  }
}

@each $key, $size in $minWidth{
  @media (min-width: $size){
    .col-auto-#{$key} {
      flex: 0 0 auto;
      width: auto;
    }
    @each $colNum, $colVal in $flexCol{
      .col-#{$colNum}-#{$key}{
        flex: 0 0 auto;
        width: $colVal;
      }
    }

    @each $offNum, $offVal in $flexOffset{
      .offset-#{$offNum}-#{$key}>*{
        margin-left: $offVal;
      }
    }

    @each $gutNum, $gutVal in $flexGutter{
      .gx-#{$gutNum}-#{$key} {
        --bs-gutter-x: #{$gutVal};
      }
      .gy-#{$gutNum}-#{$key} {
        --bs-gutter-y: #{$gutVal};
      }
    }
    // .gx-lg-4 // padding-left: 1.5rem 
  }
}

/*# sourceMappingURL=bootstrap-grid.css.map */