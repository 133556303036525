.contact-infor{
    // --wBtnSw: 30px;
    --wBtnSw: 40px;
    @include rpmin(lg){
        --hMap: 41.667vw;
    }

    @include rpmin(xl){
        --wInforPlus: 36px;
        --hInforMinus: 32px;
    }

    &__map{
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        padding-top: var(--hMap, 400px);

        &>*{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &-wrapper{
        // @include space(margin-top, 100px);
        // @include rpmin(lg){
        //     margin-top: 0;
        // }

        background-size: cover;
        background-position: 51% 0%;

        margin-left: -2vw;
        margin-right: -2vw;

        @include rpmin(xl){
            margin-left: 0vw;
            margin-right: 0vw;
        }

        &>.row{
            align-items: stretch;
            flex-direction: column;

            margin-left: 2vw;
            margin-right: 2vw;
    
            @include rpmin(xl){
                margin-left: 0vw;
                margin-right: 0vw;
            }

            @include rpmin(xl){
                flex-direction: row;
            }
        }
        .content{
            position: relative;
            // display: block;
            // background-color: var(--cl-blue, #000);
            // padding: var(--wBtnSw, 40px) 30px var(--wBtnSw, 40px) 30px;
            // color: var(--cl-content-light);

            // margin-top: calc(-1 * #{var(--wBtnSw, 40px)} );
            // margin-left: 2vw;
            // margin-right: 2vw;
            
            // @include rpmin(xl){
            //     margin-left: 0vw;
            //     margin-right: 0vw;
            //     margin-top: 0;

            //     padding: 42px 40px 55px 75px;
            //     transform: translateY(-45px);
                
            //     height: calc(100% - #{var(--hInforMinus, 0px)});
            //     width: calc(100% + #{var(--wInforPlus, 0px)});
            // }

            display: block;
            background-color: var(--cl-blue, #000);
            padding: 20px 30px var(--wBtnSw, 40px) 30px;
            @include rpmin(xl){
                width: calc(100% + #{var(--wInforPlus, 0px)});
            }
            
            .content__title, h1{

                @include title(60px);

                line-height: 110%;
                @include medium;

                color: var(--cl-title-light, #FFF);
                
                margin-bottom: 6px;
                @include rpmin(md){
                    margin-bottom: 12px;
                }
            }

            .row>*{
                color: #BBC2DD;
                line-height: 166%;

                font-size: 16px;
                @include rpmin(md){
                    font-size: 17px;
                }

                p:first-child{
                    color: #FFF;
                }

                a{
                    display: inline-block;
                    color: currentColor;
                    &:hover{
                        color: var(--cl-red, #FFF);
                    }
                }

                &:first-child{
                    @include content(18px);
                }
            }
        }
        .desc{
            padding: 20px 0 20px 0;
            width: 100%;
            text-align: center;

            @include rpmin(md){
                width: calc(#{var(--wContainer)} * 9 / 12 );
            }
            @include rpmin(xl){
                width: 100%;
                text-align: left;
            }

            &>*{
                display: inline-block;
            }

            @include rpmin(xl){
                padding: 80px;
                /* min-height: calc(100% + 40px); */
                display: flex;
                flex-direction: column;
                justify-content: center;
                min-height: 100%;
                
                &>*{
                    display: block;
                    margin-right: 0;
                }
            }
            &>*{
                // @include title(60px);
                font-size: 24px;
                @include rpmin(md){
                    font-size: 36px;
                }
                @include rpmin(lg){
                    font-size: 42px;
                }
                @include rpmin(xl){
                    font-size: 60px;
                }
                line-height: 134%;
                @include bold;

                color: var(--cl-title-light);
                text-transform: uppercase;
            }
        }
    }

    .swiper{
        &-button-customs{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 0;
            padding-top: var(--hMap, 400px);
        }

        &-button-next, &-button-prev{
            position: absolute;
            top: auto;
            bottom: 0;
            left: 50%;
            margin-top: 0;
            // transform: translateY(-100%);

            width: var(--wBtnSw, 40px);
            height: var(--wBtnSw, 40px);
            
            &::after{
                content: none;
            }

            [stroke]:not([stroke="none"]){
                stroke: currentColor;
            }
            [fill]:not([fill="none"]){
                fill: currentColor;
            }

            &[aria-disabled="true"]{
                background-color: var(--cl-w, #FFF);
                opacity: .4;
                color: #B3B4B8;
            }

            &[aria-disabled="false"]{
                background-color: var(--cl-w, #000);
                opacity: 1;

                color: #B3B4B8;
            }
            
            --iCol: 12;
            @include rpmin(md){
                --iCol: 9;
            }
            @include rpmin(xl){
                --iCol: 5;
            }

            &:hover{
                @include rpmin(md){
                    background-color: var(--cl-blue, #000) !important;
                    color: var(--cl-w) !important;
                }
            }
        }

        &-button-prev{
            display: none;
            left: auto;
            right: calc(2vw + #{var(--wBtnSw, 40px)});

            @include rpmin(md){
                display: flex;
                right: auto;
                --colInfor: calc((#{var(--wContainer, 100vw)} * #{var(--iCol, 12)} / 12) + #{var(--wInforPlus, 0px)});
                left: calc( 1vw + (#{var(--colInfor, 0)}) ); 
            }
            @include rpmin(xl){
                right: auto;
                --colInfor: calc((#{var(--wContainer, 100vw)} * #{var(--iCol, 12)} / 12) + #{var(--wInforPlus, 0px)});
                left: calc( (100% - #{var(--wContainer, 100vw)} ) / 2 + (#{var(--colInfor, 0)})); 
            }
        }

        &-button-next{
            right: 2vw;
            left: auto;
            display: none;

            @include rpmin(md){
                display: flex;
                right: auto;
                --colInfor: calc((#{var(--wContainer, 100vw)} * #{var(--iCol, 12)} / 12) + #{var(--wInforPlus, 0px)} + #{var(--wBtnSw, 40px)});
                left: calc( 1vw + (#{var(--colInfor, 0)}) ); 
            }
            @include rpmin(xl){
                right: auto;
                --colInfor: calc((#{var(--wContainer, 100vw)} * #{var(--iCol, 12)} / 12) + #{var(--wInforPlus, 0px)} + #{var(--wBtnSw, 40px)});
                left: calc( (100% - #{var(--wContainer, 100vw)} ) / 2 + (#{var(--colInfor, 0)})); 
            }
        }
    }
    position: relative;

    &-thumbnails{
        position: absolute;
        z-index: 1;
        top: var(--hMap, 400px);
        left: calc( ( 100% - #{var(--wContainer)} ) / 2);
        transform: translateY(-100%);

        width: calc(#{var(--wContainer)} * 12 / 12 );
        @include rpmin(md){
            width: calc(#{var(--wContainer)} * 9 / 12 );
        }
        @include rpmin(xl){
            width: calc(#{var(--wContainer)} * 5 / 12 + #{var(--wInforPlus)});
        }
    
        .contact-infor-item{
            padding: 10px 8px;
            height: 40px;
            background: var(--cl-blue);
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            cursor: pointer;
            &>*{
                color: var(--cl-content-light, #FFF);
            }
        }

        .swiper{
            &-slide{
                &.is-actived{
                    .contact-infor-item{
                        background: var(--cl-red);
                    }
                }
            }
        }
    }

}

.contact-form{
    @include space(margin-top, 100px);

    &-infor{
        height: 100%;
        background-image: url('../assets/images/contact/bg-information.jpg');
        background-size: cover;
        background-position: bottom right;
        color: var(--cl-content-light, #FFF);

        position: relative;

        
        border-radius: 10px;
        overflow: hidden;
        
        padding: 32px 20px 40px 20px;
        @include rpmin(md){
            padding: 48px 80px 68px 40px;
        }

        &::after{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #1E4487;
            opacity: .5;
            z-index: 0;
        }

        &>*{
            position: relative;
            z-index: 1;
        }

        h1{
            color: var(--cl-title-light, #FFF);
            @include medium;
            @include title(60px);
        }

        p{
            margin-top: 10px;
            @include content(18px);
            @include regular;

            color: var(--cl-content-light, #FFF);
        }

        ul{
            margin-top: 48px;
            list-style-type: none;

            &>li{
                display: flex;
                align-items: center;
                .icon{
                    width: 24px;
                    height: 24px;
                    &>*{
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                    margin-right: 13px;
                }

                p{
                    margin-top: 0;
                }

                & + li {
                    margin-top: 40px;
                }
            }
        }
    }
    
    .form{
        display: flex;
        height: 100%;
        align-items: center;

        &-group{
            display: block;
            label{
                // font-size: 16px;
                @include content(16px);

                font-weight: 400;
                line-height: 150%;
                color: var(--cl-content, #000);
                
                a, span{
                    color: var(--cl-red, currentColor);
                }
            }

            input:not([type="checkbox"]):not([type="radio"]), textarea{
                resize: none;
                width: 100%;

                background:transparent;
                border: none;
                
                color: var(--cl-content, #000);
                border-bottom: 1px solid currentColor;

                @include content(16px);
                line-height: 150%;

                margin-top: -4px;
            }

            .checkbox-custom{
                display: inline-block;
                --whCb: 20px;

                width: var(--whCb, 20px);
                height: var(--whCb, 20px);
                margin-right: 6px;

                position: relative;

                input[type="checkbox"]{
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    display: none;

                    position: absolute;
                    top: 0;
                    left: 0;

                    &:checked ~ .checkmark{
                        background:currentColor;
                        &::after{
                            display:block;
                        }
                    }
                }

                .checkmark{
                    display: inline-block;
                    cursor: pointer;

                    width: var(--whCb, 20px);
                    height: var(--whCb, 20px);

                    color: var(--cl-content, #000);
                    border: 1px solid currentColor;

                    background: none;
                    border-radius: 4px;

                    transform: translateY(29%);

                    &::after{
                        content: "";
                        position: absolute;
                        display: none;

                        top: 46%;
                        left: 50%;
                        width: 36%;
                        height: 64%;

                        border: solid var(--cl-blue, #000);
                        border-width: 0 3px 3px 0;
                        transform:translate(-50%,-50%) rotate(45deg);
                    }
                }

                &:hover{
                    .checkmark{
                        background:#ccc;
                    }
                }
            }

            button[type="submit"]{

                background: none;
                border: none;
                outline: none;

                display: inline-flex;
                justify-content: center;
                align-items: center;

                @include content(16px);

                font-weight: 400;
                line-height: 150%;

                color: var(--cl-content-light, #FFF);

                background-color: var(--cl-red, #000);
                border-radius: 10px;

                padding: 10px 20px;

                svg{
                    transition: .2s transform linear;

                    margin-left: 10px;
                }

                &:hover{
                    background-color: var(--cl-blue, #000);
                    svg{
                        transform: rotate(-90deg);
                    }
                }
            }
        }
    }
}


.contact-footer{
    @include space(margin-top, 100px);
    background-color: var(--cl-blue, #000);

    &-wrapper{
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 60px 0 60px 0;
        @include rpmin(md){
            flex-direction: row;
            padding: 80px 0 80px 0;
        }
        @include rpmin(lg){
            padding: 80px 97px 80px 51px;
        }
        .content{
            display: flex;
            align-items: center;

            color: var(--cl-content-light);

            width: 100%;
            @include rpmin(md){
                width: 48%;
            }

            @include rpmin(lg){
                width: 43.5%;
            }

            &__icon{
                display: block;
                margin-right: 10px;
                height: 100px;
                width: 100px;

                @include rpmin(md){
                    min-width: 118px;
                }

                img{
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            &__text{
                h2{
                    /* title h3 pc */
                    font-weight: 400;
                    // font-size: 24px;
                    @include title(24px);
                    line-height: 146%;
                    /* identical to box height, or 146% */

                    color: var(--cl-content-light);
                }

                p{
                    @include title(24px);
                    font-weight: 400;
                    line-height: 1.0833;

                    color: var(--cl-content-light);
                }
            }
        }
    }

    &-form{
        display: block;
        width:100%;
        margin-top: 20px;

        @include rpmin(md){
            width: auto;
            flex: 1;
            margin-top: 0;
            margin-left: 16px;
        }
        .form-group{
            display: flex;
            width: 100%;
            position: relative;
            flex-direction: column;
            @include rpmin(md){
                flex-direction: row;
            }

            
            ::-webkit-input-placeholder { /* Edge */
                color: #ffffffbb;
            }
              
            :-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: #ffffffbb;
            }
            
            ::placeholder {
                color: #ffffffbb;
            }

            input{
                display: block;
                width: 100%;
                appearance: none;
                background: none;
                outline: none;

                color: var(--cl-content-light);
                border: 1px solid rgba(255, 255, 255, 0.16);

                border-radius: 4px;
                padding: 20px 20px;

                @include content(16px);
                line-height: 1.5;

                // &::-webkit-input-placeholder { /* Edge */
                //     color: #ffffffbb;
                // }
                  
                // &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                //     color: #ffffffbb;
                // }
                
                // &::placeholder {
                //     color: #ffffffbb;
                // }

            }

            input[type="submit"], button[type="submit"]{
                width: 100%;
                margin-top: 16px;
                @include rpmin(md){
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: auto;
                    margin-top: 0;
                }

                min-width: 175px;
                height: 100%;

                display: flex;
                justify-content: center;
                align-items: center;
                white-space: nowrap;

                background-color: var(--cl-red, #000);
                color: var(--cl-content-light, #FFF);

                @include content(16px);
                line-height: 1.5;
            }
        }
    }
}