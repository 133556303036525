@keyframes rotateSvg {
    0%{
        transform: rotate(0);
    }
    100%{
        transform: rotate(359deg);
    }
}
@keyframes strokeSvg {
    0%{
        stroke-dashoffset: 0;
        stroke-dasharray: 15 5;
    }
    100%{
        stroke-dashoffset: -179;
        stroke-dasharray: 15 5;
    }
}

.settings{
    z-index: 999;
    position: fixed;
    top: calc(#{var(--hHeader, 0px)} + 20px);
    left: 0%;
    // transform: translateY(-50%);

    // display: none;

    // @include rpmin(lg){
    //     display: block;
    // }

    &-item{
        --bgItem: var(--cl-blue);
        --bgFixed: linear-gradient(82.44deg, #{var(--cl-red, #000)} 10.71%, #{var(--cl-blue, #000)} 110.16%);
        --whFixed: 36px;
        --timeAnimation: 3s;

        &:not(:first-child){
            margin-top: 38px;
        }

        .fixed{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-start;

            [stroke]:not([stroke="none"]){
                stroke: currentColor;
            }
            [fill]:not([fill="none"]){
                fill: currentColor;
            }


            // --fillFixed: #fff;

            // &.fixed--white{
            //     --bgFixed: #fff;
            //     --fillFixed: #1F6BB4;

            //     // .fixed-list{
            //     //     @include shadow(s1);
            //     // }

            //     --fillhover01: #1F6BB4;
            //     --fillhover02: #fff;

            //     .fixed{
            //         &-item{
            //             &__icon{
            //                 path{
            //                     transition: .5s ease;
            //                 }
            //             }

            //             &:hover{
            //                 .pathFixed01{
            //                     fill: var(--fillhover01);
            //                 }
            //                 .pathFixed02{
            //                     fill: var(--fillhover02);
            //                 }
            //             }
            //         }
            //     }
            // }

            // &.fixed--main{
            //     --bgFixed: #1F6BB4;
            //     --fillFixed: #fff;

            //     --fillhover: #1F6BB4;

            //     .fixed{
            //         &-item{
            //             &__icon{
            //                 path{
            //                     transition: .5s ease;
            //                 }
            //             }

            //             &:hover{
            //                 .fixed-item__icon{
            //                     path{
            //                         fill: var(--fillhover);
            //                     }
            //                 }
            //             }
            //         }
            //     }
            // }

            &-icons{
                order: 2;
                // background: var(--bgFixed);
                background: #{var(--bgFixed, #000)};
                color: #FFF;

                width: var(--whFixed, auto);
                height: var(--whFixed, auto);

                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 0% 50% 50% 0%;

                .rotateSvg{
                    transform-origin: center;
                    animation: rotateSvg var(--timeAnimation) linear infinite;
                }

                .strokeSvg{
                    animation: strokeSvg var(--timeAnimation) linear infinite;
                }

                cursor: pointer;

                &.clicked ~ .fixed-list{
                    transform: scale(1);
                    height: auto;
                    width: auto;
                    opacity: 1;
                    transition: .16s ease;
                }
            }

            &-list{
                // background: var(--bgFixed, #000);
                // padding: 20px 18px;
                // border-radius: 0 0 0 20px;

                background-color: #FFF;
                position: relative;
                border-radius: 0 0 10px 0px;
                
                box-shadow: 0 2px 2px rgba(0,0,0,.25);

                &::before{
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: var(--whFixed, 0px);
                    background-color: var(--cl-red, #000);
                }

                &>*{
                    position: relative;
                    z-index: 1;
                }

                height: 0;
                width: 0;
                padding: 0;
                transform: scale(0);
                opacity: 0;
                transform-origin: top left;


                // transform: scale(1);
                // height: auto;
                // width: auto;
                // padding: 20px 18px;

                &>*:first-child{
                    border-top-left-radius: 10px;
                }
                &>*:last-child{
                    border-bottom-right-radius: 10px;
                }
            }

            

            &-item{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                padding: 16px 10px;
                width: 73px;

                border: 1px solid var(--fillFixed);
                // border-radius: 10px;

                background: var(--bgItem, #000);
                color: #FFF;

                &:not(:first-child){
                    margin-top: 1px;
                }

                
                &__title{
                    margin-top: 10px;
                    text-align: center;
                    &>*{
                        transition: .5s ease;
                        color: var(--fillFixed);
                        @include content(14px);
                        @include semi-bold;
                        color: currentColor;
                    }
                }
                cursor: pointer;
                transition: .5s ease;

                &:hover{
                    background-color: var(--cl-red, #000);
                }
            }
        }
    }
}

.scroll-to-top{
    z-index: 997;
    position: fixed;
    bottom: 16%;
    right: 20px;

    width: 28px;
    height: 28px;
    border-radius: 100%;
    border: 1px solid #1E4487;

    background-color: var(--cl-w, #FFF);
    box-shadow: 0 2px 2px rgba(0,0,0,.25);

    display: flex;
    justify-content: center;
    align-items: center;

    svg{
        transform-origin: center;
    }

    [stroke]:not([stroke="none"]){
        stroke: currentColor;
    }
    [fill]:not([fill="none"]){
        fill: currentColor;
    }

    cursor: pointer;
    transition: .5s ease;
    &:hover{
        color: var(--cl-w, #FFF);
        background-color: #1E4487;
    }
}


.fixed-contact{
    position: fixed;
    z-index: 998;
    top: 40%;
    right: 20px;    
    @include rpmin(md){
        right: 40px;
    }

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #3d93ff;
    color: #FFF;

    @include content(16px);
    @include medium;

    border-radius: 40px;
    
    // min-width: 120px;
    
    transform: rotate(90deg);
    transform-origin: right center;
    
    --circleContact: 26px;

    height: var(--circleContact, 0px);
    border-radius: var(--circleContact, 0px);

    overflow: hidden;
    padding: calc(#{var(--circleContact, 0px)} / 2);
    white-space: nowrap;

    box-shadow: 0 2px 2px rgba(0,0,0,.25);

    @keyframes loopFixedBtn {
        0%, 20%, 80%,100%{
            color: #FFF;
            clip-path: circle(70.7% at 50% 50%);
        }   
        30%, 50%, 70%{
            clip-path: circle(calc(#{var(--circleContact, 0px)} / 2) at 50% 50%);
            color: transparent;
        }
    }

    @keyframes hoverFixedBtn {
        100%{
            color: #FFF;
            clip-path: circle(70.7% at 50% 50%);
        } 
    } 
    
    animation: loopFixedBtn 10s infinite linear;

    &:hover{
        animation: hoverFixedBtn 1s linear forwards;
    }
}