.f-jc-c{
    justify-content: center;
}

.f-jc-between{
    justify-content: space-between;
}

.text-main, .txt-main, .cl-main{
    color: var(--cl-blue, #000);
}
.text-blue, .txt-blue, .cl-blue, .blue{
    color: var(--cl-blue, #000);
}
.text-red, .txt-red, .cl-red, .red{
    color: var(--cl-red, #000);
}

.text-main-imp, .txt-main-imp, .cl-main-imp{
    color: var(--cl-blue, #000) !important;
}
.text-blue-imp, .txt-blue-imp, .cl-blue-imp, .blue-imp{
    color: var(--cl-blue, #000) !important; 
}
.text-red-imp, .txt-red-imp, .cl-red-imp, .red-imp{
    color: var(--cl-red, #000) !important;
}

.clear-fix{
    clear: both;
}


.ov-hide{
    overflow: hidden;
}
.m-auto{
    margin-left: auto;
    margin-right: auto;
}
.ml-auto{
    margin-left: auto;
}
.mr-auto{
    margin-right: auto;
}
.mt-auto{
    margin-top: auto;
}

.thin{
    @include thin(true);
}
.extra-light{
    @include extra-light(true);
}
.light{
    @include light(true);
}
.regular{
    @include regular(true);
}
.normal{
    @include normal(true);
}
.medium{
    @include medium(true);
}
.semi-bold{
    @include semi-bold(true);
}
.bold{
    @include bold(true);
}
.extra-bold{
    @include extra-bold(true);
}
.ultra-bold{
    @include ultra-bold(true);
}

.m-c{margin-left:auto;margin-right:auto}

.df-cc{
    display: flex;
    justify-content: center;
    align-items: center;
}

.tc{text-align: center}
.tl{text-align: left}
.tr{text-align: right}

.uppercase{
    text-transform: uppercase !important;
}
.bold{
    font-weight: bold !important;
}

.italic{
    font-style: italic !important;
}

.d-none{
    display: none !important;
}

.noSelect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.scrollgsap{
    position: relative;
    transition: top .5s ease,opacity .1s ease;
    top: 150px;
    opacity: 0;

    &.gsap-loaded{
        top: 0;
        opacity: 1;
    }
}

.f-align-center{
    align-items: center;
}

.obj-contain{
    object-fit: contain;
}
.obj-cover{
    object-fit: cover;
}

.toast-msg{
    position: fixed;
    bottom: 80px;
    right: 20px;
    z-index: 11111;
    // overflow: hidden;
    
    @include rpmin(lg){
        bottom: 20px;
    }
    list-style-type: none;

    li{
        display: block;
        background-color: #333;
        &.success{
            background-color: #40f467;
        }
        &.error{
            background-color: #D81A1A;
        }
        &>p{
            color: #fff;
            @include medium;
            @include content(16px);

            
            padding: 6px 8px;
            @include rpmin(lg){
                padding: 8px 12px;
            }
        }

        transform: translateX(calc(100% + 20px));
        transition: 0.6s all cubic-bezier(0.175, 0.885, 0.32, 1.275);

        &.active{
            transform: translateX(0);
        }

        cursor: pointer;

        &>*{
            user-select: none;
            pointer-events: none;
        }
    }

    li + li {
        margin-top: 10px;
    }
}


.writeux{
    font-family: map-get($family, primary), sans-serif !important;
    color: currentColor;

    // font-size: 16px;
    @include content(18px);

    line-height: 1.388888889;
    color: var(--cl-content, #000);

    &.justify{
        text-align: justify;
    }

    .float-left{
        float: left;
    }

    .float-right{
        float: right;
        width: 120px;
    }

    .clear-fix{
        clear: both;
    }

    h2{

        @include title(32px);
        @include semi-bold;
        color: var(--cl-title, #000);
        line-height: 1.2;
    }
    h3{
        @include title(20px);
        @include medium;
        color: var(--cl-title, #000);
        line-height: 1.333334;
    }
    h4{
        @include content(18px);
        @include medium;
        color: var(--cl-title, #000);
        line-height: 1.3333333;
    }

    div[class|="col"]{
        display: flex;
        flex-direction: column;
        a[data-fancybox]{
            display: block;
            flex: 1;

            img{
                width: 100%;
                height: 100%;
            }
        }
        img{
            display: block;
            flex: 1;

            &.f-auto{
                flex: 0 0 auto;
            }

            &.full-w{
                width: 100%;
            }
        }
    }

    .red, .cl-red{
        color: var(--cl-red, #000);
    }

    .cl-main, .blue, .cl-blue{
        color: var(--cl-blue, #000);
    }
    
    p{
        line-height: 156%;

        &.inline-block{
            display: inline-block;
        }
        &.inline{
            display: inline;
        }
    }

    br{
        &.cp{
            display: none;
            @include rpmin(lg){
                display: block;
            }
        }
        
        &.tb{
            display: none;
            @include rpmin(md){
                display: block;
            }
        }
        
        &.mb{
            display: none;
            @include rpmin(md){
                display: block;
            }
        }
    }

    span{
        &.block{
            display: block;
        }
        &.inline-block{
            display: inline-block;
        }
    }
    
    ul, ol{
        padding-left: 1.4rem;
        
        &.style-none{
            list-style-type: none;
            padding-left: 0;
        }

        &.tab-left{
            margin-left: 0;
            @include rpmin(md){
                margin-left: 2rem;
            }
        }
    }

    img{
        display: block;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        width: auto;

        @include rpmax(md){
            width: 100% !important;
        }

        &.obj-contain{
        }
        &.obj-cover{
            width: 100%;
        }

        &.icon{
            width: auto !important;
        }
    }

    a{
        display: inline-block;
        color: -webkit-link;
        &:hover{
            color: var(--cl-blue, #000);
        }
    }

    a[data-fancybox]{
        display: inline-block;
    }

    figure{
        figcaption{
            font-size: 14px;
            font-style: italic;
            color: var(--cl-caption);

            text-align: center;
            font-style: italic;
        }
    }

    .l3image{
        padding: 0;
        margin: 0;
        
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        justify-items: stretch;

        position: relative;
        width: 100%;

        li:nth-of-type(1){
            flex: 0 0 69.5%;
        }
        li:nth-of-type(2){
            position: absolute;
            height: calc(50% - 5px);
            top: 0;
            left: calc(69.5% + 10px);
            width: calc(30.5% - 10px);
        }
        li:nth-of-type(3){
            position: absolute;
            top: calc(50% + 5px);
            height: calc(50% - 5px);
            
            left: calc(69.5% + 10px);
            width: calc(30.5% - 10px);
        }

        li>img{
            display: block;
            height: 100%;
        }
    }
    

    p.caption{
        font-size: 14px;
        line-height: 2;
        font-style: italic;
        color: var(--cl_gray);

        text-align: center;
        font-style: italic;
    }

    table{
        width: 100%;

        &:not(:first-child){
            margin-top: 8px;
        }

        thead{
            // position: sticky;
            // top: var(--hHeader, 0px);

            // background-color: var(--cl-w);
            transition: .16s all ease-in-out;
            
            // &.scrolling{
            //     box-shadow: 0 4px 4px rgba(#A3ABB9, .24);
            // }
        }
        
        thead>tr>th{
            @include content(18px);
            color: var(--cl-blue, #000);
            @include medium;

            padding-top: 14px;
            padding-bottom: 14px;

            min-width: 20%;
            
            &:first-child{
                text-align: left;
                padding-left: 16px;
            }
            &:last-child{
                text-align: right;
                padding-right: 16px;
            }

            &:not(:first-child):not(:last-child){
                text-align: center;
                padding-left: 8px;
                padding-right: 8px;
            }
        }

        // tbody>tr{
        //     border-top: 1px solid #E6EAF0;
        // }

        tbody>tr>td{
            @include content(18px);
            color: var(--cl-content);
            white-space: nowrap;
            
            padding: 14px 0;
            &:first-child{
                text-align: left;
                color: var(--cl-b);
                padding-left: 16px;
            }
            &:last-child{
                text-align: right;
                padding-right: 16px;
            }

            &:not(:first-child):not(:last-child){
                text-align: center;
                padding-left: 8px;
                padding-right: 8px;
            }
        }

        @include rpmax(lg){
            thead, thead>tr, thead>tr>td{
                display: none;
            }
            tbody, tbody>tr{
                display: flex;
                flex-direction: column;
                text-align: left !important;
            }
            tbody>tr>td{
                display: table-cell;
                text-align: left !important;
            }

            tbody>tr>td{
                position: relative;
                display: flex;
                align-items: center;
                white-space: normal;

                &::before{
                    content: attr(data-title);
                    width: 50%;
                    flex: 0 0 50%;
                    align-self: stretch;
                    display: inline-flex;
                    align-items: center;

                    @include content(18px);
                    color: var(--cl-blue, #000);
                    @include medium;

                    margin-right: 8px;
                }

                padding: 8px 16px !important;

                &:not(:first-child){
                    border-top: 1px solid #E1E1E1;
                    // border-right: 1px solid #E1E1E1;
                }
            }
            tbody>tr{
                border-left: 1px solid var(--cl-blue);
                border-right: 1px solid var(--cl-blue);

                &:first-child{
                    border-top: 1px solid var(--cl-blue);
                    border-top-right-radius: 10px;
                    border-top-left-radius: 10px;
                }

                &:not(:first-child){
                    border-top: 1px solid var(--cl-blue);
                }

                &:last-child{
                    border-bottom: 1px solid var(--cl-blue);
                    border-bottom-right-radius: 10px;
                    border-bottom-left-radius: 10px;
                }
            }
        }

        // @include rpmax(md){
        //     tbody td{
        //         padding: 8px;
        //         padding-left: 20px;
        //         padding-right: 20px;

        //         &:first-child{
        //             padding-left: 20px;
        //         }
        //         &:last-child{
        //             padding-right: 20px;
        //         }
        //     }
        // }
    }

    .table{
        display: block;
        max-width: 100%;
        overflow-x: auto;

        /* width */
        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #888;
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: var(--cl-blue, #555);
        }

        &.table-column{
            --borderOuter: #E1E1E1;
            thead, thead>tr, thead>tr>td{
                display: none;
            }
            tbody, tbody>tr, tbody>tr>td{
                display: block;
                text-align: left !important;
            }


            tbody>tr>td{
                position: relative;
                display: flex;
                align-items: center;

                white-space: normal;

                &::before{
                    content: attr(data-title);
                    display: inline-block;
                    width: 50%;
                    flex: 0 0 50%;

                    align-self: stretch;
                    display: inline-flex;
                    align-items: center;

                    @include content(18px);
                    color: var(--cl-blue, #000);
                    @include medium;

                    margin-right: 8px;
                }

                padding: 8px 16px !important;

                &:not(:first-child){
                    border-top: 1px solid #E1E1E1;
                    // border-right: 1px solid #E1E1E1;
                }
            }
            tbody>tr{
                border-left: 1px solid var(--borderOuter);
                border-right: 1px solid var(--borderOuter);

                &:first-child{
                    border-top: 1px solid var(--borderOuter);
                    border-top-right-radius: 10px;
                    border-top-left-radius: 10px;
                }

                &:not(:first-child){
                    border-top: 1px solid var(--borderOuter);
                }

                &:last-child{
                    border-bottom: 1px solid var(--borderOuter);
                    border-bottom-right-radius: 10px;
                    border-bottom-left-radius: 10px;
                }
            }

            tbody>tr:first-child{
                td:first-child{
                    border-top-right-radius: 10px;
                    border-top-left-radius: 10px;

                    &::before{
                        border-top-left-radius: 10px;

                    }
                }
            }

            tbody>tr:last-child{
                td:last-child{
                    border-bottom-right-radius: 10px;
                    border-bottom-left-radius: 10px;

                    &::before{
                        border-bottom-left-radius: 10px;

                    }
                }
            }
            
            tbody>tr>td{
                display: flex;
                align-items: center;
                // text-indent: 8px;
                padding: 0px !important;

                color: var(--cl-title, #000);

                &::before{
                    padding: 8px 8px 8px 16px !important;
                    
                    width: 50%;
                    flex: 0 0 50%;

                    @include rpmin(md){
                        width: 24%;
                        flex: 0 0 24%;
                    }
                }

                &:nth-of-type(2n + 1){
                    &::before{
                        background: #D7E5FF;
                    }
                }

                &:nth-of-type(2n){
                    &::before{
                        background: #E8F0FF;
                    }
                    
                }
            }
        }

        @include rpmax(lg){
            table{
                tbody, tbody>tr{
                    display: flex;
                    flex-direction: column;
                    
                    text-align: left !important;
                }
            }
        }
    }

    .table-container{
        .head{
            text-align: center;
            padding: 10px 0;
            @include content(24px);
            color:  white;
            background-color: var(--cl-blue, #000);
        }
        

        table {
            margin-top: 0;
            width: 100%;
            border-collapse: collapse;
        
            // border-left:  1px solid #cccccc;

        }

        td, th {
            border: 1px solid #cccccc;
        }

        table tbody>tr>td:not(:first-child):not(:last-child){
            text-align: left;
        }

        table tbody>tr>td:first-child{
            padding-right: 8px;
            padding-left: 8px;
        }
        table tbody>tr>td:last-child{
            text-align: left;
            padding-right: 8px;
            padding-left: 8px;
        }

        @include rpmax(lg){
            thead, thead>tr, thead>tr>td{
                display: block;
            }
            // tbody>tr>td{
            //     display: table-cell;
            //     text-align: left !important;
            // }

            tbody, tbody>tr,
            tbody>tr>td{
                display: table-cell;
                text-align: left !important;
            }

            tbody>tr>td{
                position: relative;
                white-space: normal;

                &::before{
                    content: none;
                    width: 50%;
                    flex: 0 0 50%;
                    align-self: stretch;
                    display: inline-flex;
                    align-items: center;

                    @include content(18px);
                    color: var(--cl-blue, #000);
                    @include medium;
                    margin-right: 8px;
                }

                padding: 8px 16px !important;

                &:not(:first-child){
                    border-top: 1px solid #E1E1E1;
                    // border-right: 1px solid #E1E1E1;
                }
            }
            tbody>tr{
                border-left: none;
                border-right: none;

                &:first-child{
                    border-top: none;
                    border-top-right-radius: 0;
                    border-top-left-radius: 0;
                }

                &:not(:first-child){
                    border-top: none;
                }

                &:last-child{
                    border-bottom: none;
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }

            tr{
                display: flex;
                flex-wrap: wrap;
                
                &>td:first-child{
                    display: block;
                    
                    &[rowspan], &[colspan]{
                        flex: 0 0 100%;
                        background: #666666;
                        color: var(--cl-w);
                    }
                    &:not([rowspan]):not([colspan]){
                        flex: 1;
                    }
                }
                &>td:not(:first-child){
                    flex: 1;
                } 
            }
        }

    }

    .table-container + .table-container{
        @include space(margin-top, 40px);
    }
}

.share-socials{
    &>*{
        @include content(18px);
        @include regular;
        color: #666666;
    }

    display: flex;
    align-items: flex-end;

    ul{
        list-style-type: none;
        margin-left: 20px;
        
        display: flex;
        align-items: flex-end;
        li + li{
            margin-left: 18px;
        }
    }
}