#accountPage{
    .breadcrumbs-list{
        justify-content: flex-start;
    }
}

.account-section{
    @include space(margin-top, 100px);
    @include space(margin-bottom, 100px);


    &__title{
        @include space(margin-bottom, 40px);
        border-bottom: 1px solid #000;
        @include space(padding-bottom, 40px);
    }

    &-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        @include rpmin(lg){
            align-items: flex-start;
            flex-direction: row;
        }
    }
    
    &-sidebar{
        display: inline-block;
        flex: 0 0 auto;
        @include rpmin(lg){
            max-width: 50%;
        }

        padding: 20px;
        border: 1px solid #666666;
        border-radius: 10px;

        .sidebar-top{
            @include content(16px);
            color: #666666;

            .email{
                @include title(20px);
                @include medium;
                color: #111111;
            }

            &>*:not(:first-child){
                margin-top: 10px;
            }
        }

        .sidebar-list{
            margin-top: 20px;
            padding-top: 18px;
            border-top: 1px solid #000;


            list-style-type: none;

            li + li{
                margin-top: 20px;
            }

            li{
                a{
                    display: flex;
                    align-items: center;
                    color: var(--cl-b, #000);
                    white-space: nowrap;

                    svg, img{
                        object-fit: contain;
                        height: 20px;
                        margin-right: 15px;
                    }

                    [stroke]:not([stroke="none"]){
                        stroke: currentColor;
                    }
                    [fill]:not([fill="none"]){
                        fill: currentColor;
                    }

                    &:hover{
                        color: var(--cl-red, #000);
                    }
                }

                &.is-actived{
                    a{
                        color: var(--cl-red, #000);
                    }
                }
            }
        }
    }

    &-form{
        flex: 1;
        max-width: 100%;

        padding: 30px 20px;
        margin-top: 20px;
        
        @include rpmin(lg){
            margin-top: 0;
            margin-left: 60px;
            padding: 60px 40px;
        }
        
        @include rpmin(yl){
            padding: 60px 132px;
        }


        border: 1px solid #666666;
        border-radius: 10px;

        // --wLabel: 140px;


        .group-input{
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            
            color: var(--cl-title, #111111);


            label{
                order: 1;
                @include title(20px);
                white-space: nowrap;
                width: 100%;
                @include rpmin(md){
                    width: var(--wLabel, auto); 
                }

                span{
                    color: var(--cl-red, red);
                }
            }
            textarea, input{
                order: 2;
                resize: none;
                appearance: none;
                outline: none;
                background: none;
                border: 1px solid #EEEEEE;
                color: #666666;

                background: #F9F9F9;

                border-radius: 4px;

                flex: 0 0 100%;
                width: 100%;
                max-width: 100%;
                margin-top: 8px;
                
                @include title(14px);
                line-height: 25px;
                padding: 8px 15px;

                // @include rpmin(md){
                //     flex: 1;
                //     margin-top: 0;
                //     width: auto;
                // }

                &:focus{
                    & ~ label{
                        color: var(--cl-red, currentColor);
                    }
                }
            }
        }

        .forgotpw{
            text-align: center;
            color: var(--cl-blue, #000);
            a{
                &:hover, &:focus{
                    color: var(--cl-red, #000);
                }
            }
        }

        .group-submit{
            @include rpmin(md){
                // margin-left: var(--wLabel, 0px);
                input, button{
                    margin-top: 20px;
                }
            }

            .forgotpw{
                margin-bottom: 20px;

                & ~ input, button{
                    margin-top: 0px;
                }
            }

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            input, button{
                appearance: none;
                outline: none;
                background: none;
                border: none;
                border-radius: 8px;

                width: 312px;
                max-width: 100%;

                padding: 13px 20px;

                color: var(--cl-content-light, #000);
                background-color: var(--cl-red, #FFF);
            }
        }
    }
}