.news-list{
    @include space(margin-top, 60px);
    @include space(margin-bottom, 100px);

    // &__ttile{

    // }
    &-wrapper{
        ul{
            list-style-type: none;
        }
    }


    .item{
        &-thumb{
            position: relative;
            width: 100%;
            height: 0;
            padding-top: 75%;
            overflow: hidden;
            
            &>*{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                transition: .3s linear transform;
            }
        }

        img{
            display: block;
        }

        &-content{
            // &__name{
            //     margin-top: 22px;

            //     &>*{
            //         // font-size: 18px;
            //         @include content(18px);
            //         font-weight: 500;
            //         line-height: 139%;

            //         color: var(--cl-blue, #000);
            //     }
            // }

            &__desc{
                margin-top: 20px;
                text-align: center;
                // font-size: 24px;
                &>*{
                    @include title(24px);
                    font-weight: 500;
                    line-height: 108%;
    
                    color: var(--cl-blue, #000);
                }
            }
        }

        &:hover{
            .item-thumb{
                &>*{
                    transform: scale(1.2);
                }
            }
            .item-content__desc{
                &>*{
                    color: var(--cl-red, #000);
                }
            }
        }
    }

    --sizeLoader: 10px;
    
    @keyframes bouncing-loader {
        to {
          opacity: 0.1;
          transform: translate3d(0, calc(-1 * #{var(--sizeLoader, 16px)}), 0);
        }
    }

    .loadmore{
        margin-top: 40px;
        text-align: center;

        color: var(--cl-blue, #000);
        @include content(18px);
        line-height: 1.38888;

        &:hover{
            color: var(--cl-red, #000);
        }

        &.loading{
            pointer-events: none;
            cursor: none;

            display: flex;
            justify-content: center;  

            &::after, &::before{
                content: "";
            }
            &>*, &::after, &::before{
                width: var(--sizeLoader, 16px);
                height: var(--sizeLoader, 16px);
                background: #8385aa;
                border-radius: 50%;
                animation: bouncing-loader 0.6s infinite alternate;

                color: transparent;

                margin: 0 0.2rem;
            }

            &>a{
                animation-delay: 0.2s;
            }

            &::after{
                animation-delay: 0.4s;
            }
        }
    }
}

// .news-detail{
//     @include space(margin-top, 60px);
//     @include space(margin-bottom, 100px);

//     &-wrapper{
//         @include space(padding-top, 80px);
//         border-top: 1px solid #000;

//         .content{
//             &>h2{
//                 @include title(32px);
//                 @include semi-bold;
//                 color: var(--cl-title, #000);
//                 line-height: 1.2;

//                 @include space(margin-bottom, 60px);
//             }
//             &-footer{
//                 @include space(margin-top, 40px);
//             }
//         }
//     }
// }

// .news-related{
//     &>*{
//         @include title(32px);
//         @include semi-bold;
//         color: var(--cl-title, #000);
//         line-height: 1.2;

//         @include space(margin-bottom, 60px);
//     }
//     ul{
//         list-style-type: none;
//     }
// }