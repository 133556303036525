*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

:root{
	--cl-w: #FFF;
	--cl-b: #000;

	--cl-blue: #1E4487;
	--cl-blue-dark: #0D004C;
	--cl-red: #EC2224;

	--cl-title: #000;
	--cl-title-light: #FFF;
	--cl-content: #343434;
	--cl-content-light: #FFF;

	--cl-readmore: #000;
	--cl-readmore-light: #FFF;


	--cl-caption: #343434;

	--wContainer: 96vw;
    @each $key, $size in $minWidth {
        @media (min-width: $size){
            --wContainer: #{map-get($containerWidth, $key)};
        }
    }

	--wContainer2: 100%;
	@include rpmin(md){
		--wContainer2: 92%;
	}
	@include rpmin(lg){
		--wContainer2: 87.8%;
	}

	--wContainer3: 100%;
	@include rpmin(md){
		--wContainer3: 92%;
	}
	@include rpmin(lg){
		--wContainer3: 82.6%;
	}

	--hHeader: 97px;
	@include rpmin(md){
		--hHeader: 146px;
	}
}

html, body{
	// trình duyệt mặc định font-size: 16px
	-webkit-touch-callout : none ; 
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-font-smoothing: antialiased;

	// scrollbar-width: none;
	// -ms-overflow-style: none;
	// &::-webkit-scrollbar{
	// 	display: none;
	// 	width: 0;
	// 	background-color: transparent;
	// }

	// overflow-x: hidden;
}

// html {
// 	// font-size: 52.5%; // 8.4px
// 	// @include rpmin(md) {
// 	// 	font-size: 57.5%; // 9.2px
// 	// }
// 	// @include rpmin(xl) {
// 	// 	font-size: 62.5%; // 10px
// 	// }
// 	// @media (min-width: 1921px) {
// 	// 	font-size: 77%; // 12.32px
// 	// }
// }

body {
	// font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
	// 	"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
	// 	"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	
	color: #000;
	background-color: var(--cl-w);
	@include regular;
	@include family--primary;
	font-size: 16px;

	// font-size: 1.6rem;
	// @include sizeBs(s16);

	max-width: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	&.hideScroll{
		max-height: 100vh;
		overflow: hidden;
	}
}

// fullpage
main{
	position: relative;
	
	height: auto;
	width: 100%;

	// overflow: hidden;

	// @include rp(tx){
	// 	height: auto;
	// 	overflow-x: hidden;
	// 	overflow-y: auto;
	// }

	// &>section{
	// 	width: 100%;
	// 	height: 100%;
	
	// 	@include rp(tx){
	// 		height: auto;
	// 	}
	// }
}


main,
#banner,
#bannerSub,
#breadcrumb{
	// max-width: 1336px;
	margin-left:auto;
	margin-right:auto;
	// overflow: hidden;
}

ol, ul {
    padding-left: 0rem;
}

ul.rmd{
	list-style-type: none;
	list-style-position: inside;

	margin: 0;
	padding: 0;
}

a{
	color: currentColor;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
	display: block;
}

hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
// 	margin-top: 0;
// 	margin-bottom: 0;
// }

// p {
// 	margin-top: 0;
// 	margin-bottom: 0;
// }

a[data-fancybox] img {
    cursor: zoom-in;
}

abbr[title],
abbr[data-original-title] {
	text-decoration: underline;
	-webkit-text-decoration: underline dotted;
	text-decoration: underline dotted;
	cursor: help;
	border-bottom: 0;
	-webkit-text-decoration-skip-ink: none;
	text-decoration-skip-ink: none;
}

.hidden{
	display: none;
}

address {
	margin-bottom: 0;
	font-style: normal;
	line-height: inherit;
}

b,
strong {
	font-weight: bolder;
	font-weight: 700;
}

small {
	font-size: 80%;
}

sub,
sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

a {
	text-decoration: none;
	background-color: transparent;
}

pre,
code,
kbd,
samp {
	font-family: inherit;
	font-size: 1em;
}

pre {
	margin-top: 0;
	margin-bottom: 0;
	overflow: auto;
}

figure {
	margin: 0;
}

img {
	border-style: none;
	// max-width: 100%;
	width: 100%;
	height: auto;
	object-fit: cover;
	object-position: center;

	&.svg{
		width: auto;
	}
}

svg {
	overflow: hidden;
	vertical-align: middle;
}

table {
	border-collapse: collapse;
}

caption {
	padding-top: 0;
	padding-bottom: 0;
	text-align: left;
	caption-side: bottom;
}

th {
	text-align: inherit;
}

label {
	display: inline-block;
	margin-bottom: 0;
}

button {
	border-radius: 0;
}

input,
button,
select,
optgroup,
textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	// appearance: none;
}

button,
input {
	overflow: visible;
}

button,
select {
	text-transform: none;
}

select {
	word-wrap: normal;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
	cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	padding: 0;
	border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
	box-sizing: border-box;
	padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
	-webkit-appearance: listbox;
}

textarea {
	overflow: auto;
	resize: vertical;
}

fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0;
}

legend {
	display: block;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin-bottom: 0;
	font-size: 1.5rem;
	line-height: inherit;
	color: inherit;
	white-space: normal;
}

progress {
	vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
	height: auto;
}

[type="search"] {
	outline-offset: -2px;
	-webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button;
}

output {
	display: inline-block;
}

summary {
	display: list-item;
	cursor: pointer;
}

template {
	display: none;
}

*:focus,
*:active {
	outline: none;
}

// ::-moz-selection { /* Code for Firefox */
// 	color: #000;
// 	background-color: #55903E;	
// }

// ::selection {
// 	color: currentColor;
// 	background-color: #55903E;
// 	mix-blend-mode: difference;
// }