.tools-list{
    @include space(margin-top, 60px);
    @include space(margin-bottom, 100px);
    &-wrapper{
        // background-image: url('../assets/images/tools/tools-bg.jpg');
        background-size: cover;

        padding: 16px;
        border-radius: 10px;

        position: relative;

        @include rpmin(md){
            padding: 27px 32px;
        }

        &::after{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width:100%;
            height: 100%;
            background-color: var(--cl-blue, #000);
            opacity: .51;
            border-radius: 10px;
        }

        &>*{
            position: relative;
            z-index: 1;
        }
    }

    .tool-item{
        background-color: var(--cl-w, #FFF);
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        height: 100%;
        padding: 20px;
        
        @include rpmin(md){
            padding: 36px;
        }

        transition: .3s all linear;
        position: relative;

        &>*{
            position: relative;
            z-index: 1;
        }


        &::after{
            content: "";
            z-index: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            background-color: var(--cl-blue, #FFF);
            transition: .3s all linear;

            clip-path: circle(0% at 50% 83px);
            @include rpmin(md){
                clip-path: circle(0% at 50% 109px);
            }
        }


        &__icon{
            width: 146px;
            height: 146px;

            background-color: var(--cl-blue, #000);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            overflow: hidden;

            margin-bottom: 20px;

            transition: .3s all linear;

            img, svg{
                width: auto;
                max-width: 86%;
                max-height: 90%;
                object-fit: contain;
            }
        }

        &__name{
            flex: 1;
            text-align: center;
            &>*{
                @include title(24px);
                color: var(--cl-blue, #000);
                @include bold;
                line-height: 1.6666667;
                @include uppercase;
            }
        }

        &:hover{
            // background-color: var(--cl-blue, #000);
            &::after{
                clip-path: circle(100% at 50% 83px);
                @include rpmin(md){
                    clip-path: circle(100% at 50% 109px);
                }
            }
            .tool-item{
                &__icon{
                    background-color: var(--cl-w, #FFF);
                }
                &__name{
                    &>*{
                        color: var(--cl-w, #FFF);
                    }
                }
            }
        }
    }
}

.tools-category{
    @include space(margin-top, 60px);
    @include space(margin-bottom, 100px);

    &-wrapper{
        .content{
            &:not([class="writeux"]){
                ul{
                    list-style-type: none;
                }
            }
        }
    }

    &-item{
        position: relative;
        width: 100%;
        display: block;

        .thumbnails{
            position: relative;
            width: 100%;
            height: 0;
            padding-top: 71%;
            overflow: hidden;

            &>*{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                display: block;
                object-fit: cover;

                transition: .3s transform ease;
            }

            &::after{
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: var(--cl-blue, #000);
                opacity: 0;
                transition: .3s transform linear;
            }
        }

        .name{
            z-index: 1;
            position: absolute;
            bottom: 0;
            width: 100%;
            padding: 20px;
            background-color: var(--cl-blue, #000);

            color: var(--cl-title-light, #FFF);

            text-align: center;

            &>*{
                @include title(24px);
                line-height: 1.6666667;
                @include bold;
                @include uppercase;
                color: inherit;

            }


        }

        &:hover{
            .thumbnails{
                &>*{
                    transform: scale(1.16);
                }

                &::after{
                    opacity: .5;
                }
            }

            .name{
                background-color: var(--cl-red, #000);
            }
        }

        &.small{
            .thumbnails{
                padding-top: 97.21%;
            }
            .name{
                padding: 8px 12px;
            }
        }
    }
}


