.error404{
    .container{
        @include space-section(padding, 100px);
        min-height: calc(100vh - var(--hHeader, 0px));

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;

        .background{
            max-width: 90%;
            &>*{
                width: 100%;
                height: auto;
            }
        }
    }
    &-btn{
        @include space(margin-top, 40px);

        .btn-error{
            display: inline-flex;
            justify-content: center;
            align-items: center;
            padding: 16px 20px 12px 20px;
            min-width: 148px;
            @include rpmin(md){
                padding: 21px 30px 17px 30px;
                min-width: 194px;
            }
            
            @include content(16px);
            color: var(--cl-w, #FFF);
            background: var(--cl-blue, #000);
            border-radius: 120px;

        }
    }
}