@mixin rpSize($lg, $md, $default, $important: ""){
    @if ($important == ""){
        font-size: $default;
    
        @include rpmin(md){
            font-size: $md;
        }
    
        @include rpmin(lg){
            font-size: $lg;
        }
    }
    @else{
        font-size: $default $important;
    
        @include rpmin(md){
            font-size: $md $important;
        }
    
        @include rpmin(lg){
            font-size: $lg $important;
        }
    }
}

@mixin rpSpace($space, $lg, $md, $default){
    #{$space}: $default;

    @include rpmin(md){
        #{$space}: $md;
    }

    @include rpmin(lg){
        #{$space}: $lg;
    }
}

@mixin rpSpaceSection($space, $lg, $md, $default){
    #{$space}: $default 0;

    @include rpmin(md){
        #{$space}: $md 0;
    }

    @include rpmin(lg){
        #{$space}: $lg 0;
    }
}

@mixin title($val, $important: ""){
    @if ($val == 140px){
        @include rpSize(140px, 112px, 80px, $important);
    }
    
    //big intro
    @if ($val == 131px){
        @include rpSize(131px, 100px, 68px, $important);
    }
    
    // title h1 pc
    @if ($val == 60px){
        @include rpSize($val, 48px, 40px, $important);
    }

    // title 36px
    @if ($val == 36px){
        @include rpSize($val, 32px, 28px, $important);
    }

    // title 32px
    @if ($val == 32px){
        @include rpSize($val, 28px, 24px, $important);
    }

    // title h2/h3 pc
    @if ($val == 24px){
        @include rpSize($val, 22px, 20px, $important);
    }

    // title footer
    @if ($val == 20px){
        @include rpSize($val, 20px, 18px, $important);
    }
}

@mixin content($val, $important: ""){
    @if ($val == 18px){
        @include rpSize($val, 18px, 16px, $important);
    }
    @if ($val == 17px){
        @include rpSize($val, 16px, 15px, $important);
    }
    @if ($val == 16px){
        @include rpSize($val, 16px, 15px, $important);
    }
    @if ($val == 14px){
        @include rpSize($val, 13px, 13px, $important);
    }
}


@mixin space($key, $val){
    @if ($val == 120px){
        @include rpSpace($key, $val, 80px, 60px);
    }
    @if ($val == 100px){
        @include rpSpace($key, $val, 72px, 54px);
    }
    @if ($val == 80px){
        @include rpSpace($key, $val, 64px, 48px);
    }
    @if ($val == 64px){
        @include rpSpace($key, $val, 52px, 40px);
    }
    @if ($val == 60px){
        @include rpSpace($key, $val, 48px, 40px);
    }
    @if ($val == 40px){
        @include rpSpace($key, $val, 32px, 28px);
    }
}

@mixin space-section($key, $val){
    @if ($val == 120px){
        @include rpSpaceSection($key, $val, 80px, 60px);
    }
    @if ($val == 100px){
        @include rpSpaceSection($key, $val, 72px, 54px);
    }
    @if ($val == 60px){
        @include rpSpaceSection($key, $val, 48px, 40px);
    }
    @if ($val == 40px){
        @include rpSpaceSection($key, $val, 32px, 28px);
    }
}